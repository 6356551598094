@use "vars" as *;

.sec-recruit {
	background: #fff;
	padding: clamp(30px, 7.1vw, 12.1em) 0 clamp(7em, 8.5vw, 14.2em);
}

.recruit-box {
	@include flex(0, center);
	gap: 0 clamp(40px, 4.694vw, 80px);

	@include mq {
		display: block;
	}

	&__l {
		width: calc(47.5% - clamp(40px, 4.694vw, 80px));

		@include mq {
			width: 100%;
			margin-bottom: 35px;
		}
	}

	&__r {
		width: 52.5%;

		@include mq {
			width: 100%;
		}
	}

	&__img {
		@include aspect-ratio(1260, 1120);

		@include mq {
			width: 80%;
			margin: 0 auto;
		}
	}
}

.recruit-ttl {
	// @include fts(36, 60);
	font-size: clamp(2.1rem, 3vw, 3.6rem);
	line-height: calc(60 / 36);
	font-weight: 500;
	margin-bottom: 1.22em;
	font-family: $font-jp;
}

.recruit-des {
	p {
		margin-bottom: 2.41em;

		@include mq {
			margin-bottom: 1.5em;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.sec-jobs {
	padding: 109px 0;
	background: #f8f8f2;
	padding: clamp(10em, 6.39586vw, 10.9em) 0 clamp(10em, 9.2124vw, 15.7em);

	@include mq {
		padding: 50px 0;
	}
}

.jobs-ttl {
	text-align: center;
	font-family: $font-jp;
	font-weight: 500;
	font-size: clamp(2rem, 2.7vw, 3.6rem);
	letter-spacing: 0.03em;
	margin-bottom: clamp(30px, 4.4vw, 80px);
}

.jobs-tabs {
	@include flex(center, center);
	gap: 0 20px;
	margin-bottom: clamp(50px, 5.222vw, 89px);

	@include mq(sm) {
		gap: 0 10px;
	}

	&__item {
		max-width: calc(50% - 10px);
		color: #9b9b9b;

		@include mq(sm) {
			max-width: calc(50% - 5px);
		}

		span {
			width: 400px;
			height: 70px;
			padding: 20px 20px;
			border-radius: 8px;
			max-width: 100%;
			text-align: center;
			@include fts(18, 36);
			@include flex(center, center);
			border: 1px solid #e1dccd;
			cursor: pointer;
			background: #fff;
			@include smooth-transition;

			&:hover {
				opacity: 0.75;
				background: #5b7c67;
				color: #fff;
			}

			@include mq {
				padding: 10px 10px;
				height: 50px;
				font-size: 1.5rem;
			}

			@include mq(xs) {
				@include font-size(12.5);
			}
		}

		&.active {
			span {
				background: #5b7c67;
				color: #fff;
			}
		}
	}
}

.jobs-content {
	&__ttl {
		@include mq() {
			@include font-size(18);
		}
		&sub {
			// @include fts(26, 39);
			font-size: clamp(2rem, 1.6vw, 2.6rem);
			line-height: calc(39 / 26);
			font-weight: 500;
			color: #5a7b66;
			margin-bottom: 50px;
			font-family: $font-jp;
			@include mq() {
				@include font-size(18);
			}
		}
	}

	&__sub {
		&-box {
			margin-bottom: clamp(45px, 5.1636vw, 88px);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__list {
		@include flex(0, 0, wrap);
		gap: 60px 60px;
		margin-bottom: 49px;

		@include mq(lp) {
			gap: 3.52vw 3.52vw;
		}

		@include mq {
			gap: 3.52vw 0;
			justify-content: space-between;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&-item {
			width: calc(33.33% - 40px);
			border-bottom: 1px solid #e6e6e6;
			@include mq(lp) {
				width: calc(33.33% - 2.3471vw);
			}

			@include mq {
				width: calc(50% - 7px);
			}
			.c-btn__01{
				.txt-lg{
					color: #373737;
					@include mq {
						@include font-size(12);
					}
				}
				&:hover {
					@include hover {
						.txt-lg{
							color: $color-green;
						}
					}
				}
			}
		}

		a {
			display: block;
			position: relative;
			width: 100%;
			padding: 0 0 30px;
			@include mq {
				padding-bottom: 15px;
			}
			&:hover {
				img {
					transform: scale(1.1);
				}
			}
			.c-btn__01{
				display: block;
				position: relative;
				@include mq {
					padding-right: 30px;
				}
				.ico{
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -15px;
				}
			}
		}




		&-img {
			@include aspect-ratio(720, 600);
			border-radius: 8px;
			overflow: hidden;

			img {
				transition: all 0.3s ease;
			}
		}
	}
}

.recruit-detail {
	padding: clamp(50px, 7.0413vw, 120px) 0 clamp(50px, 9.212397vw, 157px);
	background: #f8f8f2;

	&__cat {
		@include flex(0, 0, wrap);
		gap: 10px 10px;

		@include mq(sm) {
			width: 100%;
			gap: 5px;
		}

		&-item {
			@include fts(13, 21);
			font-weight: 500;
			padding: .35em 1.1428em;
			border-radius: 3px;
			border: 1px solid #dddddd;

			@include mq(sm) {
				@include font-size(12);
				padding: .2em .8em;
			}
		}
	}

	&__t {
		@include flex(0, center);
		gap: 0 20px;
		margin-bottom: 4px;

		@include mq(sm) {
			flex-wrap: wrap;
		}
	}

	&__time {
		@include fts(14, 21);

		@include mq {
			padding: 5px 10px 2px;
			width: 100%;
			text-align: right;
		}

		@include mq(sm) {
			padding-left: 0;
		}

		time {
			font-family: $font-en;
			@include fts(16, 24);
			@include mq() {
				@include font-size(14);
			}
		}
	}

	&__ttl {
		@include fts(26, 46);
		font-family: $font-jp;
		padding-bottom: 21px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 54px;

		@include mq {
			@include font-size(18);
			padding-bottom: 5px;
			margin-bottom: 25px;
		}
	}

	&__des {
		margin-bottom: 54px;

		@include mq(sm) {
			margin-bottom: 30px;
		}
	}

	&__dl {
		@include flex(0, 0);
		border-bottom: 1px solid #e6e6e6;

		@include mq(sm) {
			display: block;
		}

	}

	&__tb {
		border: 1px solid #e6e6e6;
		margin-bottom: 60px;
	}

	&__dt {
		width: clamp(170px, 14.67vw, 250px);
		background: #ececdc;
		padding: 23px 30px;
		border-right: 1px solid #e6e6e6;

		@include mq(sm) {
			width: 100%;
			padding: 15px 15px;
			border-right: 0;
		}
	}

	&__dd {
		width: calc(100% - clamp(170px, 14.67vw, 250px));
		background: #ffffff;
		padding: 23px 30px;

		@include mq(sm) {
			width: 100%;
			padding: 15px 15px;
		}

		p {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-block {
			position: relative;
			margin-bottom: 15px;
			padding-left: 15px;

			&::before {
				content: '◆';
				position: absolute;
				top: 0;
				left: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			display: block;
			width: 100%;

			@include mq(sm) {
				@include font-size(11);
			}

			li {
				display: flex;

				&::before {
					content: '・';
				}
			}
		}

		a {
			text-decoration: underline
		}
	}

	&__btn {
		@include mq(md-min) {
			a {
				@include font-size(17);
			}
		}
	}
}

#id-tabs01 {
	.jobs-content__list--parents {
		display: none;
	}
}