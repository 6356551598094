@use "vars" as *;

.sec-sustainability {
	background: #f8f8f2;
	padding-bottom: clamp(5rem,8.7vw,14.6rem);
	.materality-box__list {
		margin: 50px 0 42px;
		@include mq {
			margin: 30px auto;
		}
	}
}

.sustainability-intro {
	position: relative;
	padding: clamp(50px, 6.5vw, 107px) 0 clamp(50px, 6.6vw, 110px);
	margin-bottom: clamp(50px, 7.2vw, 121px);
	z-index: 1;
	@include mq(md-min) {
		@include flex(between, start, wrap);
		.c-ttl__01 {
			width: 54%;
			line-height: 1.6875;
			@include mq(xl) {
				width: 56%;
			}
		}
		.c-txt__01 {
			width: 44.8%;
			@include mq(lp-min) {
				margin-top: 23px;
			}
			@include mq(xl) {
				width: 44%;
			}
		}
	}
	.c-ttl__01 {
		font-weight: 500;
		@include mq {
			margin-bottom: 15px;
		}
	}
	&:before {
		content: "";
		position: absolute;
		width: 100vw;
		height: 100%;
		background: white;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
	}
}

.sustainability-csv {
	margin-bottom: clamp(50px, 7.2vw, 120px);
	@include mq(md-min) {
		margin-top: -8px;
	}
	&__item {
		@include mq(sm-min) {
			@include flex(between, center, wrap);
		}
		&:not(:last-child) {
			margin-bottom: -20px;
			@include mq {
				margin-bottom: 30px;
			}
		}
	}
	&__head {
		text-align: center;
		width: 180px;
		height: 180px;
		background: rgba(91, 124, 102, 0.4);
		color: white;
		border-radius: 50%;
		padding: 52px 10px 10px;
		@include mq(tb) {
			width: 140px;
			height: 140px;
			padding-top: 35px;
		}
		@include mq(sm) {
			margin: 0 auto 20px;
		}
		&-ttl {
			font-weight: 500;
			@include font-size(21);
			margin-top: 18px;
			letter-spacing: 0.05em;
			@include mq(tb) {
				@include font-size(18);
				margin-top: 10px;
			}
		}
	}
	&__ct {
		position: relative;
		z-index: 1;
		@include mq(sm-min) {
			width: calc(64% - 180px);
			letter-spacing: 0.02em;
			padding: 23px 5px 20px 46px;
			@include mq(tb) {
				width: calc(66% - 140px);
			}
			@include mq {
				width: calc(100% - 140px);
			}
			&:before {
				content: "";
				position: absolute;
				background: url("../img/sustainability/sustainability-shape.png") center no-repeat;
				background-size: auto 100%;
				width: 84px;
				height: 100%;
				top: 0;
				left: 0;
				z-index: -1;
			}
		}
		&-tag {
			@include flex(0, 0, wrap);
			gap: 3px;
			margin-bottom: 12px;
			@include mq(sm) {
				justify-content: center;
			}
			span {
				display: inline-block;
				background: white;
				border: 1px solid #dddddd;
				border-radius: 3px;
				font-weight: 500;
				@include font-size(13);
				padding: 6px 10px 2px;
				@include mq {
					@include font-size(11);
				}
			}
		}
	}
	&__img {
		display: grid;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		gap: 8px;
		@include mq(sm-min) {
			width: 31.7%;
			@include mq {
				width: calc(100% - 187px);
				margin-left: auto;
			}
		}
		@include mq(sm) {
			margin-top: 10px;
		}
	}

}
.sustainability-block{
	@include fts(12,20);
	&__img{
		margin-bottom: 15px;
		img{
			display: block;
			margin: 0 auto;
		}
	}
	&__item{
		position: relative;
		z-index: 1;
		border-bottom: 1px solid #e1dccd;
		padding-bottom: 20px;
		margin-bottom: 20px;
		&:last-child{
			border-bottom: none;
			margin-bottom: 0;
		}
	}
	&__ct{
		position: relative;
		padding-top: 45px;
		.sustainability-csv__img {
			margin-top: 12px;
		}
		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100px;
			background: url("../img/sustainability/sustainability-bf.png")no-repeat;
			background-size: 100%;
			background-position: top center;
		}
		&-ttl{
			@include flex(0,center,wrap);
			@include font-size(16);
			font-weight: 500;
			margin-bottom: 5px;
			span{
				display: block;
				background: white;
				@include font-size(10);
				border: 1px solid #ddd;
				padding: 2px 4px 0;
				margin: 0 1px;
				position: relative;
				top: -1px;
				&:nth-child(1){
					margin-left: 5px;
				}
			}
		}
	}
}

.sustainability-materiality {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 40px;
	margin: 50px 0 42px;
	counter-reset: my-sec-counter;
	@include mq(tb) {
		gap: 10px;
	}
	@include mq {
		max-width: 500px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin: 30px auto;
	}
	&__item {
		position: relative;
		background: #f3f3ec;
		border-radius: 8px;
		border: 1px solid #e1dccd;
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(280 / 270 * 100%);
		}
	}
	&__count {
		position: absolute;
		width: 100%;
		color: #5b7c66;
		font-family: $font-en;
		@include fts(12, 17);
		letter-spacing: 0.05em;
		padding-left: 71px;
		top: 19px;
		left: 0;
		z-index: 1;
		@include mq(tb) {
			padding-left: 56px;
			top: 10px;
		}
		@include mq(xs) {
			@include font-size(10);
			padding-left: 45px;
			top: 4px;
		}
		&:before {
			counter-increment: my-sec-counter;
			content: "0" counter(my-sec-counter);
			position: absolute;
			@include font-size(32);
			line-height: 1;
			top: 3px;
			left: 17px;
			@include mq(tb) {
				@include font-size(26);
				top: 4px;
				left: 13px;
			}
			@include mq(xs) {
				@include font-size(20);
			}
		}
		&:after {
			content: "";
			position: absolute;
			width: 30px;
			height: 1px;
			background: #5b7c66;
			bottom: -11px;
			left: 19px;
			@include mq(tb) {
				bottom: 0;
				left: 15px;
			}
			@include mq(xs) {
				width: 23px;
				bottom: 3px;
			}
		}
	}
	&__ct {
		position: absolute;
		text-align: center;
		width: 100%;
		@include fts(14, 26);
		padding: 15px 23px;
		top: 57.3%;
		left: 0;
		transform: translateY(-50%);
		z-index: 1;
		@include mq {
			@include font-size(12);
			padding: 15px 10px 0;
		}
		&-ttl {
			font-family: $font-jp;
			font-weight: 500;
			@include font-size(28);
			letter-spacing: 0.05em;
			margin-bottom: 18px;
			@include mq(xl) {
				@include font-size(24);
				margin-bottom: 8px;
			}
			@include mq(xs) {
				@include font-size(18);
				margin-bottom: 0;
			}
		}
	}
}

.sustainability-btn {
	margin-bottom: clamp(50px, 7.2vw, 116px);
}

.sustainability-goals {
	margin: 35px 0 clamp(30px,4vw,61px);
	@include mq(md-min) {
		@include flex(between, center, wrap);
	}
	&__main {
		@include mq(md-min) {
			width: 25.6%;
		}
		@include mq {
			max-width: 280px;
			margin: 0 auto 15px;
		}
	}
	&__list {
		@include mq(md-min) {
			width: 72.78%;
		}
		display: grid;
		grid-template-columns: repeat(9, minmax(0, 1fr));
		gap: 9px 11px;
		@include mq(sm) {
			grid-template-columns: repeat(6, minmax(0, 1fr));
			gap: 5px;
		}
	}
}

.sustainability-initiatives {
	counter-reset: my-sec-counter;
	margin-bottom: 40px;
	@include mq(md-min) {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 0 40px;
		@include mq(tb) {
			gap: 0 15px;
		}
	}
	&__item {
		@include flex(between, center, wrap);
		border-bottom: 1px solid #e1dccd;
		padding: 31px 0 31px 9px;
		@include mq(tb) {
			padding: 20px 0;
		}
		//@include mq(sm) {
		//	padding-bottom: 15px;
		//}
	}
	&__img {
		position: relative;
		width: 28.2%;
		//@include mq(sm) {
		//	width: 100%;
		//	margin-bottom: 15px;
		//}
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(130 / 160 * 100%);
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			top: 0;
			left: 0;
		}
	}
	&__ct {
		position: relative;
		width: 67.5%;
		font-family: $font-jp;
		font-size: clamp(1.8rem, 1.7vw, 2.4rem);
		padding-top: 37px;
		@include mq(tb) {
			padding-top: 23px;
		}
		@include mq(sm) {
			//width: 100%;
			line-height: 1.2;
		}
		//@include mq(xs) {
		//	@include font-size(17);
		//}
		&:before {
			counter-increment: my-sec-counter;
			content: "Examples of initiatives 0" counter(my-sec-counter);
			position: absolute;
			color: #5b7c66;
			font-family: $font-en;
			@include font-size(12);
			letter-spacing: 0.04em;
			top: 10px;
			left: 0;
			@include mq(tb) {
				top: 0;
			}
		}
		span {
			display: block;
			font-size: clamp(1.3rem, 1.9vw, 1.6rem);
			margin-bottom: 4px;
		}
	}
}