@use "vars" as *;


body {
	color: $color-base;
	font-family: $font-base;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	
	&:not(.is-page-value){
		overflow-x: hidden;
	}
}

a{
	text-decoration: none;
    color: inherit;
	@include smooth-transition;
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
	
	@include mq(){
		-webkit-tap-highlight-color: transparent;
	}
}

.clearfix {
	*zoom: 1;
	&:before {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

.icn-outline {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icn-filled {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icn-outline-fw300 {
    font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
}
