@use "vars" as *;

.sec-message{
    // padding: 120px 0 158px;
	background: #F8F8F2;
	padding: clamp(5rem,6vw,12rem) 0 clamp(5rem,6vw,15.8rem);
	// @include mq(){
	// 	padding: 50px 0;
	// }
}

.message-flex{
	@include flex(0,center);
	margin-bottom: 116px;
	@include mq(xl){
		margin-bottom: 60px;
	}
	@include mq(){
		flex-wrap: wrap;
		margin-bottom: 35px;
	}
	&__img{
		width: 480px;
		@include mq(xl){
			width: 360px;
		}
		@include mq(){
			width: 100%;
			margin-bottom: 20px;
		}
		img{
			width: 100%;
		}
	}
	&__ct{
		padding-top: 50px;
		width: calc(100% - 480px);
		padding-left: 100px;
		@include mq(lp){
			padding-left: 50px;
			padding-top: 0;
		}
		@include mq(xl){
			padding-left: 30px;
			width: calc(100% - 360px);
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
		}
	}
	&__name{
		margin-bottom: 61px;
		@include mq(lp){
			margin-bottom: 35px;
		}
		@include mq(){
			margin-bottom: 25px;
		}
		small{
			color: #373737;
			display: block;
			font-family: $font-jp;
			font-weight: 500;
			margin-bottom: 17px;
			letter-spacing: 1px;
			@include font-size(22);
			@include mq(xl){
				letter-spacing: 0;
				margin-bottom: 10px;
				@include font-size(18);
			}
			@include mq(){
				@include font-size(16);
			}
		}
		>span{
			letter-spacing: 2px;
			font-weight: 500;
			font-family: $font-jp;
			@include font-size(34);
			@include mq(xl){
				letter-spacing: 0;
				@include font-size(28);
			}
			@include mq(){
				@include font-size(24);
			}
			span{
				margin-left: 40px;
				letter-spacing: 1px;
				font-family: $font-en;
				color: #5B7C66;
				@include font-size(28);
				@include mq(xl){
					@include font-size(22);
				}
				@include mq(){
					margin-left: 20px;
					letter-spacing: 0;
					@include font-size(20);
				}
			}
		}
	}
	&__profile{
		border: 1px solid #E1DCCD;
		border-radius: 10px;
		padding: 38px 53px 58px;
		background: #F3F3EC;
		@include mq(xl){
			padding: 25px 25px;
		}
		@include mq(){
			padding: 25px 20px;
		}
		&-ttl{
			color: #D3CAB2;
			font-family: $font-en;
			margin-bottom: 11px;
			letter-spacing: 1px;
			@include font-size(24);
			@include mq(){
				margin-bottom: 8px;
				letter-spacing: 0;
				@include font-size(20);
			}
		}
	}
}

.message-content{
	line-height: 1.875;
	letter-spacing: 0.25px;
	@include mq(){
		letter-spacing: 0;
		line-height: 1.6;
		@include font-size(13);
	}
	p{
		&:not(:last-child){
			margin-bottom: 30px;
			@include mq(){
				margin-bottom: 20px;
			}
		}
		.sign {
			font-family: $font-jp;
			font-weight: 500;
			color: #373737;
			display: block;
			text-align: right;
			margin-top: 47px;
			padding-right: 11px;
			@include font-size(17);
			@include mq(){
				margin-top: 20px;
				padding-right: 0;
				@include font-size(16);
			}
			span{
			    margin-left: 9px;
				@include font-size(24);
				@include mq(){
					@include font-size(20);
				}
			}
		}
	}
}