@use "vars" as *;

#page-disclaimer, #page-news, #page-column, #page-newsdetail, #page-columndetail {
	background: #f8f8f2;
}

.sec-disclaimer {
	padding: clamp(20px,2.8vw,46px) 0 clamp(50px,9vw,141px);
	.inner {
		max-width: 1042px;
	}

	.c-ttl__01--line {
		font-family: $font-jp;
		font-weight: 600;
		@include font-size(21);
		padding-bottom: 15px;
		margin: clamp(30px, 6.7vw, 106px) 0 clamp(15px, 2vw, 33px);
		@include mq {
			@include font-size(16);
			padding-bottom: 7px;
		}
	}
}

.disclaimer-list {
	max-width: 1000px;
	margin: 0 auto;
	&__item {
		position: relative;
		padding-left: 16px;
		@include mq {
			padding-left: 12px;
		}
		&:before {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			background: #373737;
			border-radius: 50%;
			top: 7px;
			left: 0;
			@include mq {
				width: 7px;
				height: 7px;
			}
		}
	}
}