@use "vars" as *;
#business_development{
	background: #f8f8f2;
}
.sec-outline{
	padding: clamp(5rem, 8vw, 11.4rem) 0 clamp(5rem, 10vw, 16.4rem); 
	.anchor-link{
		@include mq{
			margin-bottom: 50px;
		}
	}
	.c-ttl__04{
		font-weight: 400;
	}
}
.outline-info{
	margin-bottom: clamp(5rem, 9vw, 14.2rem);
	.c-ttl__04{
		margin-bottom: 1.22em;
		@include mq{
			margin-bottom: .65em;
		}
	}
	&__date{
		text-align: right;
	}
	&__item{
		@include flex(between, 0, wrap);
		>*{
			padding-top: 29px;
			padding-bottom: 26px;
			@include mq{
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
	}
	&__ttl{
		width: 185px;
		border-bottom: 1px solid #5B7C66;
		text-align: center;
		@include mq(sm){
			width: 115px;
		}
	}
	&__ct{
		border-bottom: 1px solid #E1DCCD;
		width: calc(100% - 190px);
		padding-left: 177px;
		@include mq{
			padding-left: 30px;
		}
		@include mq(sm){
			width: calc(100% - 115px);
			padding-left: 0;
		}
	}
}
.outline-history{
	margin-bottom: clamp(5rem, 8.4vw, 13.5rem);
	.c-ttl__04{
	    margin-bottom: clamp(5rem, 7vw, 9.2rem);
		@include mq{
			margin-bottom: 1em;
		}
	}
	&__item{
		@include flex(between, 0, wrap);
		position: relative;
		@include mq(sm){
			padding-left: 30px;
		}
		@include p-after(20px, 20px){
			background: #5B7C66;
			border-radius: 50%;
			top: 0;
			left: 242px;
			@include mq(sm){
				width: 15px;
				height: 15px;
				left: 0;
				top: 5px;
			}
		}
		&:not(:last-child){
			@include p-before(1px, calc(100% + 54px)){
				background: #5B7C66;
				top: 1px;
				left: 251px;
				@include mq(sm){
					left: 7px;
					height: calc(100% + 25px);
					top: 5px;
				}
			}
		}
		
		&:not(:last-child){
		    margin-bottom: 55px;
			@include mq(sm){
				margin-bottom: 25px;
			}
		}
	}
	&__ttl{
		color: #5B7C66;
		width: 154px;
		@include fts(20, 28);
		font-family: $font-en;
		
		@include mq(sm-min){
			text-align: right;
		}
		@include mq{
			@include font-size(16);
		}
		@include mq(sm){
			width: 100%;
			
		}
		.c-ft--base{
			@include font-size(16);
			font-family: $font-base;
			&:not(:last-child){
			    margin-right: 10px;
			}
		}
	}
	&__ct{
		width: calc(100% - 348px);
		@include mq(sm){
			width: 100%;
		}
	}
}
.outline-map{
	margin-bottom: clamp(4rem,4.5vw,7.2rem);

	.c-ttl__04{
	    margin-bottom: clamp(5rem, 7vw, 9.2rem);
		@include mq{
			margin-bottom: 1em;
		}
	}
	&__flex{
		@include flex(between, start, wrap);
	}
	&__ttl{
		width: 95px;
		border-bottom: 1px solid #5B7C66;
		@include font-size(20);
		padding-bottom: 10px;
		@include mq{
			width: 100%;
			@include font-size(16);
			margin-bottom: 20px;
			padding-bottom: 5px;
		}
	}
	&__ct{
		width: calc(100% - 195px);
		@include mq{
			width: 100%;
		}
		.c-txt__02{
		    line-height: 2;
		}
		.--md{
			@include font-size(20);
			display: block;
			color: #5B7C66;
			line-height: 2;
			@include mq{
				@include font-size(16);
			}
			.--s{
				@include font-size(13);
			}
		}
	}
	&__btn{
		@include fts(13, 21);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: #D6D1C4;
		color: white;
		border-radius: 2px;
		padding: 3px 23px 1px;
		letter-spacing: 1px;
		transition: all .3s ease-in;
		@media (any-hover: hover) {
			&:hover {
				opacity: .7;
			}
		}
		@include mq(md-min){
			transform: translateY(-3px);
		}
	}
	&__note{
		margin-top: 9px;
		li{
			position: relative;
			// padding-left: 20px;
			&:not(:last-child){
			    margin-bottom: 2px;
			}
			// @include p-before(5px, 5px){
			// 	border-radius: 50%;
			// 	left: 3px;
			// 	top: 10px;
			// 	background: currentColor;
			// }
		}
	}
	&__default{
		margin-top: 52px;
		@include mq(sm){
			margin-top: 30px;
		}
		iframe{
			width: 100%;
			height: 500px;
			@include mq{
				height: min(500px,65vw);
			}
		}

	}
}
.outline-contact{
	background: #F3F3EC;
	border: 1px solid #E1DCCD;
	border-radius: 8px;
	padding: 38px 10px 32px;
	@include mq{
		text-align: center;
		padding: 20px 15px;
	}
	&__ttl{
		text-align: center;
		font-size: clamp(2rem, 2vw, 2.6rem);
		line-height: 1.4;
		font-family: $font-jp;
		margin-bottom: 23px;
		@include mq{
		    margin-bottom: 10px;
		}
	}
	&__flex{
		@include flex(center,0, wrap);
		max-width: 855px;
		margin: 0 auto;
	}
	&__subttl{
		font-size: clamp(1.6rem, 2vw, 2.1rem);
		font-weight: 500;
		width: 194px;
		padding-top: 10px;
		@include mq{
			width: 100%;
		}
	}
	&__ct{
		width: calc(100% - 194px);
		@include mq{
			width: 100%;
		}
	}
	&__phone{
		@include font-size(22);
		font-weight: 500;
		letter-spacing: 1.2px;
		margin-bottom: 5px;
		@include mq(tb){
			@include font-size(16);
		}
		@include mq{
			//max-width: 252px;
			//text-align: left;
			margin: 0 auto 5px;
		}

		@include mq(xs){
			@include font-size(14);
			//max-width: 232px;
		}
		.--md{
			//@include font-size(33);
			font-size: clamp(2.4rem,3.22vw,3.3rem);

			//@include mq(tb){
				//@include font-size(26);
			//}
			//@include mq(xs){
			//	@include font-size(24);
			//}
		}
	}
	.c-txt__02{
		
		letter-spacing: .9px;
		@include mq(md-min){
			text-align: right;
		}
	}
}
