@use "vars" as *;

.sec-inbound {}

.inbound-heading {
	position: relative;
	z-index: 2;
	padding: clamp(50px, 9vw, 131px) 0 clamp(50px, 13vw, 220px);
	@include p-before(95%, 147%){
		background: url(../img/business/inbound/bg-01.png) no-repeat right bottom;
		background-size: 100% 100%;
		left: -.3%;
		top: 14.5%;
		z-index: -1;
		@include mq(lg){
			height: 120%;
			top: 0;
			background-size: cover;
		}
		@include mq(sm){
			height: 150%;
			width: 100%;
		}
	}


	@include mq(xl) {
		background-position: -7% -16%;
	}

	@include mq(lg) {
		background-size: cover;
		background-position: bottom right;
	}

	.heading-common {
		margin-bottom: 180px;

		@include mq {
			margin-bottom: 70px
		}
	}

	&__flex {
		@include flex(0, end, wrap);
		gap: 20px 10%;

		@include mq(lg) {
			flex-direction: column-reverse;
		}

		&-logo {
			margin-bottom: 23px;

			@include mq(lg) {
				text-align: center;
				width: 100%;
			}
		}
	}

	&__photo {
		@include flex();
		overflow: hidden;
		width: 68.4%;

		@include mq(lg) {
			width: 100%;
		}

		&-item {
			width: 420px;
			margin: 0 10px;

			@include mq {
				width: 280px;
			}
		}

		&-img {
			position: relative;
			overflow: hidden;
			border-radius: 8px;

			&::before {
				content: "";
				padding-top: calc((320 / 420) * 100%);
				display: block;
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				object-fit: cover;
			}
		}
	}
}

.inbound-customer {
	background: #f8f8f2;
	padding: clamp(30px, 9vw, 110px) 0 clamp(50px, 12vw, 140px);
	position: relative;
	z-index: 2;

	&__ttl {
		text-align: center;
		margin-bottom: clamp(30px,5.083vw,61px);
	}

	&__imgscroll {
		text-align: center;
		overflow-x: auto;
		overflow-y: hidden;

		img {
			width: 100%;
		}
	}
}

.inbound-hotels {
	padding: clamp(50px, 9vw, 129px) 0 clamp(50px, 12vw, 162px);

	&__ttl {
		margin-bottom: clamp(30px, 6vw, 60px);
	}
}

.hotels-column {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 80px 60px;

	@include mq(tb) {
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
	}

	@include mq(sm) {
		grid-template-columns: 1fr;
	}

	&__item {}

	&__img {
		position: relative;
		margin-bottom: 26px;

		&-child {
			border-radius: 9px;
			position: relative;
			overflow: hidden;

			&::before {
				content: "";
				padding-top: calc((250 / 360) * 100%);
				display: block;
			}

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				left: 0;
				top: 0;
			}
		}

		&-logo {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			text-align: center;

			@include mq(sm) {
				img {
					max-width: 250px;
					width: 100%;
				}
			}
		}
	}

	&__info {
		@include fts(14, 26);
		@include flex(between, 0, wrap);
		gap: 15px 0;
		>* {
			padding-bottom: .8em;
			padding-left: .715em;
		}

		&-ttl {
			width: 80px;
			border-bottom: 1px solid #5b7c67;

		}

		&-ct {
			width: calc(100% - 90px);
			border-bottom: 1px solid #e1dccd;
		}
	}
}
