@charset "UTF-8";
/* ====================================================
color
==================================================== */
/* ====================================================
Font
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px; /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

body {
  color: #373737;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}
body:not(.is-page-value) {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  a {
    -webkit-tap-highlight-color: transparent;
  }
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.icn-outline {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icn-filled {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icn-outline-fw300 {
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1200px) {
  .pc-xl {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-tb {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-tb {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.mouse-icon {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(91, 124, 102, 0.9);
  background-image: url(../img/common/ico-dbl-arrow.png);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  pointer-events: none;
}

.js-fadeBox > * {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c-fc--white {
  color: white !important;
}
.c-fc--black {
  color: black !important;
}

.c-ft--en {
  font-family: "Marcellus", serif;
}
.c-ft--jp {
  font-family: "Shippori Mincho B1", serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  font-size: 1.5rem;
  line-height: 1.8666666667;
}
@media screen and (max-width: 834px) {
  .c-txt__01 {
    font-size: 1.2rem;
  }
}
.c-txt__02 {
  font-size: 1.6rem;
  line-height: 1.75;
}
@media screen and (max-width: 834px) {
  .c-txt__02 {
    font-size: 1.2rem;
  }
}
.c-txt__03 {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .c-txt__03 {
    font-size: 1.4rem;
  }
}

.c-ttl__01 {
  font-size: clamp(2rem, 2vw, 3.2rem);
  line-height: 1.5;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    line-height: 1.57;
  }
}
.c-ttl__01--boderBottom {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 12px;
}
.c-ttl__01--sm {
  font-size: 2.1rem;
  line-height: 2.1904761905;
}
.c-ttl__01 .c-ft--en {
  display: inline-block;
  color: #9b9b9b;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0.04em;
  margin-left: 4px;
}
@media screen and (max-width: 834px) {
  .c-ttl__01 .c-ft--en {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__01 .c-ft--en {
    font-size: 1.4rem;
  }
}
.c-ttl__01--line {
  border-bottom: 1px solid #e1dccd;
  position: relative;
  font-weight: 500;
  padding-bottom: 22px;
  margin-bottom: 53px;
}
@media screen and (max-width: 834px) {
  .c-ttl__01--line {
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}
.c-ttl__01--line:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: auto;
  border-bottom: 1px solid #5b7c66;
  left: 0;
  bottom: -1px;
}
.c-ttl__01--linetop {
  position: relative;
  border-top: 1px solid #e1dccd;
  font-weight: 500;
  padding-top: 25px;
  margin-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .c-ttl__01--linetop {
    padding-top: 10px;
    margin-bottom: 25px;
  }
}
.c-ttl__01--linetop:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 1px;
  background: #5b7c66;
  top: -1px;
  left: 0;
}
.c-ttl__01--date {
  position: relative;
}
@media screen and (min-width: 835px) {
  .c-ttl__01--date {
    padding-left: 220px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01--date {
    padding-top: 40px;
  }
}
.c-ttl__01--date .date {
  position: absolute;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #e1dccd;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 1.7rem;
  letter-spacing: 0.02em;
  padding: 5px 20px;
  top: 10px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .c-ttl__01--date .date {
    font-size: 1.4rem;
    padding: 5px 10px 3px;
    top: 0;
  }
}
.c-ttl__01--md {
  font-size: clamp(2rem, 2vw, 3rem);
}
.c-ttl__02 {
  padding-left: 50px;
  position: relative;
  font-family: "Shippori Mincho B1", serif;
  font-size: 1.4rem;
  padding-left: 65px;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 834px) {
  .c-ttl__02 {
    padding-left: 30px;
  }
}
.c-ttl__02::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 50px;
  left: 3px;
  top: 10px;
  background: #373737;
}
@media screen and (max-width: 834px) {
  .c-ttl__02::before {
    width: 20px;
  }
}
.c-ttl__02--white {
  color: white;
}
.c-ttl__02--white::before {
  background: white;
}
.c-ttl__03 {
  font-family: "Marcellus", serif;
  font-size: 6.2rem;
  line-height: 1.22;
  letter-spacing: 0.044em;
}
@media screen and (max-width: 1200px) {
  .c-ttl__03 {
    font-size: 4.6rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03 {
    font-size: 3rem;
    letter-spacing: 0;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__03 {
    font-size: 2.6rem;
  }
}
.c-ttl__03 small {
  display: block;
  font-size: 4.2rem;
  line-height: 1.4285714286;
  letter-spacing: 0.0325em;
}
@media screen and (max-width: 1200px) {
  .c-ttl__03 small {
    font-size: 3.1rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03 small {
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__03 small {
    font-size: 1.8rem;
  }
}
.c-ttl__03 .txt-md {
  font-size: 5.2rem;
}
@media screen and (max-width: 1200px) {
  .c-ttl__03 .txt-md {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03 .txt-md {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 374px) {
  .c-ttl__03 .txt-md {
    font-size: 2rem;
  }
}
.c-ttl__04 {
  font-size: clamp(2.4rem, 4vw, 3.4rem);
  font-weight: 500;
  line-height: 1.4;
  font-family: "Shippori Mincho B1", serif;
  text-align: center;
}
@media screen and (max-width: 374px) {
  .c-ttl__04 {
    font-size: 2.1rem;
  }
}
.c-ttl__04 .--sm {
  font-size: 70.6%;
  display: block;
  margin-bottom: 5px;
}
.c-ttl__05 {
  line-height: 1.2;
}
.c-ttl__05 .c-ft--en {
  font-size: clamp(3.5rem, 4.6vw, 7.2rem);
  display: block;
  color: #5b7c66;
  opacity: 0.2;
}
.c-ttl__05 .c-ft--jp {
  font-size: clamp(1.6rem, 2vw, 1.8rem);
}
.c-ttl__06 {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  text-align: center;
  margin-bottom: 44px;
  font-size: 3.4rem;
}
@media screen and (max-width: 1200px) {
  .c-ttl__06 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__06 {
    font-size: 2.4rem;
  }
}
.c-ttl__06 span {
  color: #e4e9e6;
  line-height: 1;
  display: block;
  font-family: "Marcellus", serif;
  list-style: 1;
  margin-bottom: -26px;
  letter-spacing: 2.5px;
  font-size: 10rem;
  font-size: clamp(3rem, 6vw, 10rem);
}
@media screen and (max-width: 1200px) {
  .c-ttl__06 span {
    margin-bottom: -20px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__06 span {
    margin-bottom: -10px;
  }
}
@media screen and (max-width: 640px) {
  .c-ttl__06 span {
    margin-bottom: 0;
  }
}
.c-ttl__06 small {
  margin-bottom: 5px;
  display: block;
  color: #373737;
  font-size: 2.4rem;
}
@media screen and (max-width: 1200px) {
  .c-ttl__06 small {
    font-size: 2rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__06 small {
    margin-bottom: 0;
    font-size: 1.8rem;
  }
}
.c-ttl__07 span {
  display: block;
}
.c-ttl__07 .c-ft--en {
  color: #5b7c66;
  font-family: "Marcellus", serif;
  margin-bottom: 23px;
  letter-spacing: 0.5px;
  font-size: 1.6rem;
}
@media screen and (max-width: 1024px) {
  .c-ttl__07 .c-ft--en {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__07 .c-ft--en {
    letter-spacing: 0;
    margin-bottom: 6px;
    font-size: 1.4rem;
  }
}
.c-ttl__07 .c-ft--jp {
  color: #373737;
  font-weight: 500;
  line-height: 1.5217;
  font-family: "Shippori Mincho B1", serif;
  font-size: 4.6rem;
  font-size: clamp(2.4rem, 2.8vw, 4.6rem);
}
@media screen and (max-width: 834px) {
  .c-ttl__07 .c-ft--jp {
    line-height: 1.3;
  }
}
.c-ttl__07 .c-ft--jp span {
  font-size: clamp(2rem, 2vw, 3.2rem);
}
.c-ttl__07--border {
  border-bottom: 1px solid #e1dccd;
  position: relative;
  padding-bottom: 33px;
  margin-bottom: 34px;
}
@media screen and (max-width: 834px) {
  .c-ttl__07--border {
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}
.c-ttl__07--border:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: auto;
  border-bottom: 1px solid #5b7c66;
  left: 0;
  bottom: -1px;
}

.c-btn__01 {
  display: inline-flex;
  align-items: center;
  gap: 9px;
  font-size: 1.5rem;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    font-size: 1.2rem;
  }
}
.c-btn__01 .txt-lg {
  font-size: clamp(1.1rem, 1.75vw, 2.1rem);
}
@media screen and (max-width: 834px) {
  .c-btn__01 .txt-lg {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .c-btn__01 .txt-lg {
    font-size: 1.1rem;
  }
}
.c-btn__01 .ico {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 834px) {
  .c-btn__01 .ico {
    width: 25px;
    height: 25px;
    border: 1px solid white;
    min-width: 25px;
  }
}
.c-btn__01 .ico .material-symbols-outlined {
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .c-btn__01 .ico .material-symbols-outlined {
    font-size: 1.1rem;
  }
}
.c-btn__01 .ico.gray {
  border-color: #e4e4e4;
}
.c-btn__01 .ico.gray .circle-svg .circle-static {
  stroke: #e4e4e4;
}
.c-btn__01 .ico.gray .material-symbols-outlined {
  color: #aaa;
}
.c-btn__01 .ico.gray02 {
  border-color: #aaa;
}
.c-btn__01 .ico.gray02 .circle-svg .circle-static {
  stroke: #aaa;
}
.c-btn__01 .ico.gray02 .material-symbols-outlined {
  color: #aaa;
}
.c-btn__01 .ico .circle-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  stroke-width: 1;
  transform: rotate(-90deg);
}
@media screen and (max-width: 834px) {
  .c-btn__01 .ico .circle-svg {
    display: none;
  }
}
.c-btn__01 .ico .circle-svg circle {
  fill: transparent;
  transform: translate(-2px, -2px);
}
.c-btn__01 .ico .circle-svg .circle-static {
  stroke: white;
  stroke-width: 1;
}
.c-btn__01 .ico .circle-svg .circle-hover {
  stroke: #5b7c66;
  stroke-width: 0;
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
  animation-timing-function: cubic-bezier(0, 0, 0, 0.97);
}
@media (any-hover: hover) {
  .c-btn__01:hover {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico .material-symbols-outlined {
    animation: anim-arrow 0.4s forwards;
  }
  .c-btn__01:hover .ico.gray {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray .material-symbols-outlined {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray02 {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray02 .material-symbols-outlined {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico .circle-svg circle {
    stroke-width: 1;
    animation: anim-circle-m 0.5s forwards;
  }
}
@media (hover: hover) {
  .c-btn__01:hover {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico .material-symbols-outlined {
    animation: anim-arrow 0.4s forwards;
  }
  .c-btn__01:hover .ico.gray {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray .material-symbols-outlined {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray02 {
    border-color: #5b7c66;
  }
  .c-btn__01:hover .ico.gray02 .material-symbols-outlined {
    color: #5b7c66;
  }
  .c-btn__01:hover .ico .circle-svg circle {
    stroke-width: 1;
    animation: anim-circle-m 0.5s forwards;
  }
}
@media screen and (min-width: 835px) {
  .c-btn__01--sm .txt-lg {
    font-size: clamp(1.1rem, 1.7vw, 1.8rem);
  }
}
.c-btn__01--icoright .ico {
  margin-bottom: 4px;
  z-index: 1;
}
.c-btn__01--icoright .ico .material-symbols-outlined {
  color: #aaaaaa;
}
.c-btn__01--icoright .ico .circle-svg {
  opacity: 0;
}
.c-btn__01--icoright .ico:after {
  content: "";
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  top: 0;
  left: 0;
  z-index: -1;
}
@media (any-hover: hover) {
  .c-btn__01--icoright:hover .ico .material-symbols-outlined {
    color: #5b7c66;
  }
  .c-btn__01--icoright:hover .ico .circle-svg {
    opacity: 1;
  }
}
.c-btn__02 {
  background: #5B7C66;
  position: relative;
  border: 0;
  cursor: pointer;
  padding: 22px 10px;
  line-height: 1.5;
  font-size: clamp(1.5rem, 1.75vw, 2rem);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  max-width: 340px;
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .c-btn__02 {
    padding: 15px 10px;
  }
}
.c-btn__02::after {
  content: "arrow_forward";
  font-family: "Material Symbols Outlined";
  font-size: 1.25em;
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
}
.c-btn__02--noarrow {
  max-width: 360px;
}
.c-btn__02--noarrow:after {
  content: none;
}
.c-btn__02--prev {
  background: #BCBCBC;
}
.c-btn__02--prev:after {
  left: 33px;
  right: auto;
  transform: translateY(-50%) rotate(180deg);
}
@media (any-hover: hover) {
  .c-btn__02:hover {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  .c-btn__02:hover {
    opacity: 0.7;
  }
}
.c-btn__02.wpcf7-submit {
  background-image: url("../img/common/icon-arrow.png");
  background-repeat: no-repeat;
  background-position: center right 35px;
  background-size: 25px;
}
.c-btn__02.wpcf7-previous {
  background-image: url("../img/common/icon-arrow-prev.png");
  background-repeat: no-repeat;
  background-position: center left 35px;
  background-size: 25px;
}
.c-btn__02--down {
  font-size: 1.6rem;
  max-width: 300px;
  font-weight: normal;
  padding: 1.2em 1em 1.2em 2.9em;
  letter-spacing: -0.25px;
}
.c-btn__02--down::after {
  content: "vertical_align_bottom";
  font-family: "Material Symbols Outlined";
  font-size: 1.25em;
  position: absolute;
  top: 50%;
  right: auto;
  left: 30px;
  transform: translateY(-50%);
  line-height: 1;
  font-weight: bold;
}

@keyframes anim-arrow {
  0% {
    opacity: 0;
    margin-left: -5px;
  }
  99.9%, to {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes anim-circle-m {
  from {
    stroke-dashoffset: 250;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.js-fadeUp {
  opacity: 0;
  transform: translate(0, 70px);
}
.js-fadeUp.is-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.js-fadeBox-ct {
  opacity: 0;
}
.inner {
  display: block;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    padding: 0 6.66%;
  }
}
.inner--1500 {
  max-width: 1500px;
}

.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}

.grid {
  display: grid;
}
.grid-column-2 {
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 835px) {
  .grid-column-2-pc {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 641px) {
  .grid-column-2-sm {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 375px) {
  .grid-column-2-xs {
    grid-template-columns: repeat(2, 1fr);
  }
}
.grid-column-3 {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 641px) {
  .grid-column-3-sm {
    grid-template-columns: repeat(3, 1fr);
  }
}
.grid-column-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1201px) {
  .grid-column-4-xl {
    grid-template-columns: repeat(4, 1fr);
  }
}

.mv-page {
  position: relative;
  color: white;
}
.mv-page__img img {
  width: 100%;
  min-height: 200px;
  object-fit: cover;
}
.mv-page__ttl {
  position: absolute;
  width: 86.68%;
  max-width: 1208px;
  font-family: "Shippori Mincho B1", serif;
  font-size: clamp(2.5rem, 3.7vw, 6rem);
  letter-spacing: 0.015em;
  line-height: 1;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 834px) {
  .mv-page__ttl {
    top: 50%;
  }
}
.mv-page__link {
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 42px;
  width: 86.68%;
  max-width: 1200px;
  border-bottom: 1px solid #e6e6e6;
  top: 76.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 640px) {
  .mv-page__link {
    top: 72%;
    gap: 5px 13px;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 374px) {
  .mv-page__link--custom {
    width: 100%;
    padding: 0 15px;
    border-bottom: none;
  }
  .mv-page__link--custom:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    background: white;
  }
}
.mv-page__link a {
  position: relative;
  display: inline-block;
  color: white;
  padding: 6px 3px;
}
@media screen and (max-width: 640px) {
  .mv-page__link a {
    padding: 0;
  }
}
.mv-page__link a:after {
  content: "";
  position: absolute;
  background: white;
  width: 0;
  height: 3px;
  bottom: -1px;
  left: 0;
  transition: 0.3s ease;
}
@media screen and (max-width: 640px) {
  .mv-page__link a:after {
    height: 2px;
  }
}
.mv-page__link a.active:after {
  width: 100%;
}
@media (any-hover: hover) {
  .mv-page__link a:hover {
    opacity: 1;
  }
  .mv-page__link a:hover:after {
    width: 100%;
  }
}
.mv-page .breadcrumbs {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 2.3vw;
}
@media screen and (max-width: 640px) {
  .mv-page .breadcrumbs {
    text-align: right;
  }
}
.mv-page .breadcrumbs a {
  color: white;
}
.mv-page .breadcrumbs a:after {
  color: white;
}
.mv-page--noimg {
  height: clamp(200px, 26vw, 434px);
  border-bottom: 1px solid #e6e6e6;
  color: #373737;
  margin-bottom: clamp(20px, 3vw, 50px);
}
@media screen and (max-width: 834px) {
  .mv-page--noimg.mv-page--update {
    height: clamp(180px, 26vw, 434px);
  }
  .mv-page--noimg.mv-page--update .mv-page__ttl {
    top: 55%;
  }
  .mv-page--noimg.mv-page--update .sp .breadcrumbs a {
    color: #aaa;
  }
}
@media screen and (min-width: 835px) {
  .mv-page--noimg .mv-page__ttl {
    top: 59%;
  }
}
@media screen and (max-width: 834px) {
  .mv-page--update .breadcrumbs a:after {
    color: #373737;
  }
}

.breadcrumbs {
  font-size: 1.3rem;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 641px) {
  .breadcrumbs {
    text-align: right;
  }
}
@media screen and (max-width: 834px) {
  .breadcrumbs {
    font-size: 1.1rem;
  }
}
.breadcrumbs a {
  position: relative;
  color: #aaaaaa;
  margin-right: 37px;
}
@media screen and (max-width: 834px) {
  .breadcrumbs a {
    margin-right: 20px;
  }
}
.breadcrumbs a:after {
  content: "\e5cc";
  position: absolute;
  font-family: "Material Symbols Outlined";
  color: #373737;
  top: 1px;
  right: -29px;
  pointer-events: none;
}
@media screen and (max-width: 834px) {
  .breadcrumbs a:after {
    top: 0;
    right: -18px;
  }
}
@media (any-hover: hover) {
  .breadcrumbs a:hover {
    opacity: 0.7;
  }
}

.page-single-ir .breadcrumbs span span:nth-child(2) {
  display: none;
}

.form-control {
  display: block;
  width: 100%;
  height: 60px;
  background: white;
  color: #ddd;
  border-radius: 30px;
  outline: none;
  appearance: none;
  resize: none;
  border: 1px solid #ddd;
  font-size: 1.3rem;
  padding: 5px 26px;
}
@media screen and (max-width: 834px) {
  .form-control {
    height: 50px;
    padding: 5px 15px;
  }
}
.form-control ::placeholder {
  color: #ddd;
  opacity: 1;
}
.form-control ::-ms-input-placeholder {
  color: #ddd;
}

.sec-module {
  padding: 110px 0;
}

.block-heading {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.block-heading__ttl {
  line-height: 1.6875;
  width: 54%;
  font-weight: 500;
  letter-spacing: 0;
}
@media screen and (max-width: 990px) {
  .block-heading__ttl {
    width: 100%;
  }
}
.block-heading__ct {
  width: 45%;
  padding-top: 21px;
}
@media screen and (max-width: 990px) {
  .block-heading__ct {
    width: 100%;
  }
}

.heading-common__lead {
  font-size: clamp(3rem, 5.96vw, 10rem);
  font-family: "Marcellus", serif;
  text-align: center;
  color: #5b7c66;
  opacity: 0.2;
  width: 100vw;
  margin-left: calc((1200px - 100vw) / 2);
  letter-spacing: 3px;
  line-height: 1;
}
@media screen and (max-width: 1334px) {
  .heading-common__lead {
    margin-left: -5vw;
  }
}
@media screen and (max-width: 834px) {
  .heading-common__lead {
    letter-spacing: 0;
    margin-left: -6vw;
  }
}
.heading-common .c-ttl__04 {
  margin-top: -26px;
  position: relative;
  z-index: 3;
  margin-bottom: 44px;
}
@media screen and (max-width: 834px) {
  .heading-common .c-ttl__04 {
    margin-top: -11px;
  }
}
.heading-common__link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: clamp(3rem, 4.5vw, 7.7rem);
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 640px) {
  .heading-common__link-item {
    width: calc(50% - 5px);
  }
}
.heading-common__link-item > * {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #5b7c66;
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.875;
  font-weight: 500;
  text-align: center;
  padding: 8px 30px;
  border-radius: 4px;
  height: 100%;
}
@media screen and (max-width: 834px) {
  .heading-common__link-item > * {
    padding: 8px 10px;
  }
}
@media screen and (max-width: 640px) {
  .heading-common__link-item > * {
    line-height: 1.3;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 641px) {
  .heading-common__brief {
    text-align: center;
  }
}

.common-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 40px 0;
}
.common-wrap__ct {
  width: calc(100% - 345px);
}
@media screen and (max-width: 990px) {
  .common-wrap__ct {
    width: 100%;
  }
}
.common-wrap__sidebar {
  width: 280px;
}
@media screen and (max-width: 990px) {
  .common-wrap__sidebar {
    width: 100%;
  }
}

.sidebar-panel:not(:last-child) {
  margin-bottom: 49px;
}
.sidebar-panel.is-open .sidebar-panel__ttl::after {
  transform: rotate(0deg);
}
.sidebar-panel:not(.is-open) .sidebar-panel__ct {
  display: none;
}
.sidebar-panel.ir {
  padding-top: 20px;
}
.sidebar-panel__ttl {
  font-size: clamp(1.8rem, 2vw, 2.2rem);
  font-family: "Shippori Mincho B1", serif;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  padding-bottom: 13px;
  padding-left: 8px;
  margin-bottom: 27px;
  cursor: pointer;
}
.sidebar-panel__ttl::after {
  content: "expand_less";
  font-size: 0.819em;
  font-family: "Material Symbols Outlined";
  position: absolute;
  right: 15px;
  top: 4px;
  transition: all 0.3s ease;
  transform: rotate(180deg);
}
.sidebar-panel__ct {
  padding: 0 9px;
  margin-bottom: 20px;
}
.sidebar-panel--02 {
  margin-bottom: 20px !important;
  background: #f8f8f2;
  border-radius: 8px;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 {
    margin-bottom: 20px !important;
  }
}
.sidebar-panel--02:nth-child(1) {
  margin-top: 39px;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02:nth-child(1) {
    margin-top: 15px;
  }
}
.sidebar-panel--02 .sidebar-panel__ttl {
  border-bottom: 0;
  border-radius: 8px;
  padding: 15px 31px;
  margin-bottom: 0;
  color: #5b7c66;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-panel__ttl {
    padding: 15px 20px;
    font-size: 1.6rem;
  }
}
.sidebar-panel--02 .sidebar-panel__ttl:after {
  display: none;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico {
  margin-right: 7px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-panel__ttl .ico {
    width: 25px;
    height: 25px;
    border: 1px solid white;
    min-width: 25px;
  }
}
.sidebar-panel--02 .sidebar-panel__ttl .ico .material-symbols-outlined {
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-panel__ttl .ico .material-symbols-outlined {
    font-size: 1.1rem;
  }
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray {
  border-color: #e4e4e4;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray .circle-svg .circle-static {
  stroke: #e4e4e4;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray .material-symbols-outlined {
  color: #aaa;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray02 {
  border-color: #aaa;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray02 .circle-svg .circle-static {
  stroke: #aaa;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico.gray02 .material-symbols-outlined {
  color: #aaa;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico .circle-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  stroke-width: 1;
  transform: rotate(-90deg);
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-panel__ttl .ico .circle-svg {
    display: none;
  }
}
.sidebar-panel--02 .sidebar-panel__ttl .ico .circle-svg circle {
  fill: transparent;
  transform: translate(-2px, -2px);
}
.sidebar-panel--02 .sidebar-panel__ttl .ico .circle-svg .circle-static {
  stroke: white;
  stroke-width: 1;
}
.sidebar-panel--02 .sidebar-panel__ttl .ico .circle-svg .circle-hover {
  stroke: #5b7c66;
  stroke-width: 0;
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
  animation-timing-function: cubic-bezier(0, 0, 0, 0.97);
}
.sidebar-panel--02 .sidebar-categories {
  padding: 11px 32px 25px;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-categories {
    padding: 11px 20px 25px;
  }
}
.sidebar-panel--02 .sidebar-categories__item a {
  padding-left: 20px;
  position: relative;
  display: block;
}
.sidebar-panel--02 .sidebar-categories__item a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 1px;
  background: #e1dccd;
  top: 8px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .sidebar-panel--02 .sidebar-categories__item a {
    font-size: 1.4rem;
  }
}
.sidebar-panel--02 .sidebar-panel__ct {
  display: block !important;
}
.sidebar-panel--open .sidebar-panel__ttl::after {
  display: none;
}
.sidebar-panel--open .sidebar-panel__ct {
  display: block !important;
}

.sidebar-categories__item, .sidebar-categories li {
  font-size: 1.4rem;
  line-height: 1.4;
}
.sidebar-categories__item:not(:last-child), .sidebar-categories li:not(:last-child) {
  margin-bottom: 15px;
}
@media (any-hover: hover) {
  .sidebar-categories__item:hover, .sidebar-categories li:hover {
    opacity: 0.7;
  }
}
.sidebar-categories__item a, .sidebar-categories li a {
  font-size: 1.4rem;
  line-height: 1.4;
}

.sidebar-tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 12px 8px;
}
.sidebar-tags__item a {
  border-radius: 3px;
  background: #ececdc;
  font-size: 1.3rem;
  line-height: 1.4;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 13px;
}
@media (any-hover: hover) {
  .sidebar-tags__item a:hover {
    opacity: 0.7;
  }
}

.sidebar-link__item:not(:last-child) {
  margin-bottom: 17px;
}
.sidebar-link__item a {
  display: inline-block;
  font-size: 1.5rem;
}

.nums-page .wp-pagenavi {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px 10px;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi {
    gap: 5px 0;
  }
}
.nums-page .wp-pagenavi > * {
  min-height: 50px;
  min-width: 50px;
  border-radius: 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  border: 0;
}
@media screen and (max-width: 834px) {
  .nums-page .wp-pagenavi > * {
    min-height: 30px;
    min-width: 30px;
  }
}
@media screen and (max-width: 374px) {
  .nums-page .wp-pagenavi > * {
    min-height: 25px;
    min-width: 25px;
  }
}
.nums-page .wp-pagenavi > *.current {
  background: #373737;
  color: white;
}
@media (any-hover: hover) {
  .nums-page .wp-pagenavi > *:hover {
    background: #373737;
    color: white;
  }
}
.nums-page .wp-pagenavi > *.prevtpostslink, .nums-page .wp-pagenavi > *.previouspostslink, .nums-page .wp-pagenavi > *.nextpostslink {
  border: 1px solid #BFBFBF !important;
}
.nums-page .wp-pagenavi > *.prevtpostslink::before, .nums-page .wp-pagenavi > *.previouspostslink::before {
  content: "chevron_left";
  font-family: "Material Symbols Outlined";
  font-size: 0.819em;
}
.nums-page .wp-pagenavi > *.nextpostslink::before {
  content: "chevron_right";
  font-family: "Material Symbols Outlined";
  font-size: 0.819em;
}

.anchor-link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 115px;
  gap: 20px 0;
}
.anchor-link__item {
  width: 25%;
  border-bottom: 1px solid #E6E6E6;
}
@media screen and (max-width: 640px) {
  .anchor-link__item {
    width: 50%;
  }
}
.anchor-link__item .c-btn__01 {
  font-size: clamp(1.6rem, 2vw, 2.2rem);
  justify-content: center;
  width: 100%;
  flex-direction: row-reverse;
  padding-bottom: 31px;
  gap: 10px 15px;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .anchor-link__item .c-btn__01 {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 640px) {
  .anchor-link__item .c-btn__01 {
    padding-bottom: 10px;
    position: relative;
    padding-right: 30px;
  }
}
.anchor-link__item .c-btn__01 .ico {
  transform: rotate(90deg);
}
@media screen and (max-width: 640px) {
  .anchor-link__item .c-btn__01 .ico {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.main-header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  transition: background 0.3s;
}
.main-header__inner {
  height: 100px;
  gap: 38px;
  padding: 0 90px;
}
@media screen and (max-width: 1440px) {
  .main-header__inner {
    padding: 0 16px;
    gap: 15px;
  }
}
@media screen and (max-width: 1200px) {
  .main-header__inner {
    height: 59px;
  }
}
.main-header__logo {
  width: 187px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .main-header__logo {
    width: 120px;
  }
}
@media (any-hover: hover) {
  .main-header__logo:hover {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  .main-header__logo:hover {
    opacity: 0.7;
  }
}
.main-header__logo > * {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header__logo .active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.main-header__menu {
  font-size: 1.5rem;
  gap: 27px;
  letter-spacing: 0.05em;
  margin-top: 4px;
}
@media screen and (max-width: 1440px) {
  .main-header__menu {
    gap: 15px;
  }
}
@media (any-hover: hover) {
  .main-header__menu:hover {
    color: #aaa;
  }
}
@media (hover: hover) {
  .main-header__menu:hover {
    color: #aaa;
  }
}
@media (any-hover: hover) {
  .main-header__menu a:hover {
    color: #373737;
  }
}
@media (hover: hover) {
  .main-header__menu a:hover {
    color: #373737;
  }
}
.main-header__action {
  margin: 0 -21px 0 auto;
}
@media screen and (max-width: 1200px) {
  .main-header__action {
    margin: 0 -11px 0 auto;
  }
}
@media (any-hover: hover) {
  .main-header__action:hover {
    color: #aaa;
  }
}
@media (hover: hover) {
  .main-header__action:hover {
    color: #aaa;
  }
}
.main-header__action > * {
  width: 67px;
  height: 40px;
  border-right: 1px solid #dddddd;
}
@media screen and (max-width: 1200px) {
  .main-header__action > * {
    width: 45px;
    height: 30px;
  }
}
.main-header__action > *:last-child {
  border-right: none;
}
.main-header__action-item {
  height: 100%;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (any-hover: hover) {
  .main-header__action-item:hover .material-symbols-outlined {
    color: black;
  }
}
@media (hover: hover) {
  .main-header__action-item:hover .material-symbols-outlined {
    color: black;
  }
}
.main-header__action-item .material-symbols-outlined {
  font-size: 2.6rem;
}
@media screen and (max-width: 1200px) {
  .main-header__action-item .material-symbols-outlined {
    font-size: 1.8rem;
  }
}
.main-header__popup {
  background: #f7f7f7;
  padding: 20px;
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  display: none;
  min-height: 100px;
}
@media screen and (max-width: 1200px) {
  .main-header__popup {
    top: 59px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__popup {
    padding: 15px 0;
    min-height: 11.99vw;
  }
}
.main-header__popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.main-header__popup-close .material-symbols-outlined {
  font-size: 2rem;
  color: #ccc;
}
.main-header__search {
  max-width: 480px;
  margin: 0 auto;
  position: relative;
  color: #373737;
}
.main-header__search-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 100%;
  width: 60px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  background: transparent;
  border-radius: 0 30px 30px 0;
}
.main-header__search-btn .material-symbols-outlined {
  font-size: 2rem;
}
.main-header__search .form-control {
  color: #373737;
  font-size: 1.6rem;
}
.main-header__search .form-control::placeholder {
  color: #ddd;
}
.main-header__lang {
  font-size: 1.5rem;
  min-height: 60px;
  color: #010101;
}
@media screen and (max-width: 834px) {
  .main-header__lang {
    min-height: 30px;
    gap: 15px;
  }
}
@media (any-hover: hover) {
  .main-header__lang > *:hover {
    color: #999;
  }
}
@media (hover: hover) {
  .main-header__lang > *:hover {
    color: #999;
  }
}
.main-header__lang #google_language_translator {
  display: none !important;
}
.main-header__lang #flags {
  width: auto !important;
}
.main-header__lang #flags ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  float: none !important;
}
@media screen and (max-width: 834px) {
  .main-header__lang #flags ul {
    gap: 15px;
  }
}
.main-header__lang #flags.size18 a {
  position: relative;
  margin: 0 !important;
  width: 3em !important;
  height: 1em !important;
  background: none !important;
}
.main-header__lang #flags.size18 a[title=Japanese]:before {
  content: "日本語";
}
.main-header__lang #flags.size18 a[title=English] {
  width: 3.5em !important;
}
.main-header__lang #flags.size18 a[title=English]:before {
  content: "English";
}
.main-header__lang #flags.size18 a[title="Chinese (Traditional)"]:before {
  content: "繋体字";
}
.main-header__lang #flags.size18 a[title="Chinese (Simplified)"]:before {
  content: "簡体字";
}
.main-header__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.9);
  color: #373737;
  padding: 122px 0 0;
  font-size: 1.3rem;
  line-height: 2.1538461538;
  font-weight: 300;
  opacity: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 1024px) {
  .main-header__nav {
    padding: 50px 0 0;
  }
}
@media screen and (max-width: 834px) {
  .main-header__nav {
    font-size: 1.2rem;
    line-height: 2.4166666667;
    padding: 35px 0 0;
  }
}
.main-header__nav.is-show {
  opacity: 1;
  pointer-events: auto;
}
.main-header__nav .main-header__logo {
  margin-top: 19px;
}
@media screen and (max-width: 1200px) {
  .main-header__nav .main-header__logo {
    width: 179px;
  }
}
@media screen and (max-width: 990px) {
  .main-header__nav .main-header__logo {
    margin: 0 auto 15px;
  }
}
@media (any-hover: hover) {
  .main-header__nav a:hover {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  .main-header__nav a:hover {
    opacity: 0.7;
  }
}
.main-header__flex {
  margin-bottom: 100px;
}
@media screen and (max-width: 990px) {
  .main-header__flex {
    flex-direction: column;
  }
}
.main-header__ct {
  width: calc(100% - 260px);
}
@media screen and (max-width: 1200px) {
  .main-header__ct {
    width: calc(100% - 194px);
  }
}
@media screen and (max-width: 990px) {
  .main-header__ct {
    width: 100%;
  }
}
@media screen and (min-width: 991px) {
  .main-header__grid {
    max-width: 810px;
  }
}
@media screen and (max-width: 640px) {
  .main-header__grid {
    margin-bottom: 21px;
  }
}
.main-header__navMenu {
  border-left: 1px solid #ddd;
  padding: 21px 29px 19px;
}
@media screen and (max-width: 990px) {
  .main-header__navMenu {
    padding: 15px;
  }
}
@media screen and (max-width: 640px) {
  .main-header__navMenu {
    padding: 0;
    border-left: none;
  }
}
.main-header__navMenu > * {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .main-header__navMenu > * {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 640px) {
  .main-header__navMenu > * {
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
  }
}
.main-header__navMenu > *:last-child {
  margin-bottom: 0;
}
.main-header__navLink {
  font-size: 2.1rem;
  font-weight: 500;
  position: relative;
  display: block;
}
@media screen and (max-width: 834px) {
  .main-header__navLink {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .main-header__navLink {
    padding: 10px 70px 10px 0;
  }
  .main-header__navLink.is-active .main-header__ico::after {
    opacity: 0;
  }
}
@media screen and (max-width: 640px) {
  .main-header__ico {
    position: absolute;
    width: 60px;
    height: 60px;
    right: -23px;
    top: 0;
  }
  .main-header__ico::before, .main-header__ico::after {
    content: "";
    position: absolute;
    background: #373737;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .main-header__ico::before {
    width: 10px;
    height: 2px;
  }
  .main-header__ico::after {
    width: 2px;
    height: 10px;
  }
}
.main-header__navSub {
  margin: -2px 0 0;
  padding-bottom: 3px;
}
@media screen and (max-width: 640px) {
  .main-header__navSub {
    display: none;
    margin: -5px 0 0;
    padding-bottom: 4px;
  }
}
.main-header__link {
  display: flex;
  margin: -102px 0 0;
  padding: 0 7px;
}
@media screen and (min-width: 641px) {
  .main-header__link {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media screen and (max-width: 640px) {
  .main-header__link {
    margin: 0;
    padding: 0;
    gap: 24px;
  }
}
@media screen and (max-width: 374px) {
  .main-header__link {
    gap: 15px;
  }
}
.main-header__mobile {
  position: relative;
  cursor: pointer;
  z-index: 12;
}
@media (any-hover: hover) {
  .main-header__mobile:hover {
    opacity: 0.7;
  }
  .main-header__mobile:hover span {
    background: black;
  }
  .main-header__mobile:hover span::before, .main-header__mobile:hover span::after {
    background: black;
  }
}
@media (hover: hover) {
  .main-header__mobile:hover {
    opacity: 0.7;
  }
  .main-header__mobile:hover span {
    background: black;
  }
  .main-header__mobile:hover span::before, .main-header__mobile:hover span::after {
    background: black;
  }
}
.main-header__mobile span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 25px;
  height: 2px;
  background: white;
  border-radius: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 1200px) {
  .main-header__mobile span {
    width: 18px;
  }
}
.main-header__mobile span:before, .main-header__mobile span:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  content: "";
  border-radius: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-header__mobile span:before {
  -webkit-transform: translateY(-250%);
  transform: translateY(-250%);
  top: -4px;
}
@media screen and (max-width: 1200px) {
  .main-header__mobile span:before {
    top: -2px;
  }
}
.main-header__mobile span:after {
  -webkit-transform: translateY(250%);
  transform: translateY(250%);
  bottom: -4px;
}
@media screen and (max-width: 1200px) {
  .main-header__mobile span:after {
    bottom: -2px;
  }
}
.main-header__mobile.is-close span {
  background: transparent;
}
.main-header__mobile.is-close span:before, .main-header__mobile.is-close span:after {
  width: 100%;
  background: black;
}
.main-header__mobile.is-close span:before {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
  top: 2px;
}
.main-header__mobile.is-close span:after {
  -webkit-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
  bottom: -2px;
}
.main-header.is-active {
  background: white;
  color: black;
}
.main-header.is-active .main-header__logo .active {
  opacity: 1;
}
.main-header.is-active .main-header__logo .main {
  opacity: 0;
}
.main-header.is-active .main-header__mobile span {
  background: black;
}
.main-header.is-active .main-header__mobile span::before, .main-header.is-active .main-header__mobile span::after {
  background: black;
}
.main-header.is-active .main-header__mobile.is-close span {
  background: transparent;
}
.main-header.header-dark {
  color: black;
}
.main-header.header-dark .main-header__logo .active {
  opacity: 1;
}
.main-header.header-dark .main-header__logo .main {
  opacity: 0;
}
.main-header.header-dark .main-header__mobile span {
  background: black;
}
.main-header.header-dark .main-header__mobile span::before, .main-header.header-dark .main-header__mobile span::after {
  background: black;
}
.main-header.header-dark .main-header__mobile.is-close span {
  background: transparent;
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}

#glt-translate-trigger {
  display: none !important;
}

.main-footer {
  background: #313131;
  color: white;
  padding: 106px 0 197px;
}
@media screen and (max-width: 834px) {
  .main-footer {
    padding: 50px 0;
  }
}
@media (any-hover: hover) {
  .main-footer a:hover {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  .main-footer a:hover {
    opacity: 0.7;
  }
}
@media screen and (min-width: 991px) {
  .main-footer__nav {
    gap: 15px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 990px) {
  .main-footer__nav {
    padding-bottom: 30px;
    margin-bottom: -1em;
  }
}
@media screen and (min-width: 1201px) {
  .main-footer__menu {
    min-width: 147px;
  }
}
@media screen and (max-width: 990px) {
  .main-footer__menu {
    width: calc(25% - 12px);
    margin-bottom: 1em;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menu {
    width: calc(25% - 10px);
  }
}
@media screen and (max-width: 640px) {
  .main-footer__menu {
    width: calc(50% - 8px);
  }
}
.main-footer__flex {
  gap: 15px;
}
@media screen and (min-width: 835px) {
  .main-footer__flex {
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
}
.main-footer__txt {
  margin-bottom: 1px;
}
@media screen and (max-width: 834px) {
  .main-footer__txt {
    margin-bottom: 10px;
  }
}
.main-footer__social {
  gap: 12px;
}
@media screen and (max-width: 834px) {
  .main-footer__social {
    justify-content: center;
  }
}
.main-footer__logo {
  width: 232px;
}
@media screen and (max-width: 834px) {
  .main-footer__logo {
    width: 186px;
  }
}
.main-footer__bottom {
  border-top: 1px solid #6f6f6f;
  padding-top: 26px;
}
@media screen and (min-width: 835px) {
  .main-footer__bottom {
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__bottom {
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 30px;
  }
}
.main-footer__link {
  gap: 42px;
}
@media screen and (max-width: 834px) {
  .main-footer__link {
    gap: 15px;
  }
}
.main-footer__copyright {
  font-size: 1rem;
  line-height: 2.8;
  letter-spacing: 0.043em;
}
@media screen and (min-width: 835px) {
  .main-footer__copyright {
    margin-top: -3px;
  }
}
.main-footer .txt {
  font-size: 1.6rem;
  line-height: 2.875;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .main-footer .txt {
    font-size: 1.4rem;
    line-height: 2;
  }
}
.main-footer .txt-sm {
  font-size: 1.3rem;
  line-height: 2.1538461538;
  font-weight: 300;
}
@media screen and (max-width: 834px) {
  .main-footer .txt-sm {
    font-size: 1.1rem;
  }
}

.top-mv {
  position: relative;
  z-index: 1;
  padding: 23.4vw 0 5.65vw;
}
@media screen and (max-width: 834px) {
  .top-mv {
    padding: 249px 0 40px;
  }
}
.top-mv__slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.top-mv__slider::before {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
.top-mv__slider-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.top-mv__slider-item.zoom-in img {
  animation: zoomIn 4.5s ease-out forwards;
}
.top-mv__slider .slick-list, .top-mv__slider .slick-track {
  height: 100%;
}
.top-mv__slider .slick-dots {
  width: 30px;
  top: 22.05%;
  right: 5.35%;
  bottom: auto;
  left: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 834px) {
  .top-mv__slider .slick-dots {
    width: 26px;
    top: 19.6%;
    right: 4.4%;
    gap: 8px;
  }
}
.top-mv__slider .slick-dots li {
  margin: 0;
}
.top-mv__slider .slick-dots li button {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
@media screen and (max-width: 834px) {
  .top-mv__slider .slick-dots li button {
    width: 26px;
    height: 26px;
  }
}
.top-mv__slider .slick-dots li button::before {
  content: "";
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: none;
  margin: 0;
}
@media screen and (max-width: 834px) {
  .top-mv__slider .slick-dots li button::before {
    width: 7px;
    height: 7px;
  }
}
.top-mv__slider .slick-dots li button .circle-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  stroke-width: 1;
  transform: rotate(-90deg);
}
.top-mv__slider .slick-dots li button .circle-svg .circle-hover {
  fill: transparent;
  transform: translate(-2px, -2px);
  stroke: white;
  stroke-width: 0;
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
  animation-timing-function: cubic-bezier(0, 0, 0, 0.97);
}
.top-mv__slider .slick-dots li.slick-active button .circle-svg circle {
  stroke-width: 1;
  animation: anim-circle-m 14s forwards;
}
@media screen and (min-width: 835px) {
  .top-mv__inner {
    padding-left: 49px;
  }
}
.top-mv__ttl {
  font-size: 6rem;
  line-height: 1.35;
  letter-spacing: -0.04em;
}
@media screen and (max-width: 834px) {
  .top-mv__ttl {
    font-size: 3rem;
    line-height: 1.35;
  }
}
.top-mv__ttl small {
  font-size: 1.2rem;
  line-height: 3.75;
  display: block;
  letter-spacing: 0.045em;
  padding: 0 3px;
}
@media screen and (max-width: 834px) {
  .top-mv__ttl small {
    font-size: 1rem;
    line-height: 4.15;
  }
}
.top-mv__scroll {
  font-size: 1.2rem;
  gap: 10px;
  margin-top: 87px;
  letter-spacing: 0.04em;
}
.top-mv__scroll-circle {
  width: 13px;
  height: 13px;
  position: relative;
}
.top-mv__scroll-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  animation: transformCircleLoop 2s cubic-bezier(0.52, 0.08, 0.18, 1) infinite;
}

@keyframes zoomIn {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes transformCircleLoop {
  0% {
    transform: translateY(-150%) scale(0.8);
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(150%) scale(0.5);
    opacity: 0;
  }
}
.top-notice {
  padding: 109px 0 112px;
}
@media screen and (max-width: 834px) {
  .top-notice {
    padding: 38px 0;
  }
}
.top-notice--01 {
  padding-bottom: 40px;
}
.top-notice__inner {
  position: relative;
}
.top-notice__top {
  border-bottom: 1px solid #e6e6e6;
  padding-right: 230px;
}
@media screen and (max-width: 834px) {
  .top-notice__top {
    margin-left: calc((100% - 100vw) / 2);
    margin-right: calc((100% - 100vw) / 2);
    padding: 0 6.66%;
    overflow-x: auto;
  }
}
.top-notice__top::-webkit-scrollbar {
  display: none;
}
.top-notice__cate {
  font-size: 1.5rem;
  color: #9b9b9b;
  white-space: nowrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .top-notice__cate {
    font-size: 1.2rem;
  }
}
.top-notice__cate > * {
  cursor: pointer;
  position: relative;
  padding: 9px 7px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 33px;
}
@media screen and (max-width: 834px) {
  .top-notice__cate > * {
    margin-right: 24px;
  }
}
.top-notice__cate > *:last-of-type {
  margin-right: 0;
}
.top-notice__cate > *::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  transform: scale(0);
  bottom: -1px;
  height: 3px;
  background: black;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (any-hover: hover) {
  .top-notice__cate > *:hover {
    color: black;
  }
  .top-notice__cate > *:hover::before {
    transform: scale(1);
  }
}
@media (hover: hover) {
  .top-notice__cate > *:hover {
    color: black;
  }
  .top-notice__cate > *:hover::before {
    transform: scale(1);
  }
}
.top-notice__cate > *.is-active {
  color: black;
}
.top-notice__cate > *.is-active::before {
  transform: scale(1);
}
@media screen and (min-width: 1025px) {
  .top-notice__btn {
    position: absolute;
    right: -3px;
    top: 81px;
  }
}
@media screen and (max-width: 1200px) {
  .top-notice__btn {
    top: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .top-notice__btn {
    margin: 10px -3px 0 0;
    text-align: right !important;
  }
}
.top-notice__body-item {
  display: none;
}
.top-notice__body-item.is-active {
  display: block;
}
.top-notice__body-empty {
  font-size: 1.4rem;
}
.top-notice__list li {
  background: #FFF;
}
.top-notice__list li:nth-of-type(2n) {
  background: #f4f4f4;
}
.top-notice__list-link {
  border-radius: 8px;
  padding: 25px 50px;
  gap: 29px;
}
@media screen and (max-width: 834px) {
  .top-notice__list-link {
    padding: 11px 15px;
    gap: 16px;
  }
}
@media (any-hover: hover) {
  .top-notice__list-link:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.04);
  }
}
@media (hover: hover) {
  .top-notice__list-link:hover {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.04);
  }
}
.top-notice__list-img {
  position: relative;
  width: 34.43%;
}
.top-notice__list-img::before {
  display: block;
  content: "";
  padding-bottom: 73.6842105263%;
}
@media screen and (max-width: 834px) {
  .top-notice__list-img::before {
    padding-bottom: 62%;
  }
}
.top-notice__list-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  border-radius: 8px;
}
.top-notice__list-ct {
  gap: 7px;
}
@media screen and (max-width: 834px) {
  .top-notice__list-ct {
    gap: 2px;
  }
}
.top-notice__list-ttl {
  font-size: 1.5rem;
  line-height: 1.6;
}
@media screen and (max-width: 834px) {
  .top-notice__list-ttl {
    font-size: 1.2rem;
  }
}
.top-notice__list-info {
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 1px;
}
@media screen and (max-width: 834px) {
  .top-notice__list-info {
    gap: 5px;
  }
}
.top-notice__list-tag {
  font-size: 1.3rem;
  line-height: 2.1538461538;
  border-radius: 3px;
  border: 1px solid #ddd;
  padding: 0 9px;
  background: #FFF;
}
@media screen and (max-width: 834px) {
  .top-notice__list-tag {
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 6px;
  }
}
.top-notice__list-date {
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 834px) {
  .top-notice__list-date {
    font-size: 1rem;
  }
}

.top-introduction {
  background: #f8f8f2;
  padding: 111px 0 121px;
}
@media screen and (max-width: 834px) {
  .top-introduction {
    padding: 50px 0;
  }
}
.top-introduction__list {
  gap: 50px 41px;
}
@media screen and (max-width: 834px) {
  .top-introduction__list {
    gap: 30px 15px;
  }
}
.top-introduction__list-link {
  gap: 14px;
}
@media (any-hover: hover) {
  .top-introduction__list-link:hover .top-introduction__list-img img {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .top-introduction__list-link:hover .top-introduction__list-img img {
    transform: scale(1.1);
  }
}
.top-introduction__list-link .c-btn__01 {
  gap: 12px;
}
.top-introduction__list-img {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.top-introduction__list-img::before {
  content: "";
  display: block;
  padding-bottom: 68.9655172414%;
}
.top-introduction__list-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.top-introduction__list-ct {
  padding: 6px 0 0 0;
}
@media screen and (max-width: 834px) {
  .top-introduction__list-ct {
    padding: 0;
  }
}
.top-introduction__list-ct-ttl {
  display: block;
}
.top-introduction__list-ct-ttl span {
  display: block;
}
.top-introduction__list-ct-ttl .c-ft--jp {
  font-size: 2.6rem;
  color: #373737;
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .top-introduction__list-ct-ttl .c-ft--jp {
    font-size: 1.8rem;
  }
}
.top-introduction__list-ct-ttl .c-ft--en {
  font-size: 1.8rem;
  color: #5B7C66;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media screen and (max-width: 834px) {
  .top-introduction__list-ct-ttl .c-ft--en {
    font-size: 1.2rem;
  }
}

.top-intrance {
  background: #f8f8f2;
  padding: 0 0 121px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-intrance {
    padding: 0 0 50px;
  }
}
.top-intrance__box {
  position: relative;
  z-index: 1;
  padding: 113px 147px 120px;
}
@media screen and (max-width: 1200px) {
  .top-intrance__box {
    padding: 50px;
  }
}
@media screen and (max-width: 834px) {
  .top-intrance__box {
    padding: 30px 15px;
  }
}
.top-intrance__img {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100%;
}
.top-intrance__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  border-radius: 8px;
}
.top-intrance__des {
  font-size: 2.4rem;
  line-height: 3.375;
  margin-bottom: 248px;
}
@media screen and (max-width: 1200px) {
  .top-intrance__des {
    font-size: 2rem;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 834px) {
  .top-intrance__des {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}
.top-intrance__list {
  max-width: 536px;
  margin-left: auto;
  gap: 16px 51px;
}
@media screen and (max-width: 834px) {
  .top-intrance__list {
    gap: 10px 15px;
  }
}
.top-intrance__list .c-btn__01 {
  gap: 12px;
}
@media screen and (max-width: 834px) {
  .top-intrance__list .c-btn__01 {
    gap: 5px;
  }
}

.top-information {
  padding: 119px 0 109px;
}
@media screen and (max-width: 834px) {
  .top-information {
    padding: 50px 0;
  }
}
.top-information__inner {
  position: relative;
}
.top-information__ttl {
  width: 250px;
  padding: 38px 15px 38px 0;
}
@media screen and (max-width: 1024px) {
  .top-information__ttl {
    width: 170px;
  }
}
@media screen and (max-width: 834px) {
  .top-information__ttl {
    width: 100%;
    padding: 0 0 30px;
  }
}
.top-information__list {
  width: calc(100% - 250px);
  gap: 36px 30px;
}
@media screen and (max-width: 1024px) {
  .top-information__list {
    width: calc(100% - 170px);
  }
}
@media screen and (max-width: 834px) {
  .top-information__list {
    width: 100%;
    gap: 15px;
  }
}
.top-information__list-link {
  gap: 1px;
}
@media (any-hover: hover) {
  .top-information__list-link:hover .top-information__list-img img {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .top-information__list-link:hover .top-information__list-img img {
    transform: scale(1.1);
  }
}
.top-information__list-box {
  position: relative;
}
.top-information__list-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.top-information__list-img::before {
  display: block;
  content: "";
  padding-bottom: 56.5217391304%;
}
.top-information__list-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.top-information__list-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.top-information__list-ttl {
  font-size: 1.8rem;
  line-height: 2.5555555556;
}
@media screen and (max-width: 834px) {
  .top-information__list-ttl {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 835px) {
  .top-information__btn {
    position: absolute;
    left: 0;
    top: 118px;
  }
}
@media screen and (max-width: 834px) {
  .top-information__btn {
    width: 100%;
    text-align: right;
  }
}

.top-news {
  background: #f8f8f2;
  padding: 118px 0 121px;
}
@media screen and (max-width: 834px) {
  .top-news {
    padding: 50px 0;
  }
}
@media screen and (max-width: 834px) {
  .top-news__inner {
    flex-direction: column-reverse;
  }
}
.top-news__inner > * {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .top-news__inner > * {
    width: 100%;
  }
}
.top-news__article {
  position: relative;
}
.top-news__article-link {
  display: block;
}
@media (any-hover: hover) {
  .top-news__article-link:hover .top-news__article-img img {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .top-news__article-link:hover .top-news__article-img img {
    transform: scale(1.1);
  }
}
.top-news__article-img {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.top-news__article-img::before {
  display: block;
  content: "";
  padding-bottom: 81.3333333333%;
}
.top-news__article-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.top-news__article-ct {
  position: absolute;
  bottom: 43px;
  left: 0;
  right: 0;
  padding: 0 43px;
}
@media screen and (max-width: 834px) {
  .top-news__article-ct {
    padding: 0 15px;
    bottom: 15px;
  }
}
.top-news__link {
  gap: 16px 0;
  padding-right: 13px;
}
@media screen and (max-width: 1024px) {
  .top-news__link {
    padding-right: 0;
    margin-right: -40px;
  }
}
@media screen and (max-width: 834px) {
  .top-news__link {
    gap: 15px;
    margin-right: 0;
  }
}
@media screen and (min-width: 835px) {
  .top-news__right {
    max-width: 555px;
    padding: 0 0 0 15px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 834px) {
  .top-news__right {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 835px) {
  .top-news__list {
    margin-top: -12px;
  }
}
.top-news__list-link {
  gap: 4px;
  border-bottom: 1px solid #e6e6e6;
  padding: 25px 0 27px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .top-news__list-link {
    padding: 15px 0;
  }
}
@media (any-hover: hover) {
  .top-news__list-link:hover::before {
    width: 100%;
  }
}
@media (hover: hover) {
  .top-news__list-link:hover::before {
    width: 100%;
  }
}
.top-news__list-link::before {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  bottom: -1px;
  background: #5b7c66;
  width: 0;
  transition: all 0.3s ease-in-out;
}
.top-news__list-ttl {
  line-height: 1.6;
}
.top-news__list-date {
  font-size: 1.2rem;
}

.top-society {
  padding: 17.2vw 0 17.9vw;
  background: url(../img/index/society-img.jpg) center/cover;
}
@media screen and (max-width: 834px) {
  .top-society {
    padding: 15vw 0;
  }
}
.top-society:not(.ios) {
  background-image: url(../img/index/society-img.jpg);
  background-repeat: no-repeat;
  background-size: 300vh auto;
  background-position: top center;
  background-attachment: fixed;
}
.top-society__inner {
  position: relative;
}
@media screen and (min-width: 641px) {
  .top-society__inner .c-btn__01 {
    position: absolute;
    top: 50%;
    right: 0;
  }
}

.top-useful {
  padding: 110px 0 111px;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .top-useful {
    padding: 50px 0;
  }
}
.top-useful__inner {
  position: relative;
}
.top-useful__list {
  margin: 0 -30px;
}
@media screen and (max-width: 1024px) {
  .top-useful__list {
    margin: 0 -15px;
  }
}
.top-useful__list-item {
  padding: 0 30px;
}
@media screen and (max-width: 1024px) {
  .top-useful__list-item {
    padding: 0 15px;
  }
}
@media screen and (max-width: 640px) {
  .top-useful__list-item {
    width: 60vw;
  }
}
.top-useful__list-link {
  gap: 18px;
}
@media screen and (max-width: 834px) {
  .top-useful__list-link {
    gap: 10px;
  }
}
@media (any-hover: hover) {
  .top-useful__list-link:hover .top-useful__list-img img {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .top-useful__list-link:hover .top-useful__list-img img {
    transform: scale(1.1);
  }
}
.top-useful__list-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.top-useful__list-img::before {
  display: block;
  content: "";
  padding-bottom: 72.2222222222%;
}
.top-useful__list-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.top-useful__list .slick-list {
  overflow: visible;
}
.top-useful__list .slick-track {
  cursor: pointer;
}
@media screen and (min-width: 835px) {
  .top-useful__btn {
    position: absolute;
    top: 16px;
    right: 0;
  }
}

.top-article {
  background: #f8f8f2;
  padding: 121px 0 120px;
}
@media screen and (max-width: 834px) {
  .top-article {
    padding: 50px 0;
  }
}
.top-article__list {
  gap: 2.25%;
}
@media screen and (max-width: 834px) {
  .top-article__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
.top-article__list-link {
  display: block;
  position: relative;
}
@media (any-hover: hover) {
  .top-article__list-link:hover .top-article__list-img img {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .top-article__list-link:hover .top-article__list-img img {
    transform: scale(1.1);
  }
}
.top-article__list-link .c-btn__01 {
  position: absolute;
  bottom: 27px;
  left: 10.7%;
}
@media screen and (max-width: 1024px) {
  .top-article__list-link .c-btn__01 {
    left: 10px;
  }
}
@media screen and (max-width: 640px) {
  .top-article__list-link .c-btn__01 {
    bottom: 10px;
    left: 5px;
    letter-spacing: 0;
  }
}
.top-article__list-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.top-article__list-img::before {
  display: block;
  content: "";
  padding-bottom: 135.7142857143%;
}
.top-article__list-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sec-value {
  background: #f8f8f2;
  padding: clamp(50px, 6vw, 100px) 0 clamp(50px, 12vw, 201px);
}

.value-main {
  position: relative;
  margin-bottom: clamp(50px, 12.3vw, 206px);
}
.value-main img {
  width: 100%;
}
.value-main__ttl {
  text-align: center;
  position: absolute;
  width: 90%;
  max-width: clamp(270px, 56vw, 750px);
  color: white;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  font-size: clamp(2rem, 3vw, 4rem);
  line-height: 1.8;
  top: 49.9%;
  left: 50.3%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 374px) {
  .value-main__ttl {
    font-size: 1.8rem;
  }
}

.value-wrap {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (min-width: 641px) {
  .value-wrap {
    padding-left: 600px;
  }
}
@media screen and (min-width: 641px) and (max-width: 1270px) {
  .value-wrap {
    padding-left: 51%;
  }
}
.value-wrap__ttl {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  font-size: clamp(1.8rem, 2vw, 2.8rem);
  line-height: 1.75;
  letter-spacing: -0.03em;
  margin-bottom: clamp(20px, 2vw, 33px);
}
.value-wrap__ttl-logo {
  display: block;
}
@media screen and (max-width: 640px) {
  .value-wrap__ttl-logo {
    text-align: center;
  }
}
.value-wrap__ttl img {
  max-width: 200px;
  margin-bottom: clamp(25px, 2.8vw, 47px);
}
@media screen and (max-width: 834px) {
  .value-wrap__ttl img {
    max-width: 150px;
  }
}
@media screen and (min-width: 641px) {
  .value-wrap__img {
    position: absolute;
    width: 43.3%;
    top: 49%;
    left: 0;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 640px) {
  .value-wrap__img {
    width: 90%;
    margin: 0 auto 30px;
  }
}
.value-wrap p:not(:last-child) {
  margin-bottom: clamp(15px, 1.6vw, 26px);
}

.sec-business {
  padding: clamp(50px, 6.6vw, 110px) 0 clamp(50px, 9.5vw, 160px);
}

.business-ttl {
  text-align: center;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  font-size: clamp(2rem, 2.7vw, 3.6rem);
  letter-spacing: 0.03em;
  margin-bottom: clamp(30px, 4.4vw, 74px);
}

.business-list__item:not(:last-child) {
  margin-bottom: clamp(50px, 6vw, 100px);
}
@media screen and (min-width: 641px) {
  .business-list__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 641px) {
  .business-list__ct {
    width: 41.6%;
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .business-list__ct {
    width: 56%;
  }
}
.business-list__ct-ttl {
  margin-bottom: clamp(15px, 1.6vw, 26px);
}
.business-list__ct-ttl .c-ft--en {
  display: block;
  color: #5b7c66;
  opacity: 0.2;
  font-size: clamp(2.5rem, 4.5vw, 5.2rem);
  letter-spacing: 0.03em;
  line-height: 1;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .business-list__ct-ttl .c-ft--en {
    margin-bottom: 5px;
  }
}
.business-list__ct-ttl .c-ft--jp {
  display: block;
  font-weight: 500;
  font-size: clamp(1.8rem, 2vw, 2.6rem);
  letter-spacing: 0.02em;
}
.business-list__ct-tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: clamp(20px, 3.3vw, 53px);
}
@media screen and (max-width: 1024px) {
  .business-list__ct-tag {
    gap: 7px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 374px) {
  .business-list__ct-tag {
    font-size: 1rem;
  }
}
.business-list__ct-tag span {
  border-radius: 3px;
  border: 1px solid #dddddd;
  padding: 9px 14px 7px;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 1024px) {
  .business-list__ct-tag span {
    padding: 6px 10px 5px;
  }
}
.business-list__ct-btn {
  margin-top: clamp(10px, 2.1vw, 34px);
  text-align: right;
}
.business-list__img {
  position: relative;
}
@media screen and (min-width: 641px) {
  .business-list__img {
    width: 50%;
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .business-list__img {
    width: 41%;
  }
}
@media screen and (max-width: 640px) {
  .business-list__img {
    margin-bottom: 15px;
  }
}
.business-list__img img {
  border-radius: 8px;
}

#page-disclaimer, #page-news, #page-column, #page-newsdetail, #page-columndetail {
  background: #f8f8f2;
}

.sec-disclaimer {
  padding: clamp(20px, 2.8vw, 46px) 0 clamp(50px, 9vw, 141px);
}
.sec-disclaimer .inner {
  max-width: 1042px;
}
.sec-disclaimer .c-ttl__01--line {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 600;
  font-size: 2.1rem;
  padding-bottom: 15px;
  margin: clamp(30px, 6.7vw, 106px) 0 clamp(15px, 2vw, 33px);
}
@media screen and (max-width: 834px) {
  .sec-disclaimer .c-ttl__01--line {
    font-size: 1.6rem;
    padding-bottom: 7px;
  }
}

.disclaimer-list {
  max-width: 1000px;
  margin: 0 auto;
}
.disclaimer-list__item {
  position: relative;
  padding-left: 16px;
}
@media screen and (max-width: 834px) {
  .disclaimer-list__item {
    padding-left: 12px;
  }
}
.disclaimer-list__item:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #373737;
  border-radius: 50%;
  top: 7px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .disclaimer-list__item:before {
    width: 7px;
    height: 7px;
  }
}

.sec-fund {
  padding: clamp(5rem, 8vw, 12.9rem) 0 0;
}

.fund-block__list {
  gap: 10px;
  margin-bottom: 75px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .fund-block__list {
    gap: 5px;
    margin-bottom: 35px;
  }
}
.fund-block__list-item {
  display: inline-block;
  background: #5b7c66;
  border-radius: 4px;
  padding: 12px 26px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .fund-block__list-item {
    padding: 8px 10px;
    letter-spacing: 0;
    font-size: 1.5rem;
  }
}
.fund-block__desc, .fund-block .c-txt__01 {
  margin-bottom: 90px;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .fund-block__desc, .fund-block .c-txt__01 {
    text-align: left;
    margin-bottom: 35px;
  }
}
.fund-block__img {
  max-width: 1158px;
  margin: 0 auto 100px;
}
@media screen and (max-width: 834px) {
  .fund-block__img {
    overflow-x: auto;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .fund-block__img picture {
    width: 767px;
    display: block;
  }
}
.fund-block__img--custom {
  max-width: 100%;
  width: auto;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .fund-block__img--custom {
    margin-bottom: 0;
    margin-top: 3em;
  }
}
.fund-block__img--custom picture {
  width: 100%;
}
.fund-block__img img {
  width: 100%;
}

.fund-box {
  padding: 50px 59px 62px;
  background: #f8f8f2;
  margin: 0 12px;
}
@media screen and (max-width: 1200px) {
  .fund-box {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 834px) {
  .fund-box {
    padding: 35px 20px;
  }
  .fund-box--custom {
    margin: 0;
    padding: 0;
    background: none;
  }
}
.fund-box .c-ttl__01 {
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .fund-box .c-ttl__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .fund-box__img {
    overflow-x: auto;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .fund-box__img picture {
    width: 767px;
    display: block;
  }
}
.fund-box__img img {
  width: 100%;
}

.fund-slider {
  padding: 139px 0 160px;
}
@media screen and (max-width: 834px) {
  .fund-slider {
    padding: 60px 0;
  }
}
.fund-slider__item {
  padding: 0 10px;
  width: 440px;
}
@media screen and (max-width: 1200px) {
  .fund-slider__item {
    width: 360px;
  }
}
@media screen and (max-width: 834px) {
  .fund-slider__item {
    padding: 0 5px;
    width: 240px;
  }
}
.fund-slider__thumb {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.fund-slider__thumb::before {
  content: "";
  float: left;
  padding-top: 76.1904761905%;
}
.fund-slider__thumb::after {
  content: "";
  display: table;
  clear: both;
}
.fund-slider__thumb > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.fund-slider__thumb > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fund-hotel {
  padding: 109px 0 159px;
  background-color: rgb(248, 248, 242);
}
@media screen and (max-width: 834px) {
  .fund-hotel {
    padding: 50px 0;
  }
}
.fund-hotel .c-ttl__01 {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .fund-hotel .c-ttl__01 {
    margin-bottom: 25px;
  }
}
.fund-hotel__item:not(:last-child) {
  margin-bottom: 87px;
}
@media screen and (max-width: 834px) {
  .fund-hotel__item:not(:last-child) {
    margin-bottom: 35px;
  }
}
.fund-hotel__box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .fund-hotel__box {
    flex-wrap: wrap;
  }
}
.fund-hotel__box-ttl {
  width: 240px;
  line-height: 1.9;
  background: #5b7c66;
  padding: 20px 10px;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 2rem;
}
@media screen and (max-width: 1200px) {
  .fund-hotel__box-ttl {
    width: 160px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .fund-hotel__box-ttl {
    width: 100%;
    line-height: 1.3;
    padding: 15px 10px;
    height: 70px;
  }
}
.fund-hotel__box-list {
  width: calc(100% - 240px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 0;
  padding: 30px 19px;
}
@media screen and (max-width: 1440px) {
  .fund-hotel__box-list {
    padding: 30px 10px;
  }
}
@media screen and (max-width: 1200px) {
  .fund-hotel__box-list {
    width: calc(100% - 160px);
  }
}
@media screen and (max-width: 834px) {
  .fund-hotel__box-list {
    width: 100%;
    gap: 10px 0;
  }
}
.fund-hotel__box-list-item {
  width: 25%;
  padding: 0 10px;
}
@media screen and (max-width: 1440px) {
  .fund-hotel__box-list-item {
    padding: 0 5px;
  }
}
@media screen and (max-width: 1200px) {
  .fund-hotel__box-list-item {
    width: 33.33%;
  }
}
@media screen and (max-width: 640px) {
  .fund-hotel__box-list-item {
    width: 50%;
    padding: 0 5px;
  }
}
.fund-hotel__box-list-item span {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f8f8f2;
  padding: 14px 5px;
  border-radius: 5px;
  color: #373737;
  font-size: 1.6rem;
  height: 100%;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .fund-hotel__box-list-item span {
    font-size: 1.4rem;
  }
}

.sec-news {
  padding: clamp(2rem, 6.5vw, 11rem) 0 clamp(5rem, 9.5vw, 15.8rem);
}

@media screen and (max-width: 834px) {
  .news-scroll {
    margin-left: calc((100% - 100vw) / 2);
    margin-right: calc((100% - 100vw) / 2);
    padding: 0 6.66%;
    overflow-x: auto;
    margin-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
  }
}

.news-tab {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 33px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .news-tab {
    margin-bottom: 0;
    white-space: nowrap;
    width: max-content;
    gap: 24px;
    border-bottom: 0;
  }
}
.news-tab a {
  position: relative;
  display: inline-block;
  color: #9b9b9b;
  font-size: 1.5rem;
  padding: 5px 7px 8px;
}
@media screen and (max-width: 834px) {
  .news-tab a {
    font-size: 1.2rem;
    padding: 9px 7px;
  }
}
.news-tab a:before {
  content: "";
  position: absolute;
  background: black;
  width: 0;
  height: 3px;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease;
}
@media (any-hover: hover) {
  .news-tab a:hover {
    opacity: 1;
    color: black;
  }
  .news-tab a:hover:before {
    width: 100%;
  }
}
.news-tab a.active {
  color: black;
}
.news-tab a.active:before {
  width: 100%;
}

.news-list {
  margin-bottom: clamp(30px, 4.7vw, 78px);
}
.news-list__item {
  border-bottom: 1px solid #e6e6e6;
}
.news-list__item a {
  display: block;
  padding: 30px 20px;
}
@media screen and (min-width: 641px) {
  .news-list__item a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 834px) {
  .news-list__item a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    gap: 16px;
  }
}
.news-list__img {
  position: relative;
}
@media screen and (min-width: 641px) {
  .news-list__img {
    width: 27%;
  }
}
@media screen and (max-width: 640px) {
  .news-list__img {
    margin-bottom: 0;
    position: relative;
    width: 34.43%;
  }
}
.news-list__img:before {
  content: "";
  display: block;
  padding-bottom: 74.5454545455%;
}
.news-list__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
  object-fit: cover;
}
@media screen and (max-width: 640px) {
  .news-list__ct {
    padding: 15px 0;
  }
}
.news-list__ct-ttl {
  font-size: 1.7rem;
  line-height: 1.7647058824;
  margin-bottom: 10px;
}
@media screen and (max-width: 640px) {
  .news-list__ct-ttl {
    line-height: 1.6;
    margin-bottom: 2px;
    font-size: 1.2rem;
  }
}
.news-list__ct-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 14px;
  font-size: 1.4rem;
}
@media screen and (max-width: 640px) {
  .news-list__ct-info {
    gap: 5px 5px;
  }
}
.news-list__ct-info span {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #dddddd;
  font-weight: 500;
  padding: 6px 15px 4px;
}
@media screen and (max-width: 640px) {
  .news-list__ct-info span {
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 6px;
  }
}
.news-list__ct-info time {
  font-family: "Marcellus", serif;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 640px) {
  .news-list__ct-info time {
    font-size: 1rem;
  }
}
@media screen and (min-width: 641px) {
  .news-list--s2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    max-width: 840px;
    margin-top: -13px;
  }
}
@media screen and (max-width: 834px) {
  .news-list--s2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
  }
}
@media screen and (min-width: 641px) {
  .news-list--s2 .news-list__item {
    margin-bottom: 0;
  }
}
.news-list--s2 .news-list__item a {
  display: block;
}
@media screen and (max-width: 834px) {
  .news-list--s2 .news-list__item {
    width: 50%;
    padding: 0 7px;
  }
}
.news-list--s2 .news-list__img {
  width: 100%;
  margin-bottom: 4px;
}
.news-list--s2 .news-list__img:before {
  padding-bottom: 71.8918918919%;
}
.news-list--s2 .news-list__ct {
  width: 100%;
}
.news-list--s2 .news-list__ct-info {
  gap: 9px;
  margin-top: -3px;
}
@media screen and (max-width: 834px) {
  .news-list--s2 .news-list__ct-info {
    gap: 5px;
  }
  .news-list--s2 .news-list__ct-info span {
    padding: 0.3em 0.5em 0.1em;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 834px) {
  .news-list--s2 .news-list__ct-ttl {
    margin: 1em 0;
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.sec-newsdetail {
  padding: clamp(20px, 4vw, 59px) 0 clamp(50px, 9.8vw, 160px);
}
@media screen and (min-width: 991px) {
  .sec-newsdetail .common-wrap__sidebar {
    padding-top: 51px;
  }
}

.detail-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 14px;
  font-size: 1.4rem;
  margin-bottom: 5px;
}
@media screen and (max-width: 834px) {
  .detail-info {
    gap: 6px;
  }
}
.detail-info span {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #dddddd;
  font-weight: 500;
  padding: 0.3em 0.5em 0.1em;
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .detail-info span {
    font-size: 1.2rem;
  }
}
.detail-info time {
  font-family: "Marcellus", serif;
  letter-spacing: 0.06em;
}
.detail-info--02 span {
  color: #fff;
  background: #5b7c67;
  border-color: #5b7c67;
  padding: 0.2em 15px;
  font-size: 1.3rem;
}
@media screen and (max-width: 834px) {
  .detail-info--02 span {
    font-size: 1.2rem;
  }
}

.detail-ttl {
  border-bottom: 1px solid #e6e6e6;
  font-family: "Shippori Mincho B1", serif;
  font-size: clamp(1.8rem, 3.1vw, 2.6rem);
  line-height: 1.75;
  padding-bottom: 20px;
  margin-bottom: clamp(1.5em, 4vw, 3.05em);
}
@media screen and (max-width: 834px) {
  .detail-ttl {
    padding-bottom: 1em;
    line-height: 1.5;
  }
}

.detail-img {
  max-width: 640px;
  margin: 0 auto clamp(20px, 3.2vw, 54px);
}
.detail-img img {
  width: 100%;
  border-radius: 8px;
}

.detail-content {
  font-size: 1.5rem;
  line-height: 1.8666666667;
  margin-bottom: clamp(30px, 4.5vw, 71px);
}
@media screen and (max-width: 834px) {
  .detail-content {
    font-size: 1.2rem;
  }
}
.detail-content p:not(:last-child) {
  margin-bottom: 44px;
}
@media screen and (max-width: 834px) {
  .detail-content p:not(:last-child) {
    margin-bottom: 20px;
  }
}
.detail-content h1 {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 1.9rem;
  padding: 12px 8px 8px;
  margin-bottom: 28px;
}
.detail-content h2 {
  border-bottom: 1px solid #5b7c66;
  font-size: 1.9rem;
  padding: 0 0 6px 8px;
  margin-bottom: 29px;
}
.detail-content h3 {
  border-left: 1px solid #5b7c66;
  font-size: 1.9rem;
  line-height: 1.5;
  padding: 1px 0 0 13px;
  margin: 51px 0 22px;
}
.detail-content h4 {
  position: relative;
  font-size: 1.7rem;
  padding-left: 52px;
  margin-bottom: 13px;
}
.detail-content h4:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 1px;
  background: #e6e6e6;
  left: 0;
  top: 14px;
}
.detail-content h5 {
  position: relative;
  font-size: 1.7rem;
  padding-left: 22px;
  margin-bottom: 13px;
}
.detail-content h5:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #5b7c66;
  border-radius: 50%;
  left: 0;
  top: 8px;
}
.detail-content h6 {
  font-size: 1.7rem;
  margin-bottom: 14px;
}
.detail-content ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.detail-content ol li {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: decimal;
}
.detail-content ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.detail-content ul li {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: disc;
}
.detail-content del, .detail-content ins {
  text-decoration: line-through;
}
.detail-content #toc_container {
  width: 100%;
  background: #f3f3ec;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 19px;
  margin: 50px 0 clamp(30px, 3.7vw, 60px);
}
.detail-content #toc_container .toc_title {
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  font-family: "Marcellus", serif;
  font-size: 2.4rem;
  padding-bottom: 4px;
  margin-bottom: 23px;
}
.detail-content #toc_container .toc_list {
  font-size: 1.5rem;
  margin: 0;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .detail-content #toc_container .toc_list {
    padding-left: 0;
    font-size: 1.4rem;
  }
}
.detail-content #toc_container .toc_list ul {
  margin: 0;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .detail-content #toc_container .toc_list ul {
    padding-left: 10px;
  }
}
.detail-content #toc_container .toc_list li {
  list-style: none;
  margin-bottom: 10px;
}
.detail-content #toc_container .toc_list a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
}
.detail-content #toc_container .toc_number {
  color: #5b7c66;
  font-family: "Marcellus", serif;
  word-break: keep-all;
  margin-right: 8px;
}

.sec-irpolicy {
  padding: clamp(2rem, 6vw, 5rem) 0 clamp(6rem, 10vw, 15.3rem);
}

.irpolicy-list__item:not(:last-child) {
  margin-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .irpolicy-list__item:not(:last-child) {
    margin-bottom: 35px;
  }
}
.irpolicy-list__heading {
  color: #373737;
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
  padding-bottom: 16px;
  line-height: 1.3;
  margin-bottom: 26px;
  border-bottom: 1px solid #E6E6E6;
  font-size: 2.2rem;
}
@media screen and (max-width: 834px) {
  .irpolicy-list__heading {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 2rem;
  }
}

#page-contact {
  background: #F8F8F2;
}

.sec-contact-page {
  padding: clamp(20px, 6.5vw, 147px) 0 clamp(50px, 6.5vw, 147px);
}

.contact-note {
  text-align: center;
  line-height: 2.13333;
  margin-bottom: 110px;
}
@media screen and (max-width: 834px) {
  .contact-note {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 640px) {
  .contact-note p {
    text-align: left;
  }
}
.contact-note__ttl {
  display: block;
  font-size: 2.2rem;
  font-family: "Shippori Mincho B1", serif;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 72px;
}
@media screen and (max-width: 834px) {
  .contact-note__ttl {
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 40px;
  }
}
.contact-note__steps {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 58px;
}
@media screen and (max-width: 834px) {
  .contact-note__steps {
    margin-bottom: 38px;
  }
}
.contact-note__steps li {
  position: relative;
  padding: 61px 77px 0;
  color: #aaa;
  font-size: 1.7rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .contact-note__steps li {
    padding: 50px 30px 0;
    font-size: 1.5rem;
  }
}
.contact-note__steps li:last-child:after {
  display: none;
}
.contact-note__steps li:before {
  content: "";
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 1px solid #aaa;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .contact-note__steps li:before {
    width: 40px;
    height: 40px;
  }
}
.contact-note__steps li:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 27px;
  background: #aaa;
}
@media screen and (max-width: 834px) {
  .contact-note__steps li:after {
    top: 22px;
  }
}
.contact-note__steps li span {
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  font-family: "Marcellus", serif;
  font-size: 2.4rem;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .contact-note__steps li span {
    font-size: 1.8rem;
  }
}
.contact-note__steps li.active {
  color: #373737;
}
.contact-note__steps li.active span {
  color: white;
}
.contact-note__steps li.active:before {
  background: #5B7C66;
  border: 1px solid #5B7C66;
}

.contact-form {
  font-size: 1.6rem;
  color: #373737;
  max-width: 1100px;
  margin: 0 auto 90px;
}
@media screen and (max-width: 834px) {
  .contact-form {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
}
.contact-form__note {
  text-align: right;
  font-size: 1.5rem;
  margin-bottom: 14px;
}
.contact-form sub {
  color: #E55A6E;
}
.contact-form .form-control {
  background: white;
  border-radius: 4px;
  border: none;
  font-size: 1.6rem;
  margin-bottom: 12px;
  color: #373737;
}
@media screen and (min-width: 835px) {
  .contact-form .form-control {
    height: 54px;
    padding: 5px 20px;
  }
}
.contact-form .form-control:last-child {
  margin-bottom: 0;
}
.contact-form .form-control::-webkit-input-placeholder {
  color: #C1C1C1;
}
.contact-form .form-control:-moz-placeholder {
  color: #C1C1C1;
}
.contact-form .form-control::-moz-placeholder {
  color: #C1C1C1;
}
.contact-form .form-control:-ms-input-placeholder {
  color: #C1C1C1;
}
.contact-form select:required:invalid {
  color: #C1C1C1;
}
.contact-form select.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #fff url(../img/common/arrow-dow.png) no-repeat 95%;
  background-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-form select.form-control option {
  color: black;
}
.contact-form textarea.form-control {
  height: 315px;
  padding-top: 7px;
}
@media screen and (max-width: 834px) {
  .contact-form textarea.form-control {
    height: 200px;
  }
}
.contact-form__item {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .contact-form__item {
    margin-bottom: 30px;
  }
}
.contact-form__item dt {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .contact-form__item dt {
    margin-bottom: 10px;
  }
}
.contact-form__item.h-adr .wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 25px;
}
.contact-form__item.h-adr .wpcf7-form-control-wrap:last-child {
  margin-bottom: 0;
}
.contact-form__tel {
  max-width: 290px;
}
@media screen and (max-width: 640px) {
  .contact-form__tel {
    max-width: 100%;
  }
}
.contact-form__select {
  max-width: 290px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .contact-form__select {
    max-width: 100%;
  }
}
.contact-form__column {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 976px;
}
.contact-form__column-item {
  width: calc(50% - 10px);
}
@media screen and (max-width: 834px) {
  .contact-form__column-item {
    width: 100%;
    margin-bottom: 15px;
  }
  .contact-form__column-item:last-child {
    margin-bottom: 0;
  }
}
.contact-form__code {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 23px;
}
.contact-form__code-ttl {
  font-size: 1.8rem;
  display: block;
  padding: 18px 0 0 0;
}
.contact-form__code-input {
  width: 150px;
  margin: 0 25px 0 15px;
}
@media screen and (max-width: 374px) {
  .contact-form__code-input {
    width: 115px;
    margin: 0 15px;
  }
}
.contact-form__code-btn {
  display: block;
  border: none;
  outline: none;
  color: white;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 40px;
  background: #5b7c66;
  cursor: pointer;
  line-height: 1;
  margin: 15px 0 0 0;
  height: 28px;
  padding: 2px 21px 0;
}
@media (any-hover: hover) {
  .contact-form__code-btn:hover {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  .contact-form__code-btn:hover {
    opacity: 0.7;
  }
}
.contact-form__check {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  padding: 90px 0 60px;
}
@media screen and (max-width: 834px) {
  .contact-form__check {
    padding: 30px 0;
  }
}
.contact-form__check:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50000px;
  background: #F3F3EC;
  border-top: 1px solid #E1DCCD;
  border-bottom: 1px solid #E1DCCD;
}
.contact-form__check-input {
  position: relative;
  z-index: 2;
}
.contact-form__check-input input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.contact-form__check-input input[type=checkbox] + span:after {
  background-color: transparent;
  top: 16px;
  left: 8px;
  width: 10px;
  height: 4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  -o-border-image: none;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}
@media screen and (max-width: 834px) {
  .contact-form__check-input input[type=checkbox] + span:after {
    top: 17px;
  }
}
.contact-form__check-input input[type=checkbox]:checked + span {
  color: #373737;
}
.contact-form__check-input input[type=checkbox]:checked + span:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}
.contact-form__check-input input[type=checkbox]:checked + span:before {
  animation: borderscale 200ms ease-in;
  background: #373737;
}
.contact-form__check-input input[type=radio] + span:after, .contact-form__check-input input[type=radio] + span:before {
  border-radius: 50%;
}
.contact-form__check-input input[type=radio]:checked + span:before {
  animation: borderscale 300ms ease-in;
}
.contact-form__check-input input[type=radio]:checked + span:after {
  transform: scale(1);
}
.contact-form__check-input .wpcf7-list-item-label {
  display: block;
  position: relative;
  padding: 10px 0 10px 47px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .contact-form__check-input .wpcf7-list-item-label {
    padding: 10px 0 10px 36px;
  }
}
.contact-form__check-input .wpcf7-list-item-label:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 26px;
  height: 26px;
  background: #F9F9F9;
  border: 1px solid #C1C1C1;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 3px;
}
@media screen and (max-width: 834px) {
  .contact-form__check-input .wpcf7-list-item-label:before {
    width: 24px;
    height: 24px;
    top: 8px;
  }
}
.contact-form__check-input .wpcf7-list-item-label:after {
  content: "";
  background-color: #333;
  position: absolute;
  top: 16px;
  left: 6px;
  width: 14px;
  height: 14px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}
.contact-form__check .js-bar {
  padding: 0 7%;
}
@media screen and (max-width: 834px) {
  .contact-form__check .js-bar {
    padding: 0 20px;
  }
}
.contact-form__check .slimScrollDiv, .contact-form__check .js-bar {
  height: 580px !important;
}
@media screen and (max-width: 834px) {
  .contact-form__check .slimScrollDiv, .contact-form__check .js-bar {
    height: 250px !important;
  }
}
.contact-form__check .slimScrollBar {
  width: 10px !important;
  opacity: 1 !important;
  background: #e6e6e6 !important;
  right: 2.7% !important;
  display: block !important;
}
.contact-form__check-input {
  font-size: 1.8rem;
  color: #C1C1C1;
}
@media screen and (max-width: 834px) {
  .contact-form__check-input {
    font-size: 1.6rem;
  }
}
.contact-form__priverty {
  position: relative;
  background: white;
  z-index: 1;
  font-size: 1.5rem;
  line-height: 2.0666666667;
  padding: 54px 0 0;
  margin-bottom: 47px;
}
@media screen and (max-width: 834px) {
  .contact-form__priverty {
    padding-top: 25px;
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 30px;
  }
}
.contact-form__priverty-heading {
  display: block;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 46px;
}
@media screen and (max-width: 834px) {
  .contact-form__priverty-heading {
    margin-bottom: 30px;
  }
}
.contact-form__priverty-heading span {
  display: block;
}
.contact-form__priverty-heading .c-ft--en {
  font-size: clamp(3rem, 2.9vw, 5rem);
  letter-spacing: 1px;
  margin-bottom: 8px;
  color: #E6E6E6;
}
.contact-form__priverty-heading .c-ft--base {
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .contact-form__priverty-heading .c-ft--base {
    font-size: 1.8rem;
  }
}
.contact-form__priverty-ttl {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #5b7c66;
  border-bottom: 1px solid #5b7c66;
  padding: 0 0 7px;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .contact-form__priverty-ttl {
    font-size: 1.6rem;
    padding: 0 0 5px;
    margin-bottom: 10px;
  }
}
.contact-form__priverty p {
  margin-bottom: 31px;
}
@media screen and (max-width: 834px) {
  .contact-form__priverty p {
    margin-bottom: 15px;
  }
}
.contact-form__priverty ul {
  counter-reset: section;
  margin-bottom: 31px;
}
.contact-form__priverty ul li {
  position: relative;
  padding: 0 0 0 18px;
  margin-bottom: 10px;
}
.contact-form__priverty ul li:before {
  counter-increment: section;
  content: counters(section, ".") ".";
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}
.contact-form__priverty ul li .contact-form__priverty-note {
  padding-left: 0;
}
.contact-form__priverty ul.note-02 li {
  margin-bottom: 25px;
}
.contact-form__priverty ul.note-02 li:last-child {
  margin-bottom: 0;
}
.contact-form__priverty .counter-first li {
  margin-bottom: 0;
}
.contact-form__priverty .counter-first li:before {
  font-weight: 400;
}
.contact-form__priverty a {
  color: #373737;
}
.contact-form__priverty-note {
  padding: 0 0 0 18px;
  margin-bottom: 31px;
}
.contact-form__priverty-note:last-child {
  margin-bottom: 0;
}
.contact-form__sub {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: clamp(4.5rem, 4.9vw, 8.3rem) 0 clamp(5rem, 8.3vw, 14rem);
}
.contact-form__sub .c-btn__02 {
  margin: 0 25px 15px;
}
.contact-form__sub--thanks {
  padding-top: 0;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mw_wp_form_confirm .contact-form {
  margin-bottom: 38px;
}
.mw_wp_form_confirm .contact-form__item {
  border-bottom: 1px solid #E6E6E6;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: 36px 0;
}
@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .contact-form__item {
    padding: 20px 0;
  }
}
.mw_wp_form_confirm .contact-form__item:nth-child(2) {
  padding-top: 0;
}
.mw_wp_form_confirm .contact-form__item dt {
  width: 300px;
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .contact-form__item dt {
    width: 100%;
    margin-bottom: 10px;
  }
}
.mw_wp_form_confirm .contact-form__item dd {
  width: calc(100% - 300px);
}
@media screen and (max-width: 834px) {
  .mw_wp_form_confirm .contact-form__item dd {
    width: 100%;
  }
}
.mw_wp_form_confirm .contact-form__item .contact-form__code-btn {
  display: none;
}
.mw_wp_form_confirm .contact-form__item .contact-form__code-ttl {
  padding-top: 0;
}
.mw_wp_form_confirm .contact-form__check {
  display: none;
}

.wpcf7-not-valid-tip {
  font-size: 1.4rem;
}

.sec-facility {
  padding: clamp(40px, 7.7vw, 129px) 0 clamp(50px, 12.1vw, 203px);
}

@media screen and (min-width: 835px) {
  .facility-list__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.facility-list__item:not(:last-child) {
  margin-bottom: clamp(50px, 6.5vw, 109px);
}
.facility-list .c-ttl__01 {
  width: 100%;
}
@media screen and (min-width: 835px) {
  .facility-list .c-ttl__01 {
    margin-bottom: 47px;
  }
}
@media screen and (max-width: 640px) {
  .facility-list .c-ttl__01 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .facility-list .c-ttl__01 {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 835px) {
  .facility-list__slider {
    width: 41.6%;
    margin-top: 4px;
  }
}
@media screen and (max-width: 834px) {
  .facility-list__slider {
    margin-bottom: 45px;
  }
}
.facility-list__slider .slick-dots {
  bottom: -42px;
}
@media screen and (max-width: 834px) {
  .facility-list__slider .slick-dots {
    bottom: -20px;
  }
}
.facility-list__slider .slick-dots li button {
  background: #cccccc;
  border-radius: 50%;
  padding: 6px;
}
@media screen and (max-width: 834px) {
  .facility-list__slider .slick-dots li button {
    padding: 4px;
  }
}
.facility-list__slider .slick-dots li button:before {
  display: none;
}
.facility-list__slider .slick-dots li.slick-active button {
  background: #5b7c67;
}
.facility-list__slider-item {
  position: relative;
}
.facility-list__slider-item:before {
  content: "";
  display: block;
  padding-bottom: 80%;
}
.facility-list__slider-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
}
@media screen and (min-width: 835px) {
  .facility-list__ct {
    width: 52.6%;
  }
}
.facility-list__ct-ttl {
  font-family: "Shippori Mincho B1", serif;
  font-size: clamp(2rem, 1.7vw, 2.4rem);
  margin-bottom: 26px;
}
@media screen and (max-width: 834px) {
  .facility-list__ct-ttl {
    margin-bottom: 10px;
  }
}
.facility-list__ct .c-txt__01 {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .facility-list__ct .c-txt__01 {
    margin-bottom: 15px;
  }
}
.facility-list__ct-btn {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 160px;
  height: 40px;
  background: #5b7c67;
  color: white;
  border-radius: 6px;
  font-size: 1.5rem;
  padding: 5px 10px 5px 35px;
  margin-bottom: 24px;
  transition: 0.3s ease;
}
@media screen and (max-width: 834px) {
  .facility-list__ct-btn {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
}
.facility-list__ct-btn:before {
  content: "\e89e";
  position: absolute;
  font-family: "Material Symbols Outlined";
  font-size: 1.8rem;
  top: 8px;
  left: 18px;
}
@media (any-hover: hover) {
  .facility-list__ct-btn:hover {
    opacity: 0.7;
  }
}
.facility-list__ct-table {
  width: 100%;
  font-size: 1.4rem;
}
@media screen and (min-width: 835px) {
  .facility-list__ct-table {
    margin-top: -17px;
  }
}
@media screen and (max-width: 374px) {
  .facility-list__ct-table {
    font-size: 1.2rem;
  }
}
.facility-list__ct-table th, .facility-list__ct-table td {
  text-align: left;
  padding: 20px;
}
@media screen and (max-width: 640px) {
  .facility-list__ct-table th, .facility-list__ct-table td {
    padding: 18px 0 15px;
  }
}
.facility-list__ct-table th {
  position: relative;
  width: 110px;
  font-weight: 400;
}
@media screen and (max-width: 640px) {
  .facility-list__ct-table th {
    width: 80px;
  }
}
.facility-list__ct-table th:before {
  content: "";
  position: absolute;
  width: calc(100% - 10px);
  height: 1px;
  background: #5b7c67;
  bottom: -1px;
  left: 0;
}
.facility-list__ct-table td {
  width: calc(100% - 110px);
  border-bottom: 1px solid #e1dccd;
}
@media screen and (max-width: 640px) {
  .facility-list__ct-table td {
    width: calc(100% - 80px);
  }
}

.sec-recruit {
  background: #fff;
  padding: clamp(30px, 7.1vw, 12.1em) 0 clamp(7em, 8.5vw, 14.2em);
}

.recruit-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 clamp(40px, 4.694vw, 80px);
}
@media screen and (max-width: 834px) {
  .recruit-box {
    display: block;
  }
}
.recruit-box__l {
  width: calc(47.5% - clamp(40px, 4.694vw, 80px));
}
@media screen and (max-width: 834px) {
  .recruit-box__l {
    width: 100%;
    margin-bottom: 35px;
  }
}
.recruit-box__r {
  width: 52.5%;
}
@media screen and (max-width: 834px) {
  .recruit-box__r {
    width: 100%;
  }
}
.recruit-box__img {
  position: relative;
}
.recruit-box__img::before {
  content: "";
  float: left;
  padding-top: 88.8888888889%;
}
.recruit-box__img::after {
  content: "";
  display: table;
  clear: both;
}
.recruit-box__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.recruit-box__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .recruit-box__img {
    width: 80%;
    margin: 0 auto;
  }
}

.recruit-ttl {
  font-size: clamp(2.1rem, 3vw, 3.6rem);
  line-height: 1.6666666667;
  font-weight: 500;
  margin-bottom: 1.22em;
  font-family: "Shippori Mincho B1", serif;
}

.recruit-des p {
  margin-bottom: 2.41em;
}
@media screen and (max-width: 834px) {
  .recruit-des p {
    margin-bottom: 1.5em;
  }
}
.recruit-des p:last-child {
  margin-bottom: 0;
}

.sec-jobs {
  padding: 109px 0;
  background: #f8f8f2;
  padding: clamp(10em, 6.39586vw, 10.9em) 0 clamp(10em, 9.2124vw, 15.7em);
}
@media screen and (max-width: 834px) {
  .sec-jobs {
    padding: 50px 0;
  }
}

.jobs-ttl {
  text-align: center;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  font-size: clamp(2rem, 2.7vw, 3.6rem);
  letter-spacing: 0.03em;
  margin-bottom: clamp(30px, 4.4vw, 80px);
}

.jobs-tabs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 20px;
  margin-bottom: clamp(50px, 5.222vw, 89px);
}
@media screen and (max-width: 640px) {
  .jobs-tabs {
    gap: 0 10px;
  }
}
.jobs-tabs__item {
  max-width: calc(50% - 10px);
  color: #9b9b9b;
}
@media screen and (max-width: 640px) {
  .jobs-tabs__item {
    max-width: calc(50% - 5px);
  }
}
.jobs-tabs__item span {
  width: 400px;
  height: 70px;
  padding: 20px 20px;
  border-radius: 8px;
  max-width: 100%;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e1dccd;
  cursor: pointer;
  background: #fff;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.jobs-tabs__item span:hover {
  opacity: 0.75;
  background: #5b7c67;
  color: #fff;
}
@media screen and (max-width: 834px) {
  .jobs-tabs__item span {
    padding: 10px 10px;
    height: 50px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .jobs-tabs__item span {
    font-size: 1.25rem;
  }
}
.jobs-tabs__item.active span {
  background: #5b7c67;
  color: #fff;
}

@media screen and (max-width: 834px) {
  .jobs-content__ttl {
    font-size: 1.8rem;
  }
}
.jobs-content__ttlsub {
  font-size: clamp(2rem, 1.6vw, 2.6rem);
  line-height: 1.5;
  font-weight: 500;
  color: #5a7b66;
  margin-bottom: 50px;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .jobs-content__ttlsub {
    font-size: 1.8rem;
  }
}
.jobs-content__sub-box {
  margin-bottom: clamp(45px, 5.1636vw, 88px);
}
.jobs-content__sub-box:last-child {
  margin-bottom: 0;
}
.jobs-content__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 60px 60px;
  margin-bottom: 49px;
}
@media screen and (max-width: 1440px) {
  .jobs-content__list {
    gap: 3.52vw 3.52vw;
  }
}
@media screen and (max-width: 834px) {
  .jobs-content__list {
    gap: 3.52vw 0;
    justify-content: space-between;
  }
}
.jobs-content__list:last-child {
  margin-bottom: 0;
}
.jobs-content__list-item {
  width: calc(33.33% - 40px);
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 1440px) {
  .jobs-content__list-item {
    width: calc(33.33% - 2.3471vw);
  }
}
@media screen and (max-width: 834px) {
  .jobs-content__list-item {
    width: calc(50% - 7px);
  }
}
.jobs-content__list-item .c-btn__01 .txt-lg {
  color: #373737;
}
@media screen and (max-width: 834px) {
  .jobs-content__list-item .c-btn__01 .txt-lg {
    font-size: 1.2rem;
  }
}
@media (any-hover: hover) {
  .jobs-content__list-item .c-btn__01:hover .txt-lg {
    color: #5b7c66;
  }
}
@media (hover: hover) {
  .jobs-content__list-item .c-btn__01:hover .txt-lg {
    color: #5b7c66;
  }
}
.jobs-content__list a {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 0 30px;
}
@media screen and (max-width: 834px) {
  .jobs-content__list a {
    padding-bottom: 15px;
  }
}
.jobs-content__list a:hover img {
  transform: scale(1.1);
}
.jobs-content__list a .c-btn__01 {
  display: block;
  position: relative;
}
@media screen and (max-width: 834px) {
  .jobs-content__list a .c-btn__01 {
    padding-right: 30px;
  }
}
.jobs-content__list a .c-btn__01 .ico {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -15px;
}
.jobs-content__list-img {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.jobs-content__list-img::before {
  content: "";
  float: left;
  padding-top: 83.3333333333%;
}
.jobs-content__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.jobs-content__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.jobs-content__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.jobs-content__list-img img {
  transition: all 0.3s ease;
}

.recruit-detail {
  padding: clamp(50px, 7.0413vw, 120px) 0 clamp(50px, 9.212397vw, 157px);
  background: #f8f8f2;
}
.recruit-detail__cat {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 10px;
}
@media screen and (max-width: 640px) {
  .recruit-detail__cat {
    width: 100%;
    gap: 5px;
  }
}
.recruit-detail__cat-item {
  font-size: 1.3rem;
  line-height: 1.6153846154;
  font-weight: 500;
  padding: 0.35em 1.1428em;
  border-radius: 3px;
  border: 1px solid #dddddd;
}
@media screen and (max-width: 640px) {
  .recruit-detail__cat-item {
    font-size: 1.2rem;
    padding: 0.2em 0.8em;
  }
}
.recruit-detail__t {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 20px;
  margin-bottom: 4px;
}
@media screen and (max-width: 640px) {
  .recruit-detail__t {
    flex-wrap: wrap;
  }
}
.recruit-detail__time {
  font-size: 1.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .recruit-detail__time {
    padding: 5px 10px 2px;
    width: 100%;
    text-align: right;
  }
}
@media screen and (max-width: 640px) {
  .recruit-detail__time {
    padding-left: 0;
  }
}
.recruit-detail__time time {
  font-family: "Marcellus", serif;
  font-size: 1.6rem;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .recruit-detail__time time {
    font-size: 1.4rem;
  }
}
.recruit-detail__ttl {
  font-size: 2.6rem;
  line-height: 1.7692307692;
  font-family: "Shippori Mincho B1", serif;
  padding-bottom: 21px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .recruit-detail__ttl {
    font-size: 1.8rem;
    padding-bottom: 5px;
    margin-bottom: 25px;
  }
}
.recruit-detail__des {
  margin-bottom: 54px;
}
@media screen and (max-width: 640px) {
  .recruit-detail__des {
    margin-bottom: 30px;
  }
}
.recruit-detail__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-width: 640px) {
  .recruit-detail__dl {
    display: block;
  }
}
.recruit-detail__tb {
  border: 1px solid #e6e6e6;
  margin-bottom: 60px;
}
.recruit-detail__dt {
  width: clamp(170px, 14.67vw, 250px);
  background: #ececdc;
  padding: 23px 30px;
  border-right: 1px solid #e6e6e6;
}
@media screen and (max-width: 640px) {
  .recruit-detail__dt {
    width: 100%;
    padding: 15px 15px;
    border-right: 0;
  }
}
.recruit-detail__dd {
  width: calc(100% - clamp(170px, 14.67vw, 250px));
  background: #ffffff;
  padding: 23px 30px;
}
@media screen and (max-width: 640px) {
  .recruit-detail__dd {
    width: 100%;
    padding: 15px 15px;
  }
}
.recruit-detail__dd p {
  margin-bottom: 15px;
}
.recruit-detail__dd p:last-child {
  margin-bottom: 0;
}
.recruit-detail__dd-block {
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
}
.recruit-detail__dd-block::before {
  content: "◆";
  position: absolute;
  top: 0;
  left: 0;
}
.recruit-detail__dd-block:last-child {
  margin-bottom: 0;
}
.recruit-detail__dd ul {
  display: block;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .recruit-detail__dd ul {
    font-size: 1.1rem;
  }
}
.recruit-detail__dd ul li {
  display: flex;
}
.recruit-detail__dd ul li::before {
  content: "・";
}
.recruit-detail__dd a {
  text-decoration: underline;
}
@media screen and (min-width: 835px) {
  .recruit-detail__btn a {
    font-size: 1.7rem;
  }
}

#id-tabs01 .jobs-content__list--parents {
  display: none;
}

#business_development {
  overflow: hidden;
}

.sec-development {
  padding-top: clamp(5rem, 9vw, 13rem);
}
.sec-development .fund-slider {
  padding: 0;
}

.development-heading {
  margin-bottom: clamp(5rem, 10vw, 15rem);
}

.development-business {
  padding: clamp(5rem, 9vw, 14rem) 0;
}
@media screen and (max-width: 834px) {
  .development-business {
    padding-bottom: 0;
  }
}
.development-business__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1060px;
  margin: 0 auto;
  position: relative;
  gap: 60px;
}
@media screen and (max-width: 834px) {
  .development-business__flex {
    gap: 80px 50px;
  }
}
@media screen and (max-width: 767px) {
  .development-business__flex {
    gap: 220px 20px;
  }
}
.development-business__flex-item {
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 77px;
}
@media screen and (max-width: 1024px) {
  .development-business__flex-item {
    width: 300px;
    height: 300px;
    padding: 0 30px;
  }
}
@media screen and (max-width: 834px) {
  .development-business__flex-item {
    padding: 0 40px;
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 767px) {
  .development-business__flex-item {
    width: calc(50% - 10px);
    height: auto;
    border-radius: 10px;
    padding: 30px;
    height: auto;
    justify-content: unset;
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  .development-business__flex-item {
    padding: 20px;
  }
}
.development-business__flex-item:first-child {
  border: 1px solid #e6e6e6;
  text-align: center;
  z-index: 10;
  width: 460px;
  height: 460px;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 834px) {
  .development-business__flex-item:first-child {
    width: 320px;
    height: 320px;
    padding: 0 5px;
  }
}
@media screen and (max-width: 767px) {
  .development-business__flex-item:first-child {
    width: 250px;
    height: 250px;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  .development-business__flex-item:first-child {
    padding: 0 20px;
  }
}
.development-business__flex-item:nth-child(2) {
  background: rgba(130, 149, 97, 0.15);
}
.development-business__flex-item:nth-child(2) .c-ttl__01 .c-ft--jp {
  font-weight: 500;
}
.development-business__flex-item:nth-child(2) .c-ttl__01 .c-ft--en {
  color: #829561;
}
.development-business__flex-item:nth-child(3) {
  background: rgba(128, 155, 153, 0.15);
}
.development-business__flex-item:nth-child(3) .c-ttl__01 .c-ft--en {
  color: #809b99;
}
.development-business__flex-item:nth-child(4) {
  background: rgba(165, 147, 65, 0.15);
}
.development-business__flex-item:nth-child(4) .c-ttl__01 .c-ft--en {
  color: #a59341;
}
.development-business__flex-item:nth-child(5) {
  background: rgba(152, 163, 63, 0.15);
}
.development-business__flex-item:nth-child(5) .c-ttl__01 .c-ft--en {
  color: #98a33f;
}
.development-business__flex-item .c-ttl__01 {
  text-align: center;
}
@media screen and (max-width: 834px) {
  .development-business__flex-item .c-ttl__01 {
    margin-bottom: 10px;
  }
}
.development-business__flex-item .c-ttl__01 .c-ft--jp {
  display: block;
  font-weight: 500;
}
.development-business__flex-item .c-ttl__01 .c-ft--en {
  display: block;
  line-height: 1.2;
  margin: 0.2em 0 0.8em;
  font-size: clamp(1.4rem, 2vw, 2rem);
}
.development-business__flex-item .c-txt__01 {
  line-height: 1.6;
}
.development-business__flex-icon {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .development-business__flex-icon img {
    width: 50px;
  }
}
.development-business__flex-ttl {
  font-size: clamp(2.1rem, 3.5vw, 3.6rem);
  font-weight: 500;
  text-align: center;
}
.development-business__flex-ttl .c-ft--jp {
  display: block;
  margin-bottom: 6px;
}
.development-business__flex-ttl .c-ft--en {
  font-size: clamp(1.8rem, 2vw, 2.4rem);
  opacity: 0.2;
  display: block;
}

.development-project {
  background: #f8f8f2;
  padding: clamp(5rem, 7.5vw, 12.1rem) 0 clamp(5rem, 9.6vw, 16rem);
}
@media screen and (max-width: 834px) {
  .development-project__imgscroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .development-project__imgscroll img {
    max-width: 1200px;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .development-project__imgscroll img {
    max-width: 650px;
  }
}
.development-project .block-heading {
  margin-bottom: clamp(3rem, 5.5vw, 9.2rem);
}
@media screen and (min-width: 835px) {
  .development-project .block-heading__ttl {
    width: 43%;
  }
  .development-project .block-heading__ct {
    width: 56.7%;
    padding-top: 11px;
  }
}

.development-solution {
  max-width: 1000px;
  margin: 0 auto 112px;
  position: relative;
  background: white;
}
.development-solution__flex {
  white-space: nowrap;
  border-radius: 8px;
  padding: 37px 70px;
  padding: min(3.7em, 3.74vw) 7%;
  gap: 10px 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .development-solution__flex {
    padding: 15px 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
@media screen and (max-width: 834px) {
  .development-solution {
    margin-bottom: 90px;
  }
}
.development-solution:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 156px;
  height: 72px;
  background: #dddddd;
  clip-path: polygon(23% 52%, 23% 0, 78% 0, 78% 52%, 100% 52%, 50% 100%, 0 52%);
  transform: translateX(-50%);
  top: calc(100% + 20px);
  left: 50%;
}
@media screen and (max-width: 834px) {
  .development-solution:after {
    width: 100px;
    height: 50px;
  }
}
.development-solution__ttl {
  font-size: clamp(1.8rem, 2vw, 2.4rem);
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .development-solution__ttl {
    text-align: center;
    width: 100%;
  }
}
.development-solution .heading-common__link {
  margin-bottom: 0;
  font-family: "IBM Plex Sans JP", sans-serif;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .development-solution .heading-common__link-item {
    width: auto;
  }
}

#business_development {
  background: #f8f8f2;
}

.sec-outline {
  padding: clamp(5rem, 8vw, 11.4rem) 0 clamp(5rem, 10vw, 16.4rem);
}
@media screen and (max-width: 834px) {
  .sec-outline .anchor-link {
    margin-bottom: 50px;
  }
}
.sec-outline .c-ttl__04 {
  font-weight: 400;
}

.outline-info {
  margin-bottom: clamp(5rem, 9vw, 14.2rem);
}
.outline-info .c-ttl__04 {
  margin-bottom: 1.22em;
}
@media screen and (max-width: 834px) {
  .outline-info .c-ttl__04 {
    margin-bottom: 0.65em;
  }
}
.outline-info__date {
  text-align: right;
}
.outline-info__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.outline-info__item > * {
  padding-top: 29px;
  padding-bottom: 26px;
}
@media screen and (max-width: 834px) {
  .outline-info__item > * {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.outline-info__ttl {
  width: 185px;
  border-bottom: 1px solid #5B7C66;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .outline-info__ttl {
    width: 115px;
  }
}
.outline-info__ct {
  border-bottom: 1px solid #E1DCCD;
  width: calc(100% - 190px);
  padding-left: 177px;
}
@media screen and (max-width: 834px) {
  .outline-info__ct {
    padding-left: 30px;
  }
}
@media screen and (max-width: 640px) {
  .outline-info__ct {
    width: calc(100% - 115px);
    padding-left: 0;
  }
}

.outline-history {
  margin-bottom: clamp(5rem, 8.4vw, 13.5rem);
}
.outline-history .c-ttl__04 {
  margin-bottom: clamp(5rem, 7vw, 9.2rem);
}
@media screen and (max-width: 834px) {
  .outline-history .c-ttl__04 {
    margin-bottom: 1em;
  }
}
.outline-history__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (max-width: 640px) {
  .outline-history__item {
    padding-left: 30px;
  }
}
.outline-history__item:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #5B7C66;
  border-radius: 50%;
  top: 0;
  left: 242px;
}
@media screen and (max-width: 640px) {
  .outline-history__item:after {
    width: 15px;
    height: 15px;
    left: 0;
    top: 5px;
  }
}
.outline-history__item:not(:last-child):before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: calc(100% + 54px);
  background: #5B7C66;
  top: 1px;
  left: 251px;
}
@media screen and (max-width: 640px) {
  .outline-history__item:not(:last-child):before {
    left: 7px;
    height: calc(100% + 25px);
    top: 5px;
  }
}
.outline-history__item:not(:last-child) {
  margin-bottom: 55px;
}
@media screen and (max-width: 640px) {
  .outline-history__item:not(:last-child) {
    margin-bottom: 25px;
  }
}
.outline-history__ttl {
  color: #5B7C66;
  width: 154px;
  font-size: 2rem;
  line-height: 1.4;
  font-family: "Marcellus", serif;
}
@media screen and (min-width: 641px) {
  .outline-history__ttl {
    text-align: right;
  }
}
@media screen and (max-width: 834px) {
  .outline-history__ttl {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .outline-history__ttl {
    width: 100%;
  }
}
.outline-history__ttl .c-ft--base {
  font-size: 1.6rem;
  font-family: "IBM Plex Sans JP", sans-serif;
}
.outline-history__ttl .c-ft--base:not(:last-child) {
  margin-right: 10px;
}
.outline-history__ct {
  width: calc(100% - 348px);
}
@media screen and (max-width: 640px) {
  .outline-history__ct {
    width: 100%;
  }
}

.outline-map {
  margin-bottom: clamp(4rem, 4.5vw, 7.2rem);
}
.outline-map .c-ttl__04 {
  margin-bottom: clamp(5rem, 7vw, 9.2rem);
}
@media screen and (max-width: 834px) {
  .outline-map .c-ttl__04 {
    margin-bottom: 1em;
  }
}
.outline-map__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.outline-map__ttl {
  width: 95px;
  border-bottom: 1px solid #5B7C66;
  font-size: 2rem;
  padding-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .outline-map__ttl {
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }
}
.outline-map__ct {
  width: calc(100% - 195px);
}
@media screen and (max-width: 834px) {
  .outline-map__ct {
    width: 100%;
  }
}
.outline-map__ct .c-txt__02 {
  line-height: 2;
}
.outline-map__ct .--md {
  font-size: 2rem;
  display: block;
  color: #5B7C66;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .outline-map__ct .--md {
    font-size: 1.6rem;
  }
}
.outline-map__ct .--md .--s {
  font-size: 1.3rem;
}
.outline-map__btn {
  font-size: 1.3rem;
  line-height: 1.6153846154;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #D6D1C4;
  color: white;
  border-radius: 2px;
  padding: 3px 23px 1px;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
}
@media (any-hover: hover) {
  .outline-map__btn:hover {
    opacity: 0.7;
  }
}
@media screen and (min-width: 835px) {
  .outline-map__btn {
    transform: translateY(-3px);
  }
}
.outline-map__note {
  margin-top: 9px;
}
.outline-map__note li {
  position: relative;
}
.outline-map__note li:not(:last-child) {
  margin-bottom: 2px;
}
.outline-map__default {
  margin-top: 52px;
}
@media screen and (max-width: 640px) {
  .outline-map__default {
    margin-top: 30px;
  }
}
.outline-map__default iframe {
  width: 100%;
  height: 500px;
}
@media screen and (max-width: 834px) {
  .outline-map__default iframe {
    height: min(500px, 65vw);
  }
}

.outline-contact {
  background: #F3F3EC;
  border: 1px solid #E1DCCD;
  border-radius: 8px;
  padding: 38px 10px 32px;
}
@media screen and (max-width: 834px) {
  .outline-contact {
    text-align: center;
    padding: 20px 15px;
  }
}
.outline-contact__ttl {
  text-align: center;
  font-size: clamp(2rem, 2vw, 2.6rem);
  line-height: 1.4;
  font-family: "Shippori Mincho B1", serif;
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .outline-contact__ttl {
    margin-bottom: 10px;
  }
}
.outline-contact__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 855px;
  margin: 0 auto;
}
.outline-contact__subttl {
  font-size: clamp(1.6rem, 2vw, 2.1rem);
  font-weight: 500;
  width: 194px;
  padding-top: 10px;
}
@media screen and (max-width: 834px) {
  .outline-contact__subttl {
    width: 100%;
  }
}
.outline-contact__ct {
  width: calc(100% - 194px);
}
@media screen and (max-width: 834px) {
  .outline-contact__ct {
    width: 100%;
  }
}
.outline-contact__phone {
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
}
@media screen and (max-width: 1024px) {
  .outline-contact__phone {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  .outline-contact__phone {
    margin: 0 auto 5px;
  }
}
@media screen and (max-width: 374px) {
  .outline-contact__phone {
    font-size: 1.4rem;
  }
}
.outline-contact__phone .--md {
  font-size: clamp(2.4rem, 3.22vw, 3.3rem);
}
.outline-contact .c-txt__02 {
  letter-spacing: 0.9px;
}
@media screen and (min-width: 835px) {
  .outline-contact .c-txt__02 {
    text-align: right;
  }
}

.sec-materality__item {
  background: #f8f8f2;
  padding: clamp(5rem, 7vw, 11.7rem) 0 clamp(5rem, 7vw, 12rem);
}
.sec-materality__item:nth-child(2n) {
  background: #fff;
}
@media screen and (min-width: 835px) {
  .sec-materality__item:nth-child(2n) {
    padding-bottom: 124px;
  }
}
@media screen and (min-width: 835px) {
  .sec-materality__item:nth-child(3) {
    padding-bottom: 142px;
  }
}

.materality-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .materality-block {
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
}
.materality-block .c-ttl__07 {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .materality-block .c-ttl__07 {
    width: 100%;
  }
}
.materality-block .c-txt__01 {
  width: 50%;
  padding-top: 54px;
  letter-spacing: 0.1px;
}
@media screen and (max-width: 834px) {
  .materality-block .c-txt__01 {
    width: 100%;
    letter-spacing: 0;
    padding-top: 15px;
  }
}
.materality-block .c-ttl__01 {
  margin-bottom: 50px;
}
@media screen and (min-width: 835px) {
  .materality-block--02 {
    border-bottom: 1px solid #e1dccd;
    position: relative;
    padding-bottom: 29px;
    margin-bottom: 59px;
  }
  .materality-block--02:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 15px;
    height: auto;
    border-bottom: 1px solid #5b7c66;
    left: 0;
    bottom: -1px;
  }
}
.materality-block--02 .c-ttl__07 {
  width: 36.75%;
}
@media screen and (max-width: 834px) {
  .materality-block--02 .c-ttl__07 {
    width: 100%;
    border-bottom: 1px solid #e1dccd;
    position: relative;
    padding-bottom: 33px;
    margin-bottom: 34px;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .materality-block--02 .c-ttl__07 {
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .materality-block--02 .c-ttl__07:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 15px;
    height: auto;
    border-bottom: 1px solid #5b7c66;
    left: 0;
    bottom: -1px;
  }
}
.materality-block--02 .c-txt__01 {
  width: 63.25%;
  padding-top: 48px;
}
@media screen and (max-width: 834px) {
  .materality-block--02 .c-txt__01 {
    width: 100%;
    padding-top: 0;
  }
}

.materality-box__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .materality-box__list {
    flex-wrap: wrap;
    gap: 20px 0;
  }
}
.materality-box__list-item {
  width: calc(25% - 30px);
  padding: 21px 19px 58px;
  border: 1px solid #e1dccd;
  border-radius: 8px;
}
@media screen and (max-width: 1440px) {
  .materality-box__list-item {
    width: calc(25% - 10px);
  }
}
@media screen and (max-width: 1024px) {
  .materality-box__list-item {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 834px) {
  .materality-box__list-item {
    padding: 20px 12px min(35px, 4.19vw);
    width: calc(50% - 5px);
  }
}
.materality-box__list-number {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .materality-box__list-number {
    margin-bottom: 2.49vw;
  }
}
.materality-box__list-number span {
  width: 55px;
  line-height: 1;
  display: block;
  font-family: "Marcellus", serif;
  color: #5b7c66;
  position: relative;
  font-size: 3.2rem;
}
@media screen and (max-width: 834px) {
  .materality-box__list-number span {
    width: 45px;
    font-size: 2.4rem;
  }
}
.materality-box__list-number span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 1px;
  background: #5b7c66;
  bottom: -9px;
  left: 0;
}
@media screen and (max-width: 834px) {
  .materality-box__list-number span:before {
    width: 20px;
    bottom: 0;
  }
}
.materality-box__list-number small {
  display: block;
  width: calc(100% - 55px);
  color: #5b7c66;
  font-family: "Marcellus", serif;
  line-height: 1.4116;
  font-size: 1.2rem;
}
@media screen and (max-width: 834px) {
  .materality-box__list-number small {
    width: calc(100% - 45px);
    line-height: 1.1;
  }
}
.materality-box__list-ttl {
  text-align: center;
  color: #373737;
  line-height: 1.857;
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .materality-box__list-ttl {
    line-height: 1.5;
  }
}
@media screen and (max-width: 640px) {
  .materality-box__list-ttl {
    font-size: 1.3rem;
    text-align: left;
  }
}
.materality-box__list-ttl span {
  display: block;
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
  font-size: 2.8rem;
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .materality-box__list-ttl span {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 640px) {
  .materality-box__list-ttl span {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  .materality-img {
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .materality-img picture {
    display: block;
    width: 834px;
  }
}
.materality-img img {
  width: 100%;
}

.materality-table {
  margin-top: 72px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e1dccd;
  margin-bottom: -11px;
}
@media screen and (max-width: 834px) {
  .materality-table {
    margin-bottom: 0;
    margin-top: 35px;
    overflow-x: auto;
    padding-bottom: 1px;
  }
}
.materality-table table {
  width: calc(100% + 2px);
  margin: -1px;
}
@media screen and (max-width: 834px) {
  .materality-table table {
    width: 834px;
  }
}
.materality-table table th {
  text-align: center;
  padding: 23px 5px;
  background: #5b7c66;
  border: 1px solid #e1dccd;
  color: #fff;
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
  font-size: 1.8rem;
}
@media screen and (max-width: 1024px) {
  .materality-table table th {
    font-size: 1.6rem;
  }
}
.materality-table table th:nth-child(1) {
  width: 250px;
}
@media screen and (max-width: 1200px) {
  .materality-table table th:nth-child(1) {
    width: 200px;
  }
}
.materality-table table th:nth-child(3) {
  width: 200px;
}
@media screen and (max-width: 1200px) {
  .materality-table table th:nth-child(3) {
    width: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .materality-table table th:nth-child(3) {
    width: 120px;
  }
}
.materality-table table th:nth-child(4) {
  width: 330px;
}
@media screen and (max-width: 1200px) {
  .materality-table table th:nth-child(4) {
    width: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .materality-table table th:nth-child(4) {
    width: 160px;
  }
}
.materality-table table td {
  border: 1px solid #e1dccd;
  padding: 34px 21px 46px;
  background: #F3F3EC;
}
@media screen and (max-width: 1200px) {
  .materality-table table td {
    padding: 22px 8px;
  }
}
.materality-table table td p {
  line-height: 1.857;
  text-align: center;
  color: #373737;
  font-size: 1.4rem;
}
@media screen and (max-width: 1200px) {
  .materality-table table td p {
    font-size: 1.3rem;
  }
}
.materality-table table td p span {
  font-family: "Shippori Mincho B1", serif;
  display: block;
  font-weight: 500;
  margin-bottom: 19px;
  font-size: 2.8rem;
}
@media screen and (max-width: 1200px) {
  .materality-table table td p span {
    font-size: 2.4rem;
  }
}
.materality-table table td ul {
  padding-left: 5px;
}
@media screen and (max-width: 1200px) {
  .materality-table table td ul {
    padding-left: 0;
  }
}
.materality-table table td ul li {
  color: #373737;
  padding-left: 5px;
  position: relative;
  line-height: 1.3;
  font-size: 1.4rem;
}
.materality-table table td ul li:not(:last-child) {
  margin-bottom: 10px;
}
.materality-table table td ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 3px;
  height: 3px;
  background: #373737;
  top: 7px;
  left: 0;
  border-radius: 50%;
}
.materality-table__logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -8px;
  gap: 20px 0;
}
@media screen and (max-width: 1200px) {
  .materality-table__logo {
    gap: 10px 0;
  }
}
@media screen and (max-width: 1024px) {
  .materality-table__logo {
    margin: 0 -4px;
  }
}
.materality-table__logo-item {
  width: 25%;
  padding: 0 8px;
}
@media screen and (max-width: 1200px) {
  .materality-table__logo-item {
    width: 33.33%;
  }
}
@media screen and (max-width: 1024px) {
  .materality-table__logo-item {
    padding: 0 4px;
  }
}

.sec-message {
  background: #F8F8F2;
  padding: clamp(5rem, 6vw, 12rem) 0 clamp(5rem, 6vw, 15.8rem);
}

.message-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 116px;
}
@media screen and (max-width: 1200px) {
  .message-flex {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 834px) {
  .message-flex {
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
}
.message-flex__img {
  width: 480px;
}
@media screen and (max-width: 1200px) {
  .message-flex__img {
    width: 360px;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__img {
    width: 100%;
    margin-bottom: 20px;
  }
}
.message-flex__img img {
  width: 100%;
}
.message-flex__ct {
  padding-top: 50px;
  width: calc(100% - 480px);
  padding-left: 100px;
}
@media screen and (max-width: 1440px) {
  .message-flex__ct {
    padding-left: 50px;
    padding-top: 0;
  }
}
@media screen and (max-width: 1200px) {
  .message-flex__ct {
    padding-left: 30px;
    width: calc(100% - 360px);
  }
}
@media screen and (max-width: 834px) {
  .message-flex__ct {
    width: 100%;
    padding-left: 0;
  }
}
.message-flex__name {
  margin-bottom: 61px;
}
@media screen and (max-width: 1440px) {
  .message-flex__name {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__name {
    margin-bottom: 25px;
  }
}
.message-flex__name small {
  color: #373737;
  display: block;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  margin-bottom: 17px;
  letter-spacing: 1px;
  font-size: 2.2rem;
}
@media screen and (max-width: 1200px) {
  .message-flex__name small {
    letter-spacing: 0;
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__name small {
    font-size: 1.6rem;
  }
}
.message-flex__name > span {
  letter-spacing: 2px;
  font-weight: 500;
  font-family: "Shippori Mincho B1", serif;
  font-size: 3.4rem;
}
@media screen and (max-width: 1200px) {
  .message-flex__name > span {
    letter-spacing: 0;
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__name > span {
    font-size: 2.4rem;
  }
}
.message-flex__name > span span {
  margin-left: 40px;
  letter-spacing: 1px;
  font-family: "Marcellus", serif;
  color: #5B7C66;
  font-size: 2.8rem;
}
@media screen and (max-width: 1200px) {
  .message-flex__name > span span {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__name > span span {
    margin-left: 20px;
    letter-spacing: 0;
    font-size: 2rem;
  }
}
.message-flex__profile {
  border: 1px solid #E1DCCD;
  border-radius: 10px;
  padding: 38px 53px 58px;
  background: #F3F3EC;
}
@media screen and (max-width: 1200px) {
  .message-flex__profile {
    padding: 25px 25px;
  }
}
@media screen and (max-width: 834px) {
  .message-flex__profile {
    padding: 25px 20px;
  }
}
.message-flex__profile-ttl {
  color: #D3CAB2;
  font-family: "Marcellus", serif;
  margin-bottom: 11px;
  letter-spacing: 1px;
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .message-flex__profile-ttl {
    margin-bottom: 8px;
    letter-spacing: 0;
    font-size: 2rem;
  }
}

.message-content {
  line-height: 1.875;
  letter-spacing: 0.25px;
}
@media screen and (max-width: 834px) {
  .message-content {
    letter-spacing: 0;
    line-height: 1.6;
    font-size: 1.3rem;
  }
}
.message-content p:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .message-content p:not(:last-child) {
    margin-bottom: 20px;
  }
}
.message-content p .sign {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  color: #373737;
  display: block;
  text-align: right;
  margin-top: 47px;
  padding-right: 11px;
  font-size: 1.7rem;
}
@media screen and (max-width: 834px) {
  .message-content p .sign {
    margin-top: 20px;
    padding-right: 0;
    font-size: 1.6rem;
  }
}
.message-content p .sign span {
  margin-left: 9px;
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .message-content p .sign span {
    font-size: 2rem;
  }
}

.inbound-heading {
  position: relative;
  z-index: 2;
  padding: clamp(50px, 9vw, 131px) 0 clamp(50px, 13vw, 220px);
}
.inbound-heading:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 95%;
  height: 147%;
  background: url(../img/business/inbound/bg-01.png) no-repeat right bottom;
  background-size: 100% 100%;
  left: -0.3%;
  top: 14.5%;
  z-index: -1;
}
@media screen and (max-width: 990px) {
  .inbound-heading:before {
    height: 120%;
    top: 0;
    background-size: cover;
  }
}
@media screen and (max-width: 640px) {
  .inbound-heading:before {
    height: 150%;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .inbound-heading {
    background-position: -7% -16%;
  }
}
@media screen and (max-width: 990px) {
  .inbound-heading {
    background-size: cover;
    background-position: bottom right;
  }
}
.inbound-heading .heading-common {
  margin-bottom: 180px;
}
@media screen and (max-width: 834px) {
  .inbound-heading .heading-common {
    margin-bottom: 70px;
  }
}
.inbound-heading__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 10%;
}
@media screen and (max-width: 990px) {
  .inbound-heading__flex {
    flex-direction: column-reverse;
  }
}
.inbound-heading__flex-logo {
  margin-bottom: 23px;
}
@media screen and (max-width: 990px) {
  .inbound-heading__flex-logo {
    text-align: center;
    width: 100%;
  }
}
.inbound-heading__photo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 68.4%;
}
@media screen and (max-width: 990px) {
  .inbound-heading__photo {
    width: 100%;
  }
}
.inbound-heading__photo-item {
  width: 420px;
  margin: 0 10px;
}
@media screen and (max-width: 834px) {
  .inbound-heading__photo-item {
    width: 280px;
  }
}
.inbound-heading__photo-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.inbound-heading__photo-img::before {
  content: "";
  padding-top: 76.1904761905%;
  display: block;
}
.inbound-heading__photo-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.inbound-customer {
  background: #f8f8f2;
  padding: clamp(30px, 9vw, 110px) 0 clamp(50px, 12vw, 140px);
  position: relative;
  z-index: 2;
}
.inbound-customer__ttl {
  text-align: center;
  margin-bottom: clamp(30px, 5.083vw, 61px);
}
.inbound-customer__imgscroll {
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
}
.inbound-customer__imgscroll img {
  width: 100%;
}

.inbound-hotels {
  padding: clamp(50px, 9vw, 129px) 0 clamp(50px, 12vw, 162px);
}
.inbound-hotels__ttl {
  margin-bottom: clamp(30px, 6vw, 60px);
}

.hotels-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px 60px;
}
@media screen and (max-width: 1024px) {
  .hotels-column {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}
@media screen and (max-width: 640px) {
  .hotels-column {
    grid-template-columns: 1fr;
  }
}
.hotels-column__img {
  position: relative;
  margin-bottom: 26px;
}
.hotels-column__img-child {
  border-radius: 9px;
  position: relative;
  overflow: hidden;
}
.hotels-column__img-child::before {
  content: "";
  padding-top: 69.4444444444%;
  display: block;
}
.hotels-column__img-child img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}
.hotels-column__img-logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .hotels-column__img-logo img {
    max-width: 250px;
    width: 100%;
  }
}
.hotels-column__info {
  font-size: 1.4rem;
  line-height: 1.8571428571;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px 0;
}
.hotels-column__info > * {
  padding-bottom: 0.8em;
  padding-left: 0.715em;
}
.hotels-column__info-ttl {
  width: 80px;
  border-bottom: 1px solid #5b7c67;
}
.hotels-column__info-ct {
  width: calc(100% - 90px);
  border-bottom: 1px solid #e1dccd;
}

#page-company-group {
  background: #f8f8f2;
}

.sec-group {
  padding: clamp(2rem, 6.7vw, 11rem) 0 clamp(5rem, 9.6vw, 16rem);
}
.sec-group__brief {
  max-width: 920px;
  margin: 0 auto 60px;
}
@media screen and (max-width: 834px) {
  .sec-group__brief {
    margin-bottom: 30px;
  }
}

.group-table {
  overflow-x: auto;
  overflow-y: hidden;
}
.group-table table {
  width: 100%;
  background: white;
}
.group-table table td,
.group-table table th {
  border: 1px solid #e6e6e6;
}
.group-table table th {
  font-size: 1.7rem;
  font-family: "Shippori Mincho B1", serif;
  background: #ececdc;
  padding: 15px;
  text-align: center;
  font-weight: 400;
}
@media screen and (max-width: 834px) {
  .group-table table th {
    padding: 10px;
    font-size: 1.4rem;
  }
}
.group-table table th:first-child {
  width: 43.5%;
}
.group-table table td {
  padding: 19px 20px 15px;
}
@media screen and (max-width: 834px) {
  .group-table table td {
    padding: 10px;
  }
}
.group-table table td a {
  text-decoration: underline;
}
@media screen and (min-width: 835px) {
  .group-table table td.child {
    padding-left: 50px;
  }
}

.sec-management {
  padding-top: clamp(5em, 8vw, 13.1em);
}

.management-infor {
  margin-bottom: clamp(3em, 4vw, 6.5em);
}
.management-infor__block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: clamp(3.5em, 4.5vw, 7.1em);
  border-bottom: 1px solid #e1dccd;
  margin-bottom: clamp(3em, 5vw, 8em);
}
.management-infor__block:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .management-infor__block {
    display: block;
  }
}
.management-infor__block-l {
  width: 45%;
}
@media screen and (max-width: 834px) {
  .management-infor__block-l {
    width: 100%;
    margin-bottom: 25px;
  }
}
.management-infor__block-r {
  width: calc(52% - 20px);
}
@media screen and (max-width: 834px) {
  .management-infor__block-r {
    width: 100%;
  }
}
.management-infor__ttl {
  font-size: clamp(2.1rem, 2vw, 3.2rem);
  line-height: 1.5;
  font-weight: 500;
  padding: 0.2em 0 0.41em 0.47em;
  border-left: 3px solid #5b7c66;
  margin-bottom: 1.32em;
  font-family: "Shippori Mincho B1", serif;
}
.management-infor__ttl small {
  font-size: 68.75%;
  display: block;
}

.management-compare {
  margin-bottom: clamp(5em, 8.5vw, 14.2em);
}
.management-compare__ttl {
  font-size: 2.4rem;
  line-height: 2.5;
  font-weight: 500;
  margin-bottom: 11px;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .management-compare__ttl {
    line-height: 1.4;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .management-compare__ttlsmall {
    display: block;
    position: relative;
    font-size: 1.6rem;
    line-height: 1.4;
    padding: 0 0 0 15px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .management-compare__ttlsmall {
    font-family: "Shippori Mincho B1", serif;
  }
}
@media screen and (max-width: 834px) {
  .management-compare__ttlsmall:before {
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #adbdb2;
    position: absolute;
    top: 5px;
    left: 0;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .management-compare__ttlsmall:before {
    top: 7px;
  }
}
@media screen and (max-width: 834px) {
  .management-compare__tb {
    position: relative;
  }
  .management-compare__tbw {
    overflow: auto;
    margin-left: 160px;
    /* width: 800px; */
    overflow-x: scroll;
    overflow-y: visible;
    padding: 0;
  }
  .management-compare__tbw.js-scrollable {
    position: unset !important;
  }
  .management-compare__tb .sticky-col {
    position: absolute;
    left: 0;
    top: auto;
    border-top-width: 1px;
  }
}
.management-compare__tb table {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .management-compare__tb table {
    width: 800px;
    overflow: auto;
  }
}
.management-compare__tb tr th,
.management-compare__tb tr td {
  padding: 15px 15px 15px 30px;
  width: 19.29175%;
  text-align: center;
  font-size: clamp(1.2rem, 1.2vw, 1.6rem);
}
@media screen and (max-width: 834px) {
  .management-compare__tb tr th,
  .management-compare__tb tr td {
    width: calc((100% - 160px) / 4);
  }
}
.management-compare__tb tr th:first-child,
.management-compare__tb tr td:first-child {
  width: 22.833%;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .management-compare__tb tr th:first-child,
  .management-compare__tb tr td:first-child {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  .management-compare__tb tr th,
  .management-compare__tb tr td {
    padding: 10px 10px 10px 15px;
  }
}
.management-compare__tb tr th {
  color: #fff;
  background: #5b7c66;
  text-align: center;
  padding: 13px 5px;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .management-compare__tb tr th:first-child {
    height: 100%;
  }
}
.management-compare__tb tr td {
  background: #eae3df;
}
.management-compare__tb tr td:first-child {
  background: #5b7c66;
  color: #fff;
  text-align: left;
  border: 1px solid #fff;
}
.management-compare__tb tr td.bg-lightorange {
  background: #efecdd;
}
.management-compare__tb tr td.bg-lightblue {
  background: #e8eded;
}
.management-compare__tb tr:nth-child(1) th {
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  border: 1px solid #fff;
  font-weight: 400;
}
.management-compare__tb--custom table {
  width: 100%;
  margin-bottom: 30px;
}
.management-compare__tb--custom table:last-child {
  margin-bottom: 0;
}
.management-compare__tb--custom table tr th, .management-compare__tb--custom table tr td {
  width: 50% !important;
  padding: 10px 5px;
}
.management-compare__tb--custom table tr th {
  border: none !important;
  border-bottom: 1px solid white !important;
  text-align: left;
  padding-left: 15px;
}
.management-service {
  padding: clamp(5em, 7.5vw, 12.6em) 0 clamp(5em, 8.5vw, 14.6em);
  background: #f8f8f2;
}
.management-service__des {
  margin-bottom: 63px;
  margin-bottom: clamp(30px, 5.25vw, 63px);
}
.management-service__box {
  background: #fff;
  padding: clamp(1.8em, 3vw, 3.6em) clamp(2em, 5.3vw, 6em) clamp(3em, 4.5vw, 5em);
  border-radius: 5px;
}
.management-service__box-ttl {
  font-size: 2.4rem;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 1.5em;
  text-align: center;
  font-family: "Shippori Mincho B1", serif;
}
@media screen and (max-width: 834px) {
  .management-service__box-ttl {
    font-size: 2rem;
  }
}
.management-service__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 20px;
}
.management-service__list-item {
  width: calc(25% - 15px);
  padding: 10.5px 10px;
  border-radius: 3px;
  background: #f8f8f2;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .management-service__list-item {
    width: calc(33.33% - 13.3333px);
  }
}
@media screen and (max-width: 834px) {
  .management-service__list-item {
    width: calc(50% - 10px);
    line-height: 1.4;
  }
}

.management-hotel {
  padding: clamp(5em, 8.5vw, 13em) 0 clamp(5em, 9.8vw, 16em);
  background: #fff;
}
.management-hotel__box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .management-hotel__box {
    display: block;
  }
}
.management-hotel__box-img {
  position: relative;
  border-radius: 5px;
}
.management-hotel__box-img::before {
  content: "";
  float: left;
  padding-top: 71.4285714286%;
}
.management-hotel__box-img::after {
  content: "";
  display: table;
  clear: both;
}
.management-hotel__box-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.management-hotel__box-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.management-hotel__box-l {
  width: 52%;
}
@media screen and (max-width: 834px) {
  .management-hotel__box-l {
    width: 100%;
    margin-bottom: 35px;
  }
}
.management-hotel__box-r {
  padding-top: 6px;
  width: calc(48% - 20px);
}
@media screen and (max-width: 834px) {
  .management-hotel__box-r {
    width: 100%;
  }
}
.management-hotel__list li {
  display: flex;
  gap: 0 10px;
  margin-bottom: 11px;
}
@media screen and (min-width: 835px) {
  .management-hotel__list li {
    font-size: 1.6rem;
  }
}
.management-hotel__list li:last-child {
  margin-bottom: 0;
}
.management-hotel__list li::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #5b7c66;
  display: inline-block;
  position: relative;
  top: 6px;
}

.sec-news--ir {
  background: #fff;
  padding: clamp(5rem, 6.3vw, 11.1rem) 0 clamp(5rem, 9.5vw, 15.8rem);
}
@media screen and (min-width: 1201px) {
  .sec-news--ir .c-ttl__01 {
    padding-bottom: 30px;
    margin-bottom: 61px;
  }
}
.sec-news--ir .detail-content {
  margin: 0 auto;
  max-width: 715px;
}

div.pdfemb-viewer {
  margin: 0 auto clamp(30px, 6vw, 50px) !important;
  border: 1px solid #ebebeb !important;
  background: #fff !important;
}

.common-wrap__ir {
  margin-bottom: 100px;
}
@media screen and (max-width: 1024px) {
  .common-wrap__ir {
    margin-bottom: 50px;
  }
}
.common-wrap__ir-item {
  border-bottom: 1px solid #e6e6e6;
}
.common-wrap__ir-item a {
  line-height: 1.733;
  padding: 34px 0 34.5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #373737;
  position: relative;
}
.common-wrap__ir-item a:after {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  bottom: -1px;
  background: #5b7c66;
  width: 0;
  transition: all 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .common-wrap__ir-item a:hover {
    opacity: 1;
  }
  .common-wrap__ir-item a:hover:after {
    width: 100%;
  }
}
@media (hover: hover) {
  .common-wrap__ir-item a:hover {
    opacity: 1;
  }
  .common-wrap__ir-item a:hover:after {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .common-wrap__ir-item a {
    padding: 20px 0;
  }
}
@media screen and (max-width: 834px) {
  .common-wrap__ir-item a {
    flex-wrap: wrap;
    padding: 15px 0;
  }
}
.common-wrap__ir-item a time {
  padding: 0 20px;
  letter-spacing: 1px;
  width: 160px;
  display: block;
  font-family: "Marcellus", serif;
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .common-wrap__ir-item a time {
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
  }
}
.common-wrap__ir-item a p {
  padding-right: 20px;
  display: block;
  width: calc(100% - 160px);
  font-size: 1.5rem;
}
@media screen and (max-width: 834px) {
  .common-wrap__ir-item a p {
    padding-right: 0;
    width: 100%;
    font-size: 1.2rem;
  }
}

.sec-initiatives {
  padding: clamp(5rem, 9.5vw, 12.2rem) 0 0;
}
.sec-initiatives__img {
  margin-bottom: 64px;
}
@media screen and (max-width: 834px) {
  .sec-initiatives__img {
    margin-bottom: 30px;
  }
}
.sec-initiatives .materality-block {
  margin-bottom: 115px;
}
@media screen and (max-width: 834px) {
  .sec-initiatives .materality-block {
    margin-bottom: 50px;
  }
}

.initiatives-exem {
  background: #f8f8f2;
  padding: clamp(5rem, 7.5vw, 11.3rem) 0 clamp(5rem, 9.5vw, 14rem);
}
.initiatives-exem__ttl .c-ft--en {
  display: block;
  color: #5b7c66;
  font-size: 1.6rem;
  margin-left: 0;
  margin-bottom: 24px;
  font-weight: 400;
}
@media screen and (max-width: 834px) {
  .initiatives-exem__ttl .c-ft--en {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 835px) {
  .initiatives-exem__ttl {
    padding-bottom: 33px;
    margin-bottom: 60px;
  }
}

.initiatives-block:not(:last-child) {
  margin-bottom: 113px;
}
@media screen and (max-width: 834px) {
  .initiatives-block:not(:last-child) {
    margin-bottom: 50px;
  }
}
.initiatives-block__heading {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px 10px 10px 39px;
  background: #ececdc;
  border-radius: 8px;
  margin-bottom: 50px;
  gap: 10px;
}
@media screen and (max-width: 834px) {
  .initiatives-block__heading {
    margin-bottom: 25px;
    padding: 10px;
  }
}
@media screen and (max-width: 640px) {
  .initiatives-block__heading {
    padding: 10px 20px 15px;
  }
}
.initiatives-block__heading-ttl {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  color: #5b7c66;
}
@media screen and (max-width: 640px) {
  .initiatives-block__heading-ttl {
    width: 100%;
  }
}
.initiatives-block__logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}
@media screen and (max-width: 640px) {
  .initiatives-block__logo {
    width: 80%;
    gap: 10px;
  }
}
.initiatives-block__logo-item {
  width: 60px;
}
@media screen and (max-width: 640px) {
  .initiatives-block__logo-item {
    width: calc(25% - 7.5px);
  }
}
.initiatives-block__wp {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px 0;
}
.initiatives-block__wp:not(:last-child) {
  border-bottom: 1px solid #e1dccd;
  padding-bottom: 63px;
  margin-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .initiatives-block__wp:not(:last-child) {
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
}
.initiatives-block__wp-ct {
  width: 50%;
  margin-top: -9px;
}
@media screen and (max-width: 834px) {
  .initiatives-block__wp-ct {
    width: 100%;
    margin-top: 0;
  }
}
.initiatives-block__wp-img {
  width: 40%;
}
@media screen and (max-width: 834px) {
  .initiatives-block__wp-img {
    width: 100%;
  }
}
.initiatives-block__wp-img img {
  border-radius: 8px;
}
@media screen and (max-width: 640px) {
  .initiatives-block__wp-img img {
    object-fit: cover;
    width: 100%;
    height: 50vw;
  }
}
.initiatives-block__wp-ttl {
  font-size: 2.6rem;
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  margin-bottom: 24px;
}
@media screen and (max-width: 1200px) {
  .initiatives-block__wp-ttl {
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 15px;
  }
}
.initiatives-block__wp-brief {
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .initiatives-block__wp-brief {
    margin-bottom: 20px;
  }
}
.initiatives-block__detail {
  background: #f3f3ec;
  border-radius: 8px;
  padding: 19px 30px 14px;
}
@media screen and (max-width: 834px) {
  .initiatives-block__detail {
    padding: 15px;
  }
}
.initiatives-block__detail-ttl {
  margin-bottom: 13px;
}
.initiatives-block__detail-ttl span {
  display: inline-block;
  background: #5b7c66;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 5px 11px 2px;
}
.initiatives-block__note li {
  position: relative;
  padding-left: 20px;
}
.initiatives-block__note li:not(:last-child) {
  margin-bottom: 7px;
}
.initiatives-block__note li::before {
  content: "・";
  position: absolute;
  left: 0;
  top: 0;
}

#page-ir {
  background: #f8f8f2;
}

.sec-ir-news {
  padding: clamp(5rem, 6.1vw, 10.4rem) 0 clamp(4rem, 7.2vw, 12.2rem);
}
.sec-ir-news .c-ttl__01 {
  margin-bottom: 10px;
}

.ir-news {
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .ir-news {
    margin-bottom: 30px;
  }
}
.ir-news--link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 74px;
}
@media screen and (max-width: 834px) {
  .ir-news--link {
    margin-bottom: 45px;
  }
}
.ir-news--link:last-child {
  margin-bottom: 0;
}
.ir-news--link .c-btn__01 .c-ft--jp {
  font-family: "IBM Plex Sans JP", sans-serif;
}
.ir-news__item {
  border-bottom: 1px solid #e6e6e6;
}
.ir-news__item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 33px 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}
@media screen and (max-width: 834px) {
  .ir-news__item a {
    padding: 20px 0;
  }
}
.ir-news__item a time {
  display: block;
  width: 100px;
  text-align: center;
  font-family: "Marcellus", serif;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 834px) {
  .ir-news__item a time {
    width: 80px;
  }
}
.ir-news__item a p {
  width: calc(100% - 100px);
  padding: 0 0 0 5%;
}
@media screen and (max-width: 834px) {
  .ir-news__item a p {
    width: calc(100% - 80px);
    padding: 0 0 0 15px;
  }
}
.ir-news__item a:after {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  bottom: -1px;
  background: #5b7c66;
  width: 0;
  transition: all 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .ir-news__item a:hover {
    opacity: 1;
  }
  .ir-news__item a:hover:after {
    width: 100%;
  }
}
@media (hover: hover) {
  .ir-news__item a:hover {
    opacity: 1;
  }
  .ir-news__item a:hover:after {
    width: 100%;
  }
}
@media screen and (min-width: 641px) {
  .ir-news--column2 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .ir-news--column2 .ir-news__item {
    width: calc(50% - 20px);
  }
}

.sec-ir-menu {
  background: white;
  padding: clamp(5rem, 6.3vw, 10.7rem) 0 clamp(4rem, 15.77vw, 26.5rem);
}
@media screen and (max-width: 640px) {
  .sec-ir-menu {
    padding-bottom: 4rem;
  }
}
.sec-ir-menu .c-ttl__01 {
  margin-bottom: 29px;
  border-bottom: none;
}
.sec-ir-menu .c-ttl__01:after {
  display: none;
}

.ir-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -2.5%;
}
.ir-menu__item {
  width: 50%;
  padding: 0 2.5%;
  margin-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .ir-menu__item {
    width: 100%;
    margin-bottom: 30px;
  }
  .ir-menu__item:last-child {
    margin-bottom: 0;
  }
}
.ir-menu__item.none {
  display: none;
}
.ir-menu__img {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  margin-bottom: 39px;
  position: relative;
}
.ir-menu__img::before {
  content: "";
  float: left;
  padding-top: 42.1052631579%;
}
.ir-menu__img::after {
  content: "";
  display: table;
  clear: both;
}
.ir-menu__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ir-menu__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .ir-menu__img {
    margin-bottom: 20px;
  }
}
.ir-menu__img span {
  display: block;
  font-size: 2.2rem;
  color: white;
  font-family: "Shippori Mincho B1", serif;
  position: absolute;
  bottom: 24px;
  left: 29px;
  right: 15px;
}
@media screen and (max-width: 834px) {
  .ir-menu__img span {
    font-size: 1.8rem;
    bottom: 15px;
    left: 15px;
  }
}
.ir-menu__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.ir-menu__list li {
  width: 50%;
  padding: 0 5px;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .ir-menu__list li {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 374px) {
  .ir-menu__list li {
    width: 100%;
  }
}
@media screen and (min-width: 835px) {
  .ir-menu__list li .c-btn__01 {
    font-size: 1.6rem;
  }
}
.ir-menu__list li .c-btn__01 .c-ft--jp {
  font-family: "IBM Plex Sans JP", sans-serif;
}

.sec-company-page {
  padding: clamp(2rem, 3.21vw, 5.4rem) 0 clamp(5rem, 9.464vw, 15.9rem);
}
@media screen and (min-width: 835px) {
  .sec-company-page .top-introduction__list {
    gap: 44px 41px;
    margin-bottom: 120px;
  }
  .sec-company-page .top-introduction__list-img:before {
    padding-bottom: 65.5%;
  }
}
@media screen and (max-width: 834px) {
  .sec-company-page .top-introduction__list {
    margin-bottom: 50px;
  }
}

.company-links {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.company-links__item {
  width: calc(50% - 20px);
  border-bottom: 1px solid #E6E6E6;
}
.company-links__item:nth-child(1), .company-links__item:nth-child(2) {
  border-top: 1px solid #E6E6E6;
}
@media screen and (max-width: 640px) {
  .company-links__item {
    width: 100%;
  }
  .company-links__item:nth-child(2) {
    border-top: none;
  }
}
.company-links__item .c-btn__01 {
  position: relative;
  width: 100%;
  padding: 32px 0;
}
@media screen and (max-width: 640px) {
  .company-links__item .c-btn__01 {
    padding: 15px 0;
  }
}
.company-links__item .c-btn__01 .ico {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 27px;
}
@media screen and (max-width: 640px) {
  .company-links__item .c-btn__01 .ico {
    right: 0;
  }
}
.company-links__item .c-btn__01 .c-ft--jp {
  font-size: 2rem;
}

#page-company-info {
  background: #f8f8f2;
}

.sec-philosophy {
  padding: clamp(5rem, 8.6vw, 14.2rem) 0 clamp(5rem, 7vw, 11.2rem);
}

.philosophy-heading {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 93px;
  margin-bottom: 82px;
}
@media screen and (max-width: 834px) {
  .philosophy-heading {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
.philosophy-heading__ttl {
  margin-bottom: 68px;
}
@media screen and (max-width: 834px) {
  .philosophy-heading__ttl {
    margin-bottom: 30px;
  }
}
.philosophy-heading__subttl {
  text-align: center;
  margin-bottom: 34px;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .philosophy-heading__subttl {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 640px) {
  .philosophy-heading__subttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 374px) {
  .philosophy-heading__subttl {
    font-size: 1.7rem;
  }
}
.philosophy-heading .c-txt__03 {
  max-width: 800px;
  margin: 0 auto;
}

.philosophy-vision {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 89px;
  margin-bottom: 82px;
}
@media screen and (max-width: 834px) {
  .philosophy-vision {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
.philosophy-vision__ttl {
  margin-bottom: 69px;
}
@media screen and (max-width: 834px) {
  .philosophy-vision__ttl {
    margin-bottom: 30px;
  }
}

.philosophy-list {
  font-size: 2rem;
  line-height: 1.8;
  max-width: 956px;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .philosophy-list {
    font-size: 1.5rem;
  }
}
.philosophy-list li {
  position: relative;
  padding-left: 28px;
}
@media screen and (max-width: 834px) {
  .philosophy-list li {
    padding-left: 25px;
  }
}
.philosophy-list li:not(:last-child) {
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .philosophy-list li:not(:last-child) {
    margin-bottom: 10px;
  }
}
.philosophy-list li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: currentColor;
  border-radius: 50%;
  left: 0;
  top: 9px;
}
@media screen and (max-width: 834px) {
  .philosophy-list li:before {
    top: 5px;
  }
}
.philosophy-list--number {
  counter-reset: number;
}
@media screen and (min-width: 835px) {
  .philosophy-list--number li {
    padding-left: 35px;
  }
}
@media screen and (min-width: 835px) {
  .philosophy-list--number li:not(:last-child) {
    margin-bottom: 32px;
  }
}
.philosophy-list--number li::before {
  counter-increment: number;
  content: "0" counter(number);
  background: transparent;
  top: 0;
  width: auto;
  height: auto;
  color: #5b7c66;
  font-family: "Marcellus", serif;
  font-size: 1.8rem;
}
@media screen and (max-width: 834px) {
  .philosophy-list--number li::before {
    font-size: 1.6rem;
  }
}

.philosophy-value {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.philosophy-value__ttl {
  width: 290px;
  padding-left: 61px;
}
@media screen and (max-width: 834px) {
  .philosophy-value__ttl {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
.philosophy-value .philosophy-list {
  width: calc(100% - 400px);
  margin: 11px 0 0;
}
@media screen and (max-width: 834px) {
  .philosophy-value .philosophy-list {
    width: 100%;
  }
}

.key-indicatiors {
  background: #f8f8f2;
  padding: clamp(5rem, 8vw, 12.6rem) 0 clamp(5rem, 11vw, 18.3rem);
}
.key-indicatiors__ttl {
  text-align: center;
  margin-bottom: 48px;
}
@media screen and (max-width: 834px) {
  .key-indicatiors__ttl {
    margin-bottom: 20px;
  }
}
.key-indicatiors__ttl .c-ft--en {
  display: block;
  color: #5b7c66;
  margin-top: 5px;
  margin-left: 0;
}
.key-indicatiors .c-txt__03 {
  max-width: 800px;
  margin: 0 auto;
}

.sec-sustainability {
  background: #f8f8f2;
  padding-bottom: clamp(5rem, 8.7vw, 14.6rem);
}
.sec-sustainability .materality-box__list {
  margin: 50px 0 42px;
}
@media screen and (max-width: 834px) {
  .sec-sustainability .materality-box__list {
    margin: 30px auto;
  }
}

.sustainability-intro {
  position: relative;
  padding: clamp(50px, 6.5vw, 107px) 0 clamp(50px, 6.6vw, 110px);
  margin-bottom: clamp(50px, 7.2vw, 121px);
  z-index: 1;
}
@media screen and (min-width: 835px) {
  .sustainability-intro {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .sustainability-intro .c-ttl__01 {
    width: 54%;
    line-height: 1.6875;
  }
}
@media screen and (min-width: 835px) and (max-width: 1200px) {
  .sustainability-intro .c-ttl__01 {
    width: 56%;
  }
}
@media screen and (min-width: 835px) {
  .sustainability-intro .c-txt__01 {
    width: 44.8%;
  }
}
@media screen and (min-width: 835px) and (min-width: 1441px) {
  .sustainability-intro .c-txt__01 {
    margin-top: 23px;
  }
}
@media screen and (min-width: 835px) and (max-width: 1200px) {
  .sustainability-intro .c-txt__01 {
    width: 44%;
  }
}
.sustainability-intro .c-ttl__01 {
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .sustainability-intro .c-ttl__01 {
    margin-bottom: 15px;
  }
}
.sustainability-intro:before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  background: white;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.sustainability-csv {
  margin-bottom: clamp(50px, 7.2vw, 120px);
}
@media screen and (min-width: 835px) {
  .sustainability-csv {
    margin-top: -8px;
  }
}
@media screen and (min-width: 641px) {
  .sustainability-csv__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.sustainability-csv__item:not(:last-child) {
  margin-bottom: -20px;
}
@media screen and (max-width: 834px) {
  .sustainability-csv__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.sustainability-csv__head {
  text-align: center;
  width: 180px;
  height: 180px;
  background: rgba(91, 124, 102, 0.4);
  color: white;
  border-radius: 50%;
  padding: 52px 10px 10px;
}
@media screen and (max-width: 1024px) {
  .sustainability-csv__head {
    width: 140px;
    height: 140px;
    padding-top: 35px;
  }
}
@media screen and (max-width: 640px) {
  .sustainability-csv__head {
    margin: 0 auto 20px;
  }
}
.sustainability-csv__head-ttl {
  font-weight: 500;
  font-size: 2.1rem;
  margin-top: 18px;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 1024px) {
  .sustainability-csv__head-ttl {
    font-size: 1.8rem;
    margin-top: 10px;
  }
}
.sustainability-csv__ct {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 641px) {
  .sustainability-csv__ct {
    width: calc(64% - 180px);
    letter-spacing: 0.02em;
    padding: 23px 5px 20px 46px;
  }
}
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .sustainability-csv__ct {
    width: calc(66% - 140px);
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .sustainability-csv__ct {
    width: calc(100% - 140px);
  }
}
@media screen and (min-width: 641px) {
  .sustainability-csv__ct:before {
    content: "";
    position: absolute;
    background: url("../img/sustainability/sustainability-shape.png") center no-repeat;
    background-size: auto 100%;
    width: 84px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.sustainability-csv__ct-tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 3px;
  margin-bottom: 12px;
}
@media screen and (max-width: 640px) {
  .sustainability-csv__ct-tag {
    justify-content: center;
  }
}
.sustainability-csv__ct-tag span {
  display: inline-block;
  background: white;
  border: 1px solid #dddddd;
  border-radius: 3px;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 6px 10px 2px;
}
@media screen and (max-width: 834px) {
  .sustainability-csv__ct-tag span {
    font-size: 1.1rem;
  }
}
.sustainability-csv__img {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 8px;
}
@media screen and (min-width: 641px) {
  .sustainability-csv__img {
    width: 31.7%;
  }
}
@media screen and (min-width: 641px) and (max-width: 834px) {
  .sustainability-csv__img {
    width: calc(100% - 187px);
    margin-left: auto;
  }
}
@media screen and (max-width: 640px) {
  .sustainability-csv__img {
    margin-top: 10px;
  }
}

.sustainability-block {
  font-size: 1.2rem;
  line-height: 1.6666666667;
}
.sustainability-block__img {
  margin-bottom: 15px;
}
.sustainability-block__img img {
  display: block;
  margin: 0 auto;
}
.sustainability-block__item {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #e1dccd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.sustainability-block__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.sustainability-block__ct {
  position: relative;
  padding-top: 45px;
}
.sustainability-block__ct .sustainability-csv__img {
  margin-top: 12px;
}
.sustainability-block__ct:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: url("../img/sustainability/sustainability-bf.png") no-repeat;
  background-size: 100%;
  background-position: top center;
}
.sustainability-block__ct-ttl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 5px;
}
.sustainability-block__ct-ttl span {
  display: block;
  background: white;
  font-size: 1rem;
  border: 1px solid #ddd;
  padding: 2px 4px 0;
  margin: 0 1px;
  position: relative;
  top: -1px;
}
.sustainability-block__ct-ttl span:nth-child(1) {
  margin-left: 5px;
}

.sustainability-materiality {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 40px;
  margin: 50px 0 42px;
  counter-reset: my-sec-counter;
}
@media screen and (max-width: 1024px) {
  .sustainability-materiality {
    gap: 10px;
  }
}
@media screen and (max-width: 834px) {
  .sustainability-materiality {
    max-width: 500px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 30px auto;
  }
}
.sustainability-materiality__item {
  position: relative;
  background: #f3f3ec;
  border-radius: 8px;
  border: 1px solid #e1dccd;
}
.sustainability-materiality__item:before {
  content: "";
  display: block;
  padding-bottom: 103.7037037037%;
}
.sustainability-materiality__count {
  position: absolute;
  width: 100%;
  color: #5b7c66;
  font-family: "Marcellus", serif;
  font-size: 1.2rem;
  line-height: 1.4166666667;
  letter-spacing: 0.05em;
  padding-left: 71px;
  top: 19px;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .sustainability-materiality__count {
    padding-left: 56px;
    top: 10px;
  }
}
@media screen and (max-width: 374px) {
  .sustainability-materiality__count {
    font-size: 1rem;
    padding-left: 45px;
    top: 4px;
  }
}
.sustainability-materiality__count:before {
  counter-increment: my-sec-counter;
  content: "0" counter(my-sec-counter);
  position: absolute;
  font-size: 3.2rem;
  line-height: 1;
  top: 3px;
  left: 17px;
}
@media screen and (max-width: 1024px) {
  .sustainability-materiality__count:before {
    font-size: 2.6rem;
    top: 4px;
    left: 13px;
  }
}
@media screen and (max-width: 374px) {
  .sustainability-materiality__count:before {
    font-size: 2rem;
  }
}
.sustainability-materiality__count:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  background: #5b7c66;
  bottom: -11px;
  left: 19px;
}
@media screen and (max-width: 1024px) {
  .sustainability-materiality__count:after {
    bottom: 0;
    left: 15px;
  }
}
@media screen and (max-width: 374px) {
  .sustainability-materiality__count:after {
    width: 23px;
    bottom: 3px;
  }
}
.sustainability-materiality__ct {
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.8571428571;
  padding: 15px 23px;
  top: 57.3%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 834px) {
  .sustainability-materiality__ct {
    font-size: 1.2rem;
    padding: 15px 10px 0;
  }
}
.sustainability-materiality__ct-ttl {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 500;
  font-size: 2.8rem;
  letter-spacing: 0.05em;
  margin-bottom: 18px;
}
@media screen and (max-width: 1200px) {
  .sustainability-materiality__ct-ttl {
    font-size: 2.4rem;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 374px) {
  .sustainability-materiality__ct-ttl {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}

.sustainability-btn {
  margin-bottom: clamp(50px, 7.2vw, 116px);
}

.sustainability-goals {
  margin: 35px 0 clamp(30px, 4vw, 61px);
}
@media screen and (min-width: 835px) {
  .sustainability-goals {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 835px) {
  .sustainability-goals__main {
    width: 25.6%;
  }
}
@media screen and (max-width: 834px) {
  .sustainability-goals__main {
    max-width: 280px;
    margin: 0 auto 15px;
  }
}
.sustainability-goals__list {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  gap: 9px 11px;
}
@media screen and (min-width: 835px) {
  .sustainability-goals__list {
    width: 72.78%;
  }
}
@media screen and (max-width: 640px) {
  .sustainability-goals__list {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 5px;
  }
}

.sustainability-initiatives {
  counter-reset: my-sec-counter;
  margin-bottom: 40px;
}
@media screen and (min-width: 835px) {
  .sustainability-initiatives {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 40px;
  }
}
@media screen and (min-width: 835px) and (max-width: 1024px) {
  .sustainability-initiatives {
    gap: 0 15px;
  }
}
.sustainability-initiatives__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1dccd;
  padding: 31px 0 31px 9px;
}
@media screen and (max-width: 1024px) {
  .sustainability-initiatives__item {
    padding: 20px 0;
  }
}
.sustainability-initiatives__img {
  position: relative;
  width: 28.2%;
}
.sustainability-initiatives__img:before {
  content: "";
  display: block;
  padding-bottom: 81.25%;
}
.sustainability-initiatives__img img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
}
.sustainability-initiatives__ct {
  position: relative;
  width: 67.5%;
  font-family: "Shippori Mincho B1", serif;
  font-size: clamp(1.8rem, 1.7vw, 2.4rem);
  padding-top: 37px;
}
@media screen and (max-width: 1024px) {
  .sustainability-initiatives__ct {
    padding-top: 23px;
  }
}
@media screen and (max-width: 640px) {
  .sustainability-initiatives__ct {
    line-height: 1.2;
  }
}
.sustainability-initiatives__ct:before {
  counter-increment: my-sec-counter;
  content: "Examples of initiatives 0" counter(my-sec-counter);
  position: absolute;
  color: #5b7c66;
  font-family: "Marcellus", serif;
  font-size: 1.2rem;
  letter-spacing: 0.04em;
  top: 10px;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .sustainability-initiatives__ct:before {
    top: 0;
  }
}
.sustainability-initiatives__ct span {
  display: block;
  font-size: clamp(1.3rem, 1.9vw, 1.6rem);
  margin-bottom: 4px;
}

.value-top {
  padding: clamp(5rem, 9vw, 11.6rem) 0 clamp(5rem, 9vw, 12rem);
  background: #f8f8f2;
}
.value-top .materality-block {
  margin-bottom: 65px;
}
@media screen and (max-width: 834px) {
  .value-top .materality-block {
    margin-bottom: 30px;
  }
}
.value-top .materality-block__ct {
  width: 50%;
  padding-top: 50px;
}
@media screen and (max-width: 834px) {
  .value-top .materality-block__ct {
    width: 100%;
    padding-top: 20px;
  }
}
.value-top .materality-block__ct .c-ttl__01 {
  font-weight: 500;
  letter-spacing: -2px;
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .value-top .materality-block__ct .c-ttl__01 {
    margin-bottom: 15px;
  }
}
.value-top .materality-block__ct .c-txt__01 {
  width: 100%;
  padding-top: 0;
}

.value-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
@media screen and (max-width: 990px) {
  .value-info {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 710px) {
  .value-info {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
@media screen and (max-width: 640px) {
  .value-info {
    gap: 15px;
  }
}
.value-info__item {
  background: #f3f3ec;
  border-radius: 8px;
  padding: 32px 40px;
  display: grid;
  grid-template-rows: subgrid;
  gap: 0;
  grid-row: 2 span;
}
@media screen and (max-width: 1440px) {
  .value-info__item {
    padding: 32px 15px;
  }
}
@media screen and (max-width: 640px) {
  .value-info__item {
    padding: 15px 20px;
  }
}
.value-info__ttl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px 20px;
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .value-info__ttl {
    margin-bottom: 15px;
  }
}
.value-info__ttl-txt {
  color: #5b7c66;
  white-space: nowrap;
  text-align: center;
}
.value-info__ttl-txt > * {
  display: block;
}
.value-info__ttl-txt .c-ft--en {
  font-size: 1.2rem;
  border-bottom: 1px solid currentColor;
  padding-bottom: 5px;
  margin-bottom: 7px;
}
.value-info__ttl-txt .c-ft--base {
  font-size: 1.4rem;
}
.value-info__ttl-sub {
  font-size: 2rem;
}
@media screen and (max-width: 1200px) {
  .value-info__ttl-sub {
    font-size: 1.6rem;
  }
}

.value-box {
  padding: clamp(5rem, 8.5vw, 12rem) 0 clamp(5rem, 8.5vw, 11rem);
}
.value-box__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 0;
}
.value-box__flex-left {
  width: 42%;
  margin-top: -26px;
}
@media screen and (max-width: 720px) {
  .value-box__flex-left {
    width: 100%;
    margin: 0 0 20px;
  }
}
@media screen and (max-width: 640px) {
  .value-box__flex-left {
    margin: 0;
  }
}
.value-box__flex-right {
  width: 50%;
}
@media screen and (max-width: 720px) {
  .value-box__flex-right {
    width: 100%;
  }
}
@media screen and (min-width: 720px) {
  .value-box__flex-stick {
    position: sticky;
    top: 80px;
  }
}
.value-box__flex-ttl {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .value-box__flex-ttl {
    margin-bottom: 20px;
  }
}
.value-box__flex-ttl > * {
  display: block;
}
.value-box__flex-ttl .c-ft--en {
  color: #5b7c66;
  font-size: 1.6rem;
  margin-left: 0;
  margin-bottom: 24px;
}
@media screen and (max-width: 834px) {
  .value-box__flex-ttl .c-ft--en {
    margin-bottom: 12px;
  }
}
.value-box__flex-ttl .c-ft--jp {
  line-height: 1.6;
  font-weight: 500;
}
.value-box__flex-ttl .c-ft--jp .--md {
  font-size: clamp(2.8rem, 2.8vw, 4.6rem);
}
.value-box__list-item:not(:last-child) {
  margin-bottom: 112px;
}
@media screen and (max-width: 834px) {
  .value-box__list-item:not(:last-child) {
    margin-bottom: 50px;
  }
}
.value-box__list-img {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .value-box__list-img {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 640px) {
  .value-box__list-img {
    margin-bottom: 30px;
  }
}
.value-box__list-img::before {
  content: "";
  padding-top: 41.6666666667%;
  display: block;
}
.value-box__list-img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.value-box__list-ttl {
  font-size: 2rem;
  font-family: "Marcellus", serif;
  color: #5b7c66;
  position: relative;
  padding-bottom: 1px;
  margin-bottom: 27px;
}
@media screen and (max-width: 834px) {
  .value-box__list-ttl {
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
}
.value-box__list-ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: #5b7c66;
  left: 0;
  bottom: 0;
}
.value-box__list-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 20px);
  height: 1px;
  background: #e1dccd;
  right: 0;
  bottom: 0;
}
.value-box__list-subttl {
  margin-bottom: 27px;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .value-box__list-subttl {
    margin-bottom: 15px;
  }
}
.value-box__list-brief {
  margin-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .value-box__list-brief {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 640px) {
  .value-box__list-brief {
    margin-bottom: 20px;
  }
}
.value-box__info {
  counter-reset: number;
}
.value-box__info-item:not(:last-child) {
  margin-bottom: 47px;
}
@media screen and (max-width: 834px) {
  .value-box__info-item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.value-box__info-ttl {
  position: relative;
  padding-left: 64px;
  font-family: "Shippori Mincho B1", serif;
  font-size: clamp(1.8rem, 2vw, 2.6rem);
  font-weight: 500;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .value-box__info-ttl {
    padding-left: 40px;
  }
}
.value-box__info-ttl::before {
  counter-increment: number;
  content: "0" counter(number);
  font-size: clamp(2.2rem, 2.5vw, 3.2rem);
  font-family: "Marcellus", serif;
  color: #5b7c66;
  position: absolute;
  left: 0;
  top: 2px;
  line-height: 1.2;
}
.value-box__info-ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 44px;
  height: 1px;
  background: #5b7c66;
  left: 0;
  top: 1.7em;
}
@media screen and (max-width: 834px) {
  .value-box__info-ttl:after {
    width: 30px;
  }
}

.value-project {
  padding: clamp(5rem, 8.5vw, 10.5rem) 0 clamp(5rem, 12vw, 17rem);
  background: #f8f8f2;
}
@media screen and (min-width: 1201px) {
  .value-project__ttl {
    font-size: 3.8rem;
  }
}
.value-project__ttl .c-ft--en {
  display: block;
  color: #5b7c66;
  font-size: 1.6rem;
  margin-left: 0;
  margin-bottom: 24px;
}
@media screen and (max-width: 834px) {
  .value-project__ttl .c-ft--en {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 835px) {
  .value-project__ttl {
    padding-bottom: 33px;
    margin-bottom: 69px;
  }
}
@media screen and (max-width: 834px) {
  .value-project__imgscroll {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .value-project__imgscroll img {
    max-width: 840px;
  }
}

.sec-solicitation {
  padding: 46px 0 140px;
}
@media screen and (max-width: 834px) {
  .sec-solicitation {
    padding: 20px 0 50px;
  }
}
.sec-solicitation__ttl {
  text-align: center;
  margin-bottom: 106px;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .sec-solicitation__ttl {
    margin-bottom: 30px;
  }
}
.sec-solicitation .inner {
  max-width: 1038px;
}

.solicitation-list__item:not(:last-child) {
  margin-bottom: 66px;
}
@media screen and (max-width: 834px) {
  .solicitation-list__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.solicitation-list__ttl {
  font-size: 2.1rem;
  font-weight: 600;
  font-family: "Shippori Mincho B1", serif;
  padding-bottom: 15px;
  margin-bottom: 32px;
  position: relative;
  line-height: 1.5;
}
@media screen and (max-width: 834px) {
  .solicitation-list__ttl {
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 1.6rem;
  }
}
.solicitation-list__ttl:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 1px;
  background: #5b7c66;
  left: 0;
  bottom: 0;
}
.solicitation-list__ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 20px);
  height: 1px;
  background: #e1dccd;
  right: 0;
  bottom: 0;
}
.solicitation-list__number {
  counter-reset: number;
  padding-left: 19px;
}
@media screen and (max-width: 834px) {
  .solicitation-list__number {
    padding-left: 15px;
  }
}
.solicitation-list__number li {
  position: relative;
  padding-left: 20px;
}
.solicitation-list__number li::before {
  counter-increment: number;
  content: counter(number) ".";
  position: absolute;
  left: 0;
  top: 0;
}

#page-sitemap, #page-solicitation {
  background: #F8F8F2;
}

.sec-sitemap {
  padding: clamp(2rem, 6vw, 4.9rem) 0 clamp(5rem, 9.2vw, 15.2rem);
}

.sitemap-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "Shippori Mincho B1", serif;
  gap: 10px 0;
}
@media screen and (max-width: 640px) {
  .sitemap-list {
    justify-content: space-between;
  }
}
.sitemap-list a {
  transition: all 0.3s ease;
}
@media (any-hover: hover) {
  .sitemap-list a:hover {
    opacity: 0.7;
  }
}
.sitemap-list__first {
  width: 100%;
  margin-bottom: 47px;
}
@media screen and (max-width: 834px) {
  .sitemap-list__first {
    margin-bottom: 30px;
  }
}
.sitemap-list__first a {
  font-weight: 500;
  font-size: 2.4rem;
}
@media screen and (max-width: 834px) {
  .sitemap-list__first a {
    font-size: 2rem;
  }
}
.sitemap-list__parent {
  font-size: 2rem;
  line-height: 1.5;
  width: 33.3%;
}
@media screen and (max-width: 834px) {
  .sitemap-list__parent {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 640px) {
  .sitemap-list__parent {
    width: calc(50% - 5px);
  }
}
@media screen and (max-width: 834px) {
  .sitemap-list__parent {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 641px) {
  .sitemap-list__parent:nth-child(3) {
    width: 39%;
  }
  .sitemap-list__parent:nth-child(4) {
    width: 20%;
  }
}
.sitemap-list__parent-item:not(:last-child) {
  margin-bottom: 16px;
}
@media screen and (max-width: 834px) {
  .sitemap-list__parent-item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.sitemap-list__sub {
  font-size: 1.7rem;
  padding: 20px 0 0 33px;
}
@media screen and (max-width: 834px) {
  .sitemap-list__sub {
    font-size: 1.3rem;
    padding: 10px 0 0 15px;
  }
}
@media screen and (max-width: 374px) {
  .sitemap-list__sub {
    padding: 10px 0 0 10px;
    font-size: 1.2rem;
  }
}
.sitemap-list__sub-item:not(:last-child) {
  margin-bottom: 21px;
}
@media screen and (max-width: 834px) {
  .sitemap-list__sub-item:not(:last-child) {
    margin-bottom: 10px;
  }
}