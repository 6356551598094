@use "vars" as *;

.main-header{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    transition: background .3s;
    &__inner {
        height: 100px;
        gap: 38px;
        padding: 0 90px;
        @include mq(lp) {
            padding: 0 16px;
            gap: 15px;
        }
        @include mq(xl) {
            height: 59px;
        }
    }
    &__logo{
        width: 187px;
        position: relative;
        @include mq(xl) {
            width: 120px;
        }
        &:hover {
            @include hover {
                opacity: 0.7;
            }
        }
        > * {
            @include smooth-transition;
        }
        .active {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
    &__menu{
        //@include fts(15,46);
        font-size: 1.5rem;
        gap: 27px;
        letter-spacing: .05em;
        margin-top: 4px;
        @include mq(lp) {
            gap: 15px;
        }
        &:hover {
            @include hover {
                color: $color-gray03;
            }
        }
        a {
            &:hover {
                @include hover {
                    color: $color-base;
                }
            }
        }
    }
    &__action {
        margin: 0 -21px 0 auto;
        @include mq(xl) {
            margin: 0 -11px 0 auto;
        }
        &:hover {
            @include hover {
                color: $color-gray03;
            }
        }
        > * {
            width: 67px;
            height: 40px;
            border-right: 1px solid #dddddd;
            @include mq(xl) {
                width: 45px;
                height: 30px;
            }
            &:last-child {
                border-right: none;
            }
        }
        &-item {
            height: 100%;
            cursor: pointer;
            @include flex(center, center);
            @include smooth-transition;
            &:hover {
                @include hover {
                    .material-symbols-outlined {
                        color: black;
                    }
                }
            }
            .material-symbols-outlined {
                @include font-size(26);
                @include mq(xl) {
                    @include font-size(18);
                }
            }
        }
    }
    &__popup {
        background: $color-gray07;
        padding: 20px;
        position: fixed;
        top: 100px;
        left: 0;
        width: 100%;
        display: none;
        min-height: 100px;
        @include mq(xl) {
            top: 59px;
        }
        @include mq {
            padding: 15px 0;
            min-height: 11.99vw;
        }
        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            @include flex(center, center);
            .material-symbols-outlined {
                @include font-size(20);
                color: $color-gray06;
            }
        }
    }
    &__search {
        max-width: 480px;
        margin: 0 auto;
        position: relative;
        color: $color-base;
        &-btn {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            height: 100%;
            width: 60px;
            @include flex(center, center);
            border: none;
            background: transparent;
            border-radius: 0 30px 30px 0;
            .material-symbols-outlined {
                @include font-size(20);
            }
        }
        .form-control{
            color: #373737;
            @include font-size(16);
            &::placeholder{
                color: #ddd;
            }
        }
    }
    &__lang {
        //gap: 30px;
        @include font-size(15);
        min-height: 60px;
        color: $color-black;
        @include mq {
            min-height: 30px;
            gap: 15px;
        }
        > * {
            &:hover {
                @include hover {
                    color: $color-gray08;
                }
            }
        }
        #google_language_translator{
            display: none!important;
        }
        
        #flags {
            width: auto!important;
            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 30px;
                float: none!important;
                @include mq {
                    gap: 15px;
                }
            }
            &.size18 a {
                position: relative;
                margin: 0!important;
                width: 3em!important;
                height: 1em!important;
                background: none!important;
                
                &[title="Japanese"]:before {
                    content: "日本語";
                }
                &[title="English"] {
                    width: 3.5em!important;
                    &:before {
                        content: "English";
                    }
                }
                &[title="Chinese (Traditional)"]:before {
                    content: "繋体字";
                }
                &[title="Chinese (Simplified)"]:before {
                    content: "簡体字";
                }
            }
        }

    }
    &__nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background: rgba(white, .9);
        color: $color-base;
        padding: 122px 0 0;
        @include fts(13,28);
        font-weight: 300;
        opacity: 0;
        pointer-events: none;
        @include smooth-transition;
        @include mq(tb) {
            padding: 50px 0 0;
        }
        @include mq {
            @include fts(12,29);
            padding: 35px 0 0;
        }
        &.is-show {
            opacity: 1;
            pointer-events: auto;
        }
        .main-header {
            &__logo {
                margin-top: 19px;
                @include mq(xl) {
                    width: 179px;
                }
                @include mq(lg) {
                    margin: 0 auto 15px;
                }
            }
        }
        a {
            &:hover {
                @include hover {
                    opacity: 0.7;
                }
            }
        }
    }
    &__flex {
        margin-bottom: 100px;
        @include mq(lg) {
            flex-direction: column;
        }
    }
    &__ct {
        width: calc(100% - 260px);
        @include mq(xl) {
            width: calc(100% - 194px);
        }
        @include mq(lg) {
            width: 100%;
        }
    }
    &__grid {
        @include mq(lg-min) {
            max-width: 810px;
        }
        @include mq(sm) {
            margin-bottom: 21px;
        }
    }
    &__navMenu {
        border-left: 1px solid $color-gray04;
        padding: 21px 29px 19px;
        @include mq(lg) {
            padding: 15px;
        }
        @include mq(sm) {
            padding: 0;
            border-left: none;
        }
        > * {
            margin-bottom: 35px;
            @include mq {
                margin-bottom: 15px;
            }
            @include mq(sm) {
                margin-bottom: 0;
                border-bottom: 1px solid $color-gray06;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__navLink {
        @include font-size(21);
        font-weight: 500;
        position: relative;
        display: block;
        @include mq {
            @include font-size(16);
        }
        @include mq(sm) {
            padding: 10px 70px 10px 0;
            &.is-active {
                .main-header__ico {
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }
    &__ico {
        @include mq(sm) {
            position: absolute;
            width: 60px;
            height: 60px;
            right: -23px;
            top: 0;
            &::before,
            &::after {
                content: '';
                position: absolute;
                background: $color-base;
                @include v-h-align;
                @include smooth-transition;
            }
            &::before {
                width: 10px;
                height: 2px;
            }
            &::after {
                width: 2px;
                height: 10px;
            }
        }
    }
    &__navSub {
        margin: -2px 0 0;
        padding-bottom: 3px;
        @include mq(sm) {
            display: none;
            margin: -5px 0 0;
            padding-bottom: 4px;
        }
    }
    &__link {
        display: flex;
        margin: -102px 0 0;
        padding: 0 7px;
        @include mq(sm-min) {
            flex-direction: column;
            align-items: flex-end;
        }
        @include mq(sm) {
            margin: 0;
            padding: 0;
            gap: 24px;
        }
        @include mq(xs) {
            gap: 15px;
        }
    }
    &__mobile {
        position: relative;
        cursor: pointer;
        z-index: 12;
        &:hover {
            @include hover {
                opacity: 0.7;
                span {
                    background: black;
                    &::before,
                    &::after {
                        background: black;
                    }
                }
            }
        }
        span{
            position: absolute;
            @include v-align;
            display: block;
            width: 25px;
            height: 2px;
            background: white;
            border-radius: 1px;
            @include smooth-transition;
            @include mq(xl) {
                width: 18px;
            }
            &:before,&:after{
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                background: white;
                content: '';
                border-radius: 1px;
                @include smooth-transition;
            }
            &:before{
                -webkit-transform: translateY(-250%);
                transform: translateY(-250%);
                top: -4px;
                @include mq(xl) {
                    top: -2px;
                }
            }
            &:after{
                -webkit-transform: translateY(250%);
                transform: translateY(250%);
                bottom: -4px;
                @include mq(xl) {
                    bottom: -2px;
                }
            }
        }
        &.is-close {
            span{
                background: transparent;
                &:before,&:after{
                    width: 100%;
                    background: black;
                }
                &:before {
                    -webkit-transform: translateY(0) rotate(45deg);
                    transform: translateY(0) rotate(45deg);
                    top: 2px;
                }
                &:after {
                    -webkit-transform: translateY(0) rotate(-45deg);
                    transform: translateY(0) rotate(-45deg);
                    bottom: -2px;
                }
            }
        }
    }
    &.is-active {
        background: white;
        color: black;
        .main-header {
            &__logo {
                .active {
                    opacity: 1;
                }
                .main {
                    opacity: 0;
                }
            }
            &__mobile {
                span {
                    background: black;
                    &::before,
                    &::after {
                        background: black;
                    }
                }
                &.is-close {
                    span {
                        background: transparent;
                    }
                }
            }
        }
    }
    &.header-dark {
        color: black;
        .main-header {
            &__logo {
                .active {
                    opacity: 1;
                }
                .main {
                    opacity: 0;
                }
            }
            &__mobile {
                span {
                    background: black;
                    &::before,
                    &::after {
                        background: black;
                    }
                }
                &.is-close {
                    span {
                        background: transparent;
                    }
                }
            }
        }
    }
}


.js-locked {
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
    body {
        -webkit-overflow-scrolling: auto;
    }
}

#glt-translate-trigger {
    display: none!important;
}