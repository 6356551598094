@use "vars" as *;

.main-footer{
    background: $color-lightBlack;
    color: white;
    padding: 106px 0 197px;
    @include mq {
        padding: 50px 0;
    }
    a {
        &:hover {
            @include hover {
                opacity: 0.7;
            }
        }
    }
    &__nav {
        @include mq(lg-min) {
            gap: 15px;
            justify-content: space-between;
        }
        @include mq(lg) {
            padding-bottom: 30px;
            margin-bottom: -1em;
        }
    }
    &__menu {
        @include mq(xl-min) {
            min-width: 147px;
        }
        @include mq(lg) {
            width: calc(25% - 12px);
            margin-bottom: 1em;
        }
        @include mq {
            width: calc(25% - 10px);
        }
        @include mq(sm) {
            width: calc(50% - 8px);
        }
        //@include mq(xs) {
        //    width: 100%;
        //}
    }
    &__flex {
        gap: 15px;
        @include mq(md-min) {
            justify-content: space-between;
            align-items: flex-end;
        }
        @include mq {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;
        }
    }
    &__txt {
        margin-bottom: 1px;
        @include mq {
            margin-bottom: 10px;
        }
    }
    &__social {
        gap: 12px;
        @include mq {
            justify-content: center;
        }
    }
    &__logo {
        width: 232px;
        @include mq {
            width: 186px;
        }
    }
    &__bottom {
        border-top: 1px solid #6f6f6f;
        padding-top: 26px;
        @include mq(md-min) {
            justify-content: space-between;
            align-items: center;
        }
        @include mq {
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding-top: 30px;
        }
    }
    &__link {
        gap: 42px;
        @include mq {
            gap: 15px;
        }
    }
    &__copyright {
        @include fts(10,28);
        letter-spacing: .043em;
        @include mq(md-min) {
            margin-top: -3px;
        }
    }
    .txt {
        @include fts(16,46);
        font-weight: 500;
        @include mq {
            @include font-size(14);
            line-height: 2;
        }
        &-sm {
            @include fts(13,28);
            font-weight: 300;
            @include mq {
                @include font-size(11);
            }
        }
    }
}