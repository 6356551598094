@use "vars" as *;


.inner {
	display: block;
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	@include mq {
		width: 100%;
		padding: 0 6.66%;
	}
	&--1500 {
		max-width: 1500px;
	}
}

// flex
.flex {
	display: flex;
	&-wrap {
		flex-wrap: wrap;
	}
}

// justify-content
.justify-content {
	&-start {
		justify-content: flex-start;
	}
	&-center {
		justify-content: center;
	}
	&-end {
		justify-content: flex-end;
	}
	&-between {
		justify-content: space-between;
	}
}

// align-items
.align-items {
	&-start {
		align-items: flex-start;
	}
	&-center {
		align-items: center;
	}
	&-end {
		align-items: flex-end;
	}
}

// grid
.grid {
	display: grid;
	&-column {
		&-2 {
			grid-template-columns: repeat(2, 1fr);
			&-pc {
				@include mq(md-min) {
					grid-template-columns: repeat(2, 1fr);
				}
			}
			&-sm {
				@include mq(sm-min) {
					grid-template-columns: repeat(2, 1fr);
				}
			}
			&-xs {
				@include mq(xs-min) {
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}
		&-3 {
			grid-template-columns: repeat(3, 1fr);
			&-sm {
				@include mq(sm-min) {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		}
		&-4 {
			grid-template-columns: repeat(4, 1fr);
			&-xl {
				@include mq(xl-min) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		}
	}
}

.mv-page {
	position: relative;
	color: white;
	&__img {
		img {
			width: 100%;
			min-height: 200px;
			object-fit: cover;
		}
	}
	&__ttl {
		position: absolute;
		width: 86.68%;
		max-width: 1208px;
		font-family: $font-jp;
		font-size: clamp(2.5rem,3.7vw,6rem);
		letter-spacing: 0.015em;
		line-height: 1;
		top: 47%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include mq {
			top: 50%;
		}
	}
	&__link {
		position: absolute;
		@include flex(0, 0, wrap);
		gap: 10px 42px;
		width: 86.68%;
		max-width: 1200px;
		border-bottom: 1px solid #e6e6e6;
		top: 76.5%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include mq(sm) {
			top: 72%;
			gap: 5px 13px;
			@include font-size(11);

		}
		&--custom{
			@include mq(xs){
				width: 100%;
				padding: 0 15px;
				border-bottom: none;
				&:after{
					content: "";
					position: absolute;
					bottom: 0;
					left: 15px;
					right: 15px;
					height: 1px;
					background: white;
				}
			}
		}
		a {
			position: relative;
			display: inline-block;
			color: white;
			padding: 6px 3px;
			@include mq(sm) {
				padding: 0;
			}
			&:after {
				content: "";
				position: absolute;
				background: white;
				width: 0;
				height: 3px;
				bottom: -1px;
				left: 0;
				transition: 0.3s ease;
				@include mq(sm) {
					height: 2px;
				}
			}
			&.active {
				&:after {
					width: 100%;
				}
			}
			@media (any-hover: hover) {
				&:hover {
					opacity: 1;
					&:after {
						width: 100%;
					}
				}
			}
		}
	}
	.breadcrumbs {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 2.3vw;
		@include mq(sm) {
			text-align: right;
		}
		a {
			color: white;
			&:after {
				color: white;
			}
		}
	}
	&--noimg {
		height: clamp(200px, 26vw, 434px);
		border-bottom: 1px solid #e6e6e6;
		color: #373737;
		margin-bottom: clamp(20px,3vw,50px);
		@include mq(){
			&.mv-page--update{
				height: clamp(180px, 26vw, 434px);
				.mv-page__ttl {
					top: 55%;
				}
				.sp{
					.breadcrumbs a{
						color: #aaa;
					}
				}
			}

		}
		.mv-page__ttl {
			@include mq(md-min) {
				top: 59%;
			}
		}
	}
	&--update {
		@include mq {
			.breadcrumbs {
				a {
					&:after {
						color: #373737;
					}
				}
			}
		}
	}
}




.breadcrumbs {
	@include font-size(13);
	letter-spacing: 0.05em;
	@include mq(sm-min) {
		text-align: right;
	}
	@include mq {
		@include font-size(11);
	}
	a {
		position: relative;
		color: #aaaaaa;
		margin-right: 37px;
		@include mq {
			margin-right: 20px;
		}
		&:after {
			content: "\e5cc";
			position: absolute;
			font-family: 'Material Symbols Outlined';
			color: #373737;
			top: 1px;
			right: -29px;
			pointer-events: none;
			@include mq {
				top: 0;
				right: -18px;
			}
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 0.7;
			}
		}
	}
}

.page-single-ir{
	.breadcrumbs span span:nth-child(2){
		display: none;
	}
}

//form-control
.form-control {
	display: block;
	width: 100%;
	height: 60px;
	background: white;
	color: $color-gray04;
	border-radius: 30px;
	outline: none;
	appearance: none;
	resize: none;
	border: 1px solid $color-gray04;
	@include font-size(13);
	padding: 5px 26px;
	@include mq {
		height: 50px;
		padding: 5px 15px;
	}
	::placeholder {
		color: $color-gray04;
		opacity: 1;
	}
	  
	::-ms-input-placeholder {
		color: $color-gray04;
	}
}

.sec-module{
	padding: 110px 0;
}

.block-heading{
	@include flex(between, 0, wrap);
	&__ttl{
		line-height: 1.6875;
		width: 54%;
		font-weight: 500;
		letter-spacing: 0;
		@include mq(lg){
			width: 100%;
		}
	}
	&__ct{
		width: 45%;
		padding-top: 21px;
		@include mq(lg){
			width: 100%;
		}
	}
}

.heading-common{
	&__lead{
		font-size: clamp(3rem, 5.96vw, 10rem);
		font-family: $font-en;
		text-align: center;
		color: #5b7c66;
		opacity: .2;
		// white-space: nowrap;
		width: 100vw;
		margin-left: calc((1200px - 100vw) / 2);
		letter-spacing: 3px;
		line-height: 1;
		@media screen and (max-width: 1334px) {
			margin-left: -5vw;
		}
		@include mq(){
			letter-spacing: 0;
			margin-left: -6vw;
		}
	}
	.c-ttl__04{
		margin-top: -26px;
		position: relative;
		z-index: 3;
		margin-bottom: 44px;
		@include mq(){
			margin-top: -11px;
		}
	}
	&__link{
		@include flex(center, 0, wrap);
		gap: 10px;
		margin-bottom: clamp(3rem,4.5vw,7.7rem);
		font-family: $font-jp;
		&-item{
			@include mq(sm){
				width: calc(50% - 5px);
			}
			
			>*{
				@include flex(center, center);
				background: #5b7c66;
				color: white;
				font-weight: 500;
				@include fts(16, 30);
				font-weight: 500;
				text-align: center;
				padding: 8px 30px;
				border-radius: 4px;
				height: 100%;
				@include mq{
					padding: 8px 10px;
				}
				@include mq(sm){
					line-height: 1.3;
					@include font-size(14);
				}
			}
		}
	}
	&__brief{
		
		@include mq(sm-min){
			text-align: center;
		}
	}
}
.common-wrap{
	@include flex(between, 0, wrap);
	gap: 40px 0;
	&__ct{
		width: calc(100% - 345px);
		@include mq(lg){
			width: 100%;
		}
	}
	&__sidebar{
		width: 280px;
		@include mq(lg){
			width: 100%;
		}
	}
}
.sidebar-panel{
	&:not(:last-child){
		margin-bottom: 49px;
	}
	&.is-open{
		.sidebar-panel__ttl{
			&::after{
				transform: rotate(0deg);
			}
		}
	}
	&:not(.is-open){
		.sidebar-panel{
			&__ct{
				display: none;
			}
		}
	}
	&.ir{
		padding-top: 20px;
	}
	&__ttl{
		font-size: clamp(1.8rem, 2vw, 2.2rem);
		font-family: $font-jp;
		border-bottom: 1px solid #e6e6e6;
		position: relative;
		padding-bottom: 13px;
		padding-left: 8px;
		margin-bottom: 27px;
		cursor: pointer;
		&::after{
			content: "expand_less";
			font-size: .819em;
			font-family: $font-icon;
			position: absolute;
			right: 15px;
			top: 4px;
			transition: all .3s ease;
			transform: rotate(180deg);
		}
	}
	&__ct{
		padding: 0 9px;
		margin-bottom: 20px;
	}
	&--02{
		margin-bottom: 20px !important;
		background: #f8f8f2;
		border-radius: 8px;
		@include mq(){
			margin-bottom: 20px !important;
		}
		&:nth-child(1){
			margin-top: 39px;
			@include mq(){
				margin-top: 15px;
			}
		}
		.sidebar-panel__ttl{
			border-bottom: 0;
			border-radius: 8px;
			padding: 15px 31px;
			margin-bottom: 0;
			color: #5b7c66;
			@include flex(0,center);
			@include font-size(16);
			@include mq(){
				padding: 15px 20px;
				@include font-size(16);
			}
			&:after{
				display: none;
			}
			.ico {
				margin-right: 7px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				@include flex(center, center);
				position: relative;
				@include mq {
					width: 25px;
					height: 25px;
					border: 1px solid white;
					min-width: 25px;
				}
				.material-symbols-outlined {
					@include font-size(14);
					@include mq {
						@include font-size(11);
					}
				}
				&.gray {
					border-color: $color-gray02;
					.circle-svg {
						.circle {
							&-static {
								stroke: $color-gray02;
							}
						}
					}
					.material-symbols-outlined {
						color: $color-gray03;
					}
				}
				&.gray02 {
					border-color: $color-gray03;
					.circle-svg {
						.circle {
							&-static {
								stroke: $color-gray03;
							}
						}
					}
					.material-symbols-outlined {
						color: $color-gray03;
					}
				}
				.circle-svg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					stroke-width: 1;
					transform: rotate(-90deg);
					@include mq {
						display: none;
					}
					circle {
						fill: transparent;
						transform: translate(-2px,-2px);
					}
					.circle {
						&-static {
							stroke: white;
							stroke-width: 1;
						}
						&-hover {
							stroke: $color-green;
							stroke-width: 0;
							stroke-dasharray: 250;
							stroke-dashoffset: 250;
							animation-timing-function: cubic-bezier(0,0,0,.97);
						}
					}
				}
			}
		}
		.sidebar-categories{
			padding: 11px 32px 25px;
			@include mq(){
				padding: 11px 20px 25px;
			}
			&__item{
				a{
					padding-left: 20px;
					position: relative;
					display: block;
					@include p-before(10px,1px){
						background: #e1dccd;
						top: 8px;
						left: 0;
					}
					@include mq(){
						@include font-size(14);
					}
				}
			}
		}
		.sidebar-panel__ct{
			display: block !important;
		}
	}
	&--open{
		.sidebar-panel__ttl{
			&::after{
				display: none;
			}
		}
		.sidebar-panel__ct{
			display: block !important;
		}
	}
}
.sidebar-categories{
	&__item,li{
		@include font-size(14);
		line-height: 1.4;
		&:not(:last-child){
			margin-bottom: 15px;
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 0.7;
			}
		}
		a{
			@include font-size(14);
			line-height: 1.4;
		}
	}
}
.sidebar-tags{
	@include flex(0, 0, wrap);
	gap: 12px 8px;
	&__item{
		a{
			border-radius: 3px;
			background: #ececdc;
			@include font-size(13);
			line-height: 1.4;
			@include flex(center, center);
			padding: 5px 13px;
			@media (any-hover: hover) {
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}
.sidebar-link {
	&__item {
		&:not(:last-child) {
			margin-bottom: 17px;
		}
		a {
			display: inline-block;
			@include font-size(15);
		}
	}
}
.nums-page{
	.wp-pagenavi{
		@include flex(center, center, wrap);
		gap: 5px 10px;
		@include mq{
			gap: 5px 0;
		}
		>*{
			min-height: 50px;
			min-width: 50px;
			border-radius: 50%;
			@include flex(center,center);
			@include font-size(15);
			border: 0;
			@include mq{
				min-height: 30px;
				min-width: 30px;
			}
			@include mq(xs){
				min-height: 25px;
				min-width: 25px;
			}
			&.current{
				background: #373737;
				color: white;

			}
			@media (any-hover: hover) {
				&:hover {
					background: #373737;
					color: white;
				}
			}
			&.prevtpostslink,&.previouspostslink,&.nextpostslink{
				border: 1px solid #BFBFBF !important;
			}
			&.prevtpostslink,&.previouspostslink{
				&::before{
					content: "chevron_left";
					font-family: $font-icon;
					font-size: .819em;
				}
			}
			&.nextpostslink{
				&::before{
					content: "chevron_right";
					font-family: $font-icon;
					font-size: .819em;
				}
			}
		}
	}
}
.anchor-link{
	@include flex(0, 0, wrap);
	margin-bottom: 115px;
	gap: 20px 0;
	&__item{
		width: 25%;
		border-bottom: 1px solid #E6E6E6;

		@include mq(sm){
			width: 50%;
		}
		.c-btn__01{
			font-size: clamp(1.6rem, 2vw, 2.2rem);
			justify-content: center;
			width: 100%;
			flex-direction: row-reverse;
			padding-bottom: 31px;
			gap: 10px 15px;
			font-weight: 500;
			@include mq{
				padding-bottom: 10px;
			}
			@include mq(sm){
				padding-bottom: 10px;
				position: relative;
				padding-right: 30px;
			}
			.ico{
				transform: rotate(90deg);
				@include mq(sm){
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
	}
}

