/* ====================================================
color
==================================================== */
$color-base: #373737;
$color-lightBlack: #313131;
$color-black: #010101;
$color-gray: #e6e6e6;
$color-gray02: #e4e4e4;
$color-gray03: #aaa;
$color-gray04: #ddd;
$color-gray05: #ebecee;
$color-gray06: #ccc;
$color-gray07: #f7f7f7;
$color-gray08: #999;
$color-lightYellow: #f8f8f2;
$color-green: #5b7c66;


/* ====================================================
Font
==================================================== */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');


$font-base: "IBM Plex Sans JP", sans-serif;
$font-jp: "Shippori Mincho B1", serif;
$font-en: "Marcellus", serif;
$font-icon: 'Material Symbols Outlined';

@mixin font-fm($fmValue, $fwValue) {
	font-family: ($fmValue);
	font-weight: ($fwValue);
}


@mixin font-size($sizeValue) {
	font-size: ($sizeValue / 10) + rem;
}

@function c-max-size($max, $size){
	@return unquote('max(' + $max + ', ' + $size + ')');
}

@function vw($element-size) {
	$vwSize: ($element-size / 1024) * 100;
	@return #{$vwSize}vw;
}


@mixin ob {
	object-fit: cover;
	font-family: 'object-fit: cover';
}


@mixin palt {
	font-feature-settings: "palt" 1;
}




@mixin fts($fts, $lin: 24) {
	$rem: $fts / 10;
	$linS: $lin / $fts;
	font-size: #{$rem}rem;
	line-height: $linS;
}



@mixin ie{
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}



@mixin hover{
	@media (any-hover: hover) {
        @content;
    }

    @media (hover: hover) {
        @content;
    }
}



@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}



/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

$breakpoint-sm: 640px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 990px !default;

$breakpoints: (
		'xs': 'screen and (max-width: 374px)',
		'sm': 'screen and (max-width: 640px)',
		'md': 'screen and (max-width: 834px)',
		'lg': 'screen and (max-width: 990px)',
		'tb': 'screen and (max-width: 1024px)',
		'xl': 'screen and (max-width: 1200px)',
		'lp': 'screen and (max-width: 1440px)',
		'mac': 'screen and (max-width: 1770px)',
		'xs-min': 'screen and (min-width: 375px)',
		'sm-min': 'screen and (min-width: 641px)',
		'md-min': 'screen and (min-width: 835px)',
		'lg-min': 'screen and (min-width: 991px)',
		'tb-min': 'screen and (min-width: 1025px)',
		'xl-min': 'screen and (min-width: 1201px)',
		'lp-min': 'screen and (min-width: 1441px)',
) !default;



/* ====================================================
// flexセット
==================================================== */
@mixin flex($justify:0, $align:0, $wrap:0, $wrap_align:0, $column:0) {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	// justify-content水平方向揃え（第一引数）
	@if $justify == start { // 左揃え
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	} @else if $justify == end { // 右揃え
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	} @else if $justify == center { // 中央揃え
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	} @else if $justify == between { // 両端配置
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	} @else if $justify == around { // 均等配置
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	// align-items垂直方向揃え（第二引数）
	@if $align == start { // 上揃え
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	} @else if $align == end { // 下揃え
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	} @else if $align == center { // 中央揃え
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	} @else if $align == baseline { // ベースライン
		-webkit-align-items: baseline;
		-ms-flex-align: baseline;
		align-items: baseline;
	} @else if $align == stretch { // 高さ揃え
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	// flex-wrap子要素の折り返し設定（第三引数）
	@if $wrap == nowrap { // 折り返ししない
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	} @else if $wrap == wrap { // 上から下
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	} @else if $wrap == reverse { // 下から上
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
	}

	// align-content複数行設定（第四引数）
	@if $wrap_align == start { // 上揃え
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	} @else if $wrap_align == end { // 下揃え
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end;
	} @else if $wrap_align == center { // 中央揃え
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
	} @else if $wrap_align == between { // 両端配置
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	} @else if $wrap_align == around { // 均等配置
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	} @else if $wrap_align == stretch { // 高さ揃え
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	// 縦並び（第五引数）
	@if $column == 1 {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@mixin ft-awesome($no){// content:'\f02b';
	content: #{'"' + $no + '"'};
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	@if $no == arrow-l { // arrow-left
		content: '\f060';
	} @else if $no == arrow-r { // arrow-right
		content: '\f061';
	} @else if $no == c-up { // chevron-up
		content: '\f077';
	} @else if $no == c-down { // chevron-down
		content: '\f078';
	} @else if $no == c-left { // chevron-left = "＜"
		content: '\f053';
	} @else if $no == c-right { // chevron-right = "＞"
		content: '\f054';
	}
}


/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@mixin aspect-ratio($width, $height) {
	position: relative;

	&::before {
		content: '';
		float: left;
		padding-top: ($height / $width) * 100%;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}

	& > :first-child {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}



//marginPC and SP
@for $i from 1 through 100 {
	@include mq(md-min){
		.mbpc-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
	@include mq(){
		.mbsp-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
}
.mb0{
	margin-bottom: 0 !important;
}


//====================================================
//Pseudo-elements setting
//====================================================
@mixin p-before($w, $h) {
	&:before {
		content: "";
		display: inline-block;
		position: absolute;
		width: $w;
		height: $h;
		@content;
	}
}

@mixin p-after($w, $h) {
	&:after {
		content: "";
		display: inline-block;
		position: absolute;
		width: $w;
		height: $h;
		@content;
	}
}


//====================================================
//min(), max(), clamp()  setting
//====================================================
@function c-max-size($max, $size){
	@return unquote('max(' + $max + ', ' + $size + ')');
}

@function c-max-sizeCalc($max, $size, $viewWidth:834){
	$division: ($size / $viewWidth) * 100;
	@return unquote('max(' + $max + ', ' + #{$division}vw + ')');
}



@function c-min-size($min, $size){
	@return unquote('min(' + $min + ', ' + $size + ')');
}

//最小幅が200px、推奨幅が50%、最大幅が1000px
//width: clamp(200px, 50%, 1000px);
//幅は200pxを下回ることはありません。
//中央の値（推奨値）は50%で、ビューポート(1150pxの場合)の幅が400px以上2000px未満の場合のみ機能します。
//幅は1000pxを超えません。
@function c-clamp($min, $recom, $max){
	@return unquote('clamp(' + $min + ', ' + $recom + ', ' + $max + ')');
}



