@use "vars" as *;

.top-mv {
    position: relative;
    z-index: 1;
    padding: 23.4vw 0 5.65vw;
    @include mq {
        padding: 249px 0 40px;
    }
    &__slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &::before {
            position: absolute;
            z-index: 1;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(black, .4);
            pointer-events: none;
        }
        &-item {
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
            }
            &.zoom-in {
              img {
                animation: zoomIn 4.5s ease-out forwards;
              }
            }
        }
        .slick {
            &-list,
            &-track {
                height: 100%;
            }
            &-dots {
                width: 30px;
                top: 22.05%;
                right: 5.35%;
                bottom: auto;
                left: auto;
                @include flex;
                flex-direction: column;
                gap: 10px;
                @include mq {
                    width: 26px;
                    top: 19.6%;
                    right: 4.4%;
                    gap: 8px;
                }
                li {
                    margin: 0;
                    button {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        @include mq {
                            width: 26px;
                            height: 26px;
                        }
                        &::before {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: white;
                            border-radius: 50%;
                            position: absolute;
                            @include v-h-align;
                            border: none;
                            margin: 0;
                            @include mq {
                                width: 7px;
                                height: 7px;
                            }
                        }
                        .circle-svg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            stroke-width: 1;
                            transform: rotate(-90deg);
                            .circle {
                                &-hover {
                                    fill: transparent;
                                    transform: translate(-2px,-2px);
                                    stroke: white;
                                    stroke-width: 0;
                                    stroke-dasharray: 250;
                                    stroke-dashoffset: 250;
                                    animation-timing-function: cubic-bezier(0,0,0,.97);
                                }
                            }
                        }
                    }
                    &.slick-active {
                        button {
                            .circle-svg {
                                circle {
                                    stroke-width: 1;
                                    animation: anim-circle-m 14s forwards;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__inner {
        @include mq(md-min) {
            padding-left: 49px;
        }
    }
    &__ttl {
        @include fts(60,81);
        letter-spacing: -.04em;
        @include mq {
            @include fts(30,40.5);
        }
        small {
            @include font-size(12);
            line-height: 3.75;
            display: block;
            letter-spacing: .045em;
            padding: 0 3px;
            @include mq {
                @include font-size(10);
                line-height: 4.15;
            }
        }
    }
    &__scroll {
        @include font-size(12);
        gap: 10px;
        margin-top: 87px;
        letter-spacing: .04em;
        &-circle {
            width: 13px;
            height: 13px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: white;
                border-radius: 50%;
                animation: transformCircleLoop 2s cubic-bezier(0.52, 0.08, 0.18, 1) infinite;
            }
        }
    }
}

@keyframes zoomIn {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
  }

@keyframes transformCircleLoop {
    0% {
        transform: translateY(-150%) scale(0.8);
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    50% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateY(150%) scale(0.5);
        opacity: 0;
    }
}

.top-notice {
    padding: 109px 0 112px;
    @include mq {
        padding: 38px 0;
    }
	&--01{
		padding-bottom: 40px;
	}
    &__inner {
        position: relative;
    }
    &__top {
        border-bottom: 1px solid $color-gray;
		padding-right: 230px;
        @include mq {
            margin-left: calc((100% - 100vw) / 2);
            margin-right: calc((100% - 100vw) / 2);
            padding: 0 6.66%;
            overflow-x: auto;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__cate {
        @include font-size(15);
        color: #9b9b9b;
        white-space: nowrap;
		flex-wrap: wrap;
        @include mq {
            @include font-size(12);
        }
        > * {
            cursor: pointer;
            position: relative;
            padding: 9px 7px;
            @include smooth-transition;
			margin-right: 33px;
			@include mq {
				margin-right: 24px;
			}
			&:last-of-type{
				margin-right: 0;
			}
            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                transform: scale(0);
                bottom: -1px;
                height: 3px;
                background: black;
                @include smooth-transition;
            }
            &:hover {
                @include hover {
                    color: black;
                    &::before {
                        transform: scale(1);
                    }
                }
            }
            &.is-active {
                color: black;
                &::before {
                    transform: scale(1);
                }
            }
        }
    }
    &__btn {
        @include mq(tb-min) {
            position: absolute;
            right: -3px;
            top: 81px;
        }
		@include mq(xl) {
			top: 70px !important;
		}
        @include mq(tb) {
            margin: 10px -3px 0 0;
			text-align: right !important;
        }
    }
    &__body {
        &-item {
            display: none;
            &.is-active {
                display: block;
            }
        }
		&-empty{
			 @include font-size(14);
		}
    }
    &__list {
		li{
			background: #FFF;
			&:nth-of-type(2n){
				background: #f4f4f4;
			}
		}
        &-link {
            border-radius: 8px;
            padding: 25px 50px;
            gap: 29px;
            @include mq {
                padding: 11px 15px;
                gap: 16px;
            }
            &:hover {
                @include hover {
                    box-shadow: 0 0 13px rgba(black, .04);
                }
            }
        }
        &-img {
            position: relative;
            width: 34.43%;
            &::before {
                display: block;
                content: '';
                padding-bottom: calc(14/19*100%);
                @include mq {
                    padding-bottom: 62%;
                }
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                border-radius: 8px;
            }
        }
        &-ct {
            gap: 7px;
            @include mq {
                gap: 2px;
            }
        }
        &-ttl {
            @include fts(15,24);
            @include mq {
                @include font-size(12);
            }
        }
        &-info {
            flex-wrap: wrap;        
            gap: 10px;
            padding: 0 1px;
            @include mq {
                gap: 5px;
            }
        }
        &-tag {
            @include fts(13, 28);
            border-radius: 3px;
            border: 1px solid $color-gray04;
            padding: 0 9px;
			background: #FFF;
            @include mq {
                @include font-size(10);
                line-height: 1.6;
                padding: 0 6px;
            }
        }
        &-date {
            @include font-size(12);
            letter-spacing: .05em;
            @include mq {
                @include font-size(10);
            }
        }
    }
}

.top-introduction {
    background: #f8f8f2;
    padding: 111px 0 121px;
    @include mq {
        padding: 50px 0;
    }
    &__list {
        gap: 50px 41px;
        @include mq {
            gap: 30px 15px;
        }
        &-link {
            gap: 14px;
            &:hover {
                @include hover {
                    .top-introduction__list-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .c-btn__01 {
                gap: 12px;
            }
        }
        &-img {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            &::before {
                content: '';
                display: block;
                padding-bottom: calc(40/58*100%);
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                @include smooth-transition;
            }
        }
        &-ct{
            padding: 6px 0 0 0;
            @include mq(){
                padding: 0;
            }
            &-ttl{
                display: block;
                span{
                    display: block;
                }
                .c-ft--jp{
                    @include font-size(26);
                    color: #373737;
                    margin-bottom: 8px;
                    @include mq(){
                        @include font-size(18);
                    }
                }
                .c-ft--en{
                    @include font-size(18);
                    color: #5B7C66;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    @include mq(){
                        @include font-size(12);
                    }
                }
            }
        }
    }
}

.top-intrance {
    background: #f8f8f2;
    padding: 0 0 121px;
    overflow: hidden;
    @include mq {
        padding: 0 0 50px;
    }
    &__box {
        position: relative;
        z-index: 1;
        padding: 113px 147px 120px;
        @include mq(xl) {
            padding: 50px;
        }
        @include mq {
            padding: 30px 15px;
        }
    }
    &__img {
        position: absolute;
        z-index: -1;
        @include v-h-align;
        //width: 100%;
        width: 100vw;
        height: 100%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include ob;
            border-radius: 8px;
        }
    }
    &__des {
        @include fts(24,81);
        margin-bottom: 248px;
        @include mq(xl) {
            @include font-size(20);
            margin-bottom: 100px;
        }
        @include mq {
            @include font-size(16);
            margin-bottom: 30px;
        }
    }
    &__list {
        max-width: 536px;
        margin-left: auto;
        gap: 16px 51px;
        @include mq {
            gap: 10px 15px;
        }
        .c-btn__01 {
            gap: 12px;
            @include mq {
                gap: 5px;
            }
        }
    }
}

.top-information {
    padding: 119px 0 109px;
    @include mq {
        padding: 50px 0;
    }
    &__inner {
        position: relative;
    }
    &__ttl {
        width: 250px;
        padding: 38px 15px 38px 0;
        @include mq(tb) {
            width: 170px;
        }
        @include mq {
            width: 100%;
            padding: 0 0 30px;
        }
    }
    &__list {
        width: calc(100% - 250px);
        gap: 36px 30px;
        @include mq(tb) {
            width: calc(100% - 170px);
        }
        @include mq {
            width: 100%;
            gap: 15px;
        }
        &-link {
            gap: 1px;
            &:hover {
                @include hover {
                    .top-information__list-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        &-box {
            position: relative;
        }
        &-img {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            &::before {
                display: block;
                content: '';
                padding-bottom: calc(13/23*100%);
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                @include smooth-transition;
            }
        }
        &-logo {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
        }
        &-ttl {
            @include fts(18,46);
            @include mq {
                @include font-size(15);
            }
        }
    }
    &__btn {
        @include mq(md-min) {
            position: absolute;
            left: 0;
            top: 118px;
        }
        @include mq {
            width: 100%;
            text-align: right;
        }
    }
}

.top-news {
    background: $color-lightYellow;
    padding: 118px 0 121px;
    @include mq {
        padding: 50px 0;
    }
    &__inner {
        @include mq {
            flex-direction: column-reverse;
        }
        > * {
            width: 50%;
            @include mq {
                width: 100%;
            }
        }
    }
    &__article {
        position: relative;
        &-link {
            display: block;
            &:hover {
                @include hover {
                    .top-news__article-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        &-img {
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            &::before {
                display: block;
                content: '';
                padding-bottom: calc(488/600*100%);
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                @include smooth-transition;
            }
        }
        &-ct {
            position: absolute;
            bottom: 43px;
            left: 0;
            right: 0;
            padding: 0 43px;
            @include mq {
                padding: 0 15px;
                bottom: 15px;
            }
        }
    }
    &__link {
        gap: 16px 0;
        padding-right: 13px;
        @include mq(tb) {
            padding-right: 0;
            margin-right: -40px;
        }
        @include mq {
            gap: 15px;
            margin-right: 0;
        }
    }
    &__right {
        @include mq(md-min) {
            max-width: 555px;
            padding: 0 0 0 15px;
            margin-top: -4px;
        }
        @include mq {
            margin-bottom: 30px;
        }
    }
    &__list {
        @include mq(md-min) {
            margin-top: -12px;
        }
        &-link {
            gap: 4px;
            border-bottom: 1px solid $color-gray;
            padding: 25px 0 27px;
            position: relative;
            @include mq {
                padding: 15px 0;
            }
            &:hover {
                @include hover {
                    &::before {
                        width: 100%;
                    }
                }
            }
            &::before {
                content: '';
                position: absolute;
                height: 1px;
                left: 0;
                bottom: -1px;
                background: $color-green;
                width: 0;
                transition:all 0.3s ease-in-out;
            }
        }
        &-ttl {
            line-height: calc(24/15);
        }
        &-date {
            @include font-size(12);
        }
    }
}

.top-society {
    padding: 17.2vw 0 17.9vw;
    background: url(../img/index/society-img.jpg) center / cover;
    @include mq {
        padding: 15vw 0;
    }
    &:not(.ios) {
        background-image: url(../img/index/society-img.jpg);
        background-repeat: no-repeat;
        background-size: 300vh auto;
        background-position: top center;
        background-attachment: fixed;
    }
    &__inner {
        position: relative;
        .c-btn__01 {
            @include mq(sm-min) {
                position: absolute;
                top: 50%;
                right: 0;
            }
        }
    }
}

.top-useful {
    padding: 110px 0 111px;
    overflow: hidden;
    @include mq {
        padding: 50px 0;
    }
    &__inner {
        position: relative;
    }
    &__list {
        margin: 0 -30px;
        @include mq(tb) {
            margin: 0 -15px;
        }
        &-item {
            padding: 0 30px;
            @include mq(tb) {
                padding: 0 15px;
            }
            @include mq(sm) {
                width: 60vw;
            }
        }
        &-link {
            gap: 18px;
            @include mq {
                gap: 10px;
            }
            &:hover {
                @include hover {
                    .top-useful__list-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        &-img {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            &::before {
                display: block;
                content: '';
                padding-bottom: calc(13/18*100%);
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                @include smooth-transition;
            }
        }
        .slick {
            &-list {
                overflow: visible;
            }
            &-track {
                cursor: pointer;
            }
        }
    }
    &__btn {
        @include mq(md-min) {
            position: absolute;
            top: 16px;
            right: 0;
        }
    }
}

.top-article {
    background: $color-lightYellow;
    padding: 121px 0 120px;
    @include mq {
        padding: 50px 0;
    }
    &__list {
        gap: 2.25%;
        //@include mq(xl) {
        //    max-width: 550px;
        //    margin: 0 auto;
        //}
        @include mq {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
        &-link {
            display: block;
            position: relative;
            &:hover {
                @include hover {
                    .top-article__list-img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .c-btn__01 {
                position: absolute;
                bottom: 27px;
                //left: 30px;
                left: 10.7%;
                @include mq(tb) {
                  left: 10px;
                }
                @include mq(sm) {
                    bottom: 10px;
                    left: 5px;
                    letter-spacing: 0;
                }
            }
        }
        &-img {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            &::before {
                display: block;
                content: '';
                padding-bottom: calc(19/14*100%);
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include ob;
                @include smooth-transition;
            }
        }
    }
}