@use "vars" as *;

.sec-facility {
	padding: clamp(40px,7.7vw,129px) 0 clamp(50px,12.1vw,203px);
}

.facility-list {
	&__item {
		@include mq(md-min) {
			@include flex(between, start, wrap);
		}
		&:not(:last-child) {
			margin-bottom: clamp(50px, 6.5vw, 109px);
		}
	}
	.c-ttl__01 {
		width: 100%;
		@include mq(md-min) {
			margin-bottom: 47px;
		}
		@include mq(sm) {
			@include font-size(20);
		}
		@include mq(xs) {
			@include font-size(17);
		}
	}
	&__slider {
		@include mq(md-min) {
			width: 41.6%;
			margin-top: 4px;
		}
		@include mq {
			margin-bottom: 45px;
		}
		.slick-dots {
			bottom: -42px;
			@include mq {
				bottom: -20px;
			}
			li {
				button {
					background: #cccccc;
					border-radius: 50%;
					padding: 6px;
					@include mq {
						padding: 4px;
					}
					&:before {
						display: none;
					}
				}
				&.slick-active {
					button {
						background: #5b7c67;
					}
				}
			}
		}
		&-item {
			position: relative;
			&:before {
				content: "";
				display: block;
				padding-bottom: calc(400 / 500 * 100%);
			}
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 8px;
				top: 0;
				left: 0;
			}
		}
	}
	&__ct {
		@include mq(md-min) {
			width: 52.6%;
		}
		&-ttl {
			font-family: $font-jp;
			font-size: clamp(2rem, 1.7vw, 2.4rem);
			margin-bottom: 26px;
			@include mq {
				margin-bottom: 10px;
			}
		}
		.c-txt__01 {
			margin-bottom: 30px;
			@include mq {
				margin-bottom: 15px;
			}
		}
		&-btn {
			position: relative;
			@include flex(center, center);
			width: 100%;
			max-width: 160px;
			height: 40px;
			background: #5b7c67;
			color: white;
			border-radius: 6px;
			@include font-size(15);
			padding: 5px 10px 5px 35px;
			margin-bottom: 24px;
			transition: 0.3s ease;
			@include mq {
				@include font-size(14);
				margin-bottom: 15px;
			}
			&:before {
				content: "\e89e";
				position: absolute;
				font-family: $font-icon;
				@include font-size(18);
				top: 8px;
				left: 18px;
			}
			@media (any-hover: hover) {
				&:hover {
					opacity: 0.7;
				}
			}
		}
		&-table {
			width: 100%;
			@include font-size(14);
			@include mq(md-min) {
				margin-top: -17px;
			}
			@include mq(xs) {
				@include font-size(12);
			}
			th, td {
				text-align: left;
				padding: 20px;
				@include mq(sm) {
					padding: 18px 0 15px;
				}
			}
			th {
				position: relative;
				width: 110px;
				font-weight: 400;
				@include mq(sm) {
					width: 80px;
				}
				&:before {
					content: "";
					position: absolute;
					width: calc(100% - 10px);
					height: 1px;
					background: #5b7c67;
					bottom: -1px;
					left: 0;
				}
			}
			td {
				width: calc(100% - 110px);
				border-bottom: 1px solid #e1dccd;
				@include mq(sm) {
					width: calc(100% - 80px);
				}
			}
		}
	}
}