@use "vars" as *;
.sec-initiatives{
	padding: clamp(5rem, 9.5vw, 12.2rem) 0 0;
	&__img{
		margin-bottom: 64px;
		@include mq{
			margin-bottom: 30px;
		}
	} 
	.materality-block{
		margin-bottom: 115px;
		@include mq{
			margin-bottom: 50px;
		}
	}
}
.initiatives-exem{
	background: #f8f8f2;
	padding: clamp(5rem, 7.5vw, 11.3rem) 0 clamp(5rem, 9.5vw, 14rem);
	&__ttl{
		.c-ft--{
			&en{
				display: block;
				color: $color-green;
				@include font-size(16);
				margin-left: 0;
				margin-bottom: 24px;
				font-weight: 400;
				@include mq{
					margin-bottom: 10px;
					@include font-size(14);
				}
			}
		}
		@include mq(md-min){
			padding-bottom: 33px;
			margin-bottom: 60px;
		}
		
	}
}
.initiatives-block{
	&:not(:last-child){
		margin-bottom: 113px;
		@include mq{
			margin-bottom: 50px;
		}
	}
	&__heading{
		@include flex(between, center, wrap);
		padding: 10px 10px 10px 39px;
		background: #ececdc;
		border-radius: 8px;
		margin-bottom: 50px;
		gap: 10px;
		@include mq{
			margin-bottom: 25px;
			padding: 10px;
		}
		@include mq(sm){
			padding: 10px 20px 15px;
		}
		&-ttl{
			font-family: $font-jp;
			font-weight: 500;
			color: $color-green;
			@include mq(sm){
				width: 100%;
			}
		}
	}
	&__logo{
		@include flex(0, 0, wrap);
		gap: 16px;
		@include mq(sm){
			width: 80%;
			gap: 10px;
		}
		&-item{
			width: 60px;
			@include mq(sm){
				width: calc(25% - 7.5px);
			}
		}
	}
	&__wp{
		@include flex(between, 0, wrap);
		gap: 30px 0;
		&:not(:last-child){
			border-bottom: 1px solid #e1dccd;
			padding-bottom: 63px;
			margin-bottom: 60px;
			@include mq{
				padding-bottom: 30px;
				margin-bottom: 20px;
			}
		}
		&-ct{
			width: 50%;
			margin-top: -9px;
			@include mq{
				width: 100%;
				margin-top: 0;
			}
		}
		&-img{
			width: 40%;
			@include mq{
				width: 100%;
			}
			img{
				border-radius: 8px;
				@include mq(sm){
					object-fit: cover;
					width: 100%;
					height: 50vw;
				}
			}
		}
		&-ttl{
			@include font-size(26);
			font-family: $font-jp;
			font-weight: 500;
			
			margin-bottom: 24px;
			@include mq(xl){
				@include font-size(20);
				line-height: 1.4;
				margin-bottom: 15px;
			}
		}
		&-brief{
		    margin-bottom: 43px;
			@include mq{
				margin-bottom: 20px;
			}
		}
	}
	&__detail{
		background: #f3f3ec;
		border-radius: 8px;
		padding: 19px 30px 14px;
		@include mq{
			padding: 15px;
		}
		&-ttl{
			margin-bottom: 13px;
			span{
				display: inline-block;
				background: #5b7c66;
				border-radius: 3px;
				color: white;
				font-weight: 500;
				@include font-size(13);
				padding: 5px 11px 2px;
			}
		}
	}
	&__note{
		li{
			position: relative;
			padding-left: 20px;
			&:not(:last-child){
			    margin-bottom: 7px;
			}
			&::before{
				content: "・";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}
