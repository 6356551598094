@use "vars" as *;

#page-company-group {
	background: #f8f8f2;
}

.sec-group {
	padding: clamp(2rem, 6.7vw, 11rem) 0 clamp(5rem, 9.6vw, 16rem);

	&__brief {
		max-width: 920px;
		margin: 0 auto 60px;
		@include mq{
			margin-bottom: 30px;
		}
	}
}

.group-table {
	overflow-x: auto;
	overflow-y: hidden;
	table {
		width: 100%;
		background: white;
		//@include mq{
		//	min-width: 840px;
		//}
		td,
		th {
			border: 1px solid #e6e6e6;
		}

		th {
			@include font-size(17);
			font-family: $font-jp;
			background: #ececdc;
			padding: 15px;
			text-align: center;
			font-weight: 400;
			@include mq{
				padding: 10px;
				@include font-size(14);
			}
			&:first-child{
				width: 43.5%;
			}
		}
		td{
			padding: 19px 20px 15px;
			@include mq{
				padding: 10px;
			}
			a{
				text-decoration: underline;
			}
			&.child{
				
				@include mq(md-min){
					padding-left: 50px;
				}
			}
		}
	}
}
