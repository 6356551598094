@use "vars" as *;

.sec-irpolicy{
	// padding: 50px 0 153px;
	padding: clamp(2rem,6vw,5rem) 0 clamp(6rem, 10vw, 15.3rem);
	// @include mq(){
	// 	padding: 50px 0 60px;
	// }
}

.irpolicy-list{
	&__item{
		&:not(:last-child){
			margin-bottom: 48px;
			@include mq(){
				margin-bottom: 35px;
			}
		}
	}
	&__heading{
		color: #373737;
		font-weight: 500;
		font-family: $font-jp;
		padding-bottom: 16px;
		line-height: 1.3;
		margin-bottom: 26px;
		border-bottom: 1px solid #E6E6E6;
		@include font-size(22);
		@include mq(){
			margin-bottom: 20px;
			padding-bottom: 10px;
			@include font-size(20);
		}
	}
}