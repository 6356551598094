@use "vars" as *;
.sec-solicitation{
	padding: 46px 0 140px;
	@include mq{
		padding: 20px 0 50px;
	}
	&__ttl{
		text-align: center;	
		margin-bottom: 106px;
		font-weight: 500;
		@include mq{
			margin-bottom: 30px;
		}
	}
	.inner{
		max-width: 1038px;
	}
}
.solicitation-list{
	&__item{
		&:not(:last-child){
		    margin-bottom: 66px;
			@include mq{
				margin-bottom: 30px;
			}
		}
	}
	&__ttl{
		@include font-size(21);
		font-weight: 600;
		font-family: $font-jp;
		padding-bottom: 15px;
		margin-bottom: 32px;
		position: relative;
		line-height: 1.5;
		@include mq{
			padding-bottom: 10px;
			margin-bottom: 20px;
			@include font-size(16);
		}
		@include p-before(15px, 1px){
			background: #5b7c66;
			left: 0;
			bottom: 0;
		}
		@include p-after(calc(100% - 20px), 1px){
			background: #e1dccd;
			right: 0;
			bottom: 0;
		}
	}
	&__number{
		counter-reset: number;
		padding-left: 19px;
		@include mq{
			padding-left: 15px;
		}
		li{
			position: relative;
		    padding-left: 20px;
			&::before{
				counter-increment: number;
				content: counter(number)".";
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
}
