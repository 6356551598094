@use "vars" as *;

#page-ir {
	background: #f8f8f2;
}

.sec-ir-news{
	padding: clamp(5rem,6.1vw,10.4rem) 0 clamp(4rem,7.2vw,12.2rem);
	.c-ttl__01{
		margin-bottom: 10px;
	}
}



.ir-news{
	margin-bottom: 43px;
	@include mq(){
		margin-bottom: 30px;
	}
	&--link{
		@include flex(end,0,wrap);
		margin-bottom: 74px;
		@include mq(){
			margin-bottom: 45px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		.c-btn__01{
			.c-ft--jp{
				font-family: $font-base;
			}
		}
	}
	&__item{
		border-bottom: 1px solid #e6e6e6;
		a{
			@include flex(0,0,wrap);
			padding: 33px 0;
			@include smooth-transition;
			position: relative;
			@include mq(){
				padding: 20px 0;
			}
			time{
				display: block;
				width: 100px;
				text-align: center;
				font-family: $font-en;
				@include font-size(14);
				letter-spacing: 0.5px;
				@include mq(){
					width: 80px;
				}
			}
			p{
				width: calc(100% - 100px);
				padding: 0 0 0 5%;
				@include mq(){
					width: calc(100% - 80px);
					padding: 0 0 0 15px;
				}
			}
			&:after{
				content: "";
				position: absolute;
				height: 1px;
				left: 0;
				bottom: -1px;
				background: #5b7c66;
				width: 0;
				transition: all .3s ease-in-out;
			}
			&:hover {
				@include hover {
					opacity: 1;
					&:after{
						width: 100%;
					}
				}
			}
		}
	}
	&--column2{

		@include mq(sm-min){
			@include flex(between,0,wrap);
			.ir-news__item{
				width: calc(50% - 20px);
			}
		}
	}
}

.sec-ir-menu{
	background: white;
	padding: clamp(5rem,6.3vw,10.7rem) 0 clamp(4rem,15.77vw,26.5rem);
	@include mq(sm){
		padding-bottom: 4rem;
	}
	.c-ttl__01{
		margin-bottom: 29px;
		border-bottom: none;
		&:after{
			display: none;
		}
	}
}











.ir-menu{
	@include flex(0,0,wrap);
	margin: 0 -2.5%;
	&__item{
		width: 50%;
		padding: 0 2.5%;
		margin-bottom: 50px;
		@include mq(sm){
			width: 100%;
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&.none{
			display: none;
		}
	}
	&__img{
		position: relative;
		border-radius: 10px;
		overflow: hidden;
		display: block;
		margin-bottom: 39px;
		@include aspect-ratio(570,240);
		@include mq(){
			margin-bottom: 20px;
		}
		span{
			display: block;
			@include font-size(22);
			color: white;
			font-family: $font-jp;
			position: absolute;
			bottom: 24px;
			left: 29px;
			right: 15px;
			@include mq(){
				@include font-size(18);
				bottom: 15px;
				left: 15px;
			}
		}
	}
	&__list{
		@include flex(0,0,wrap);
		margin: 0 -5px;
		li{
			width: 50%;
			padding: 0 5px;
			margin-bottom: 20px;
			@include mq(){
				margin-bottom: 15px;
			}
			@include mq(xs){
				width: 100%;
			}
			@include mq(md-min){
				.c-btn__01{
					@include font-size(16);
				}
			}
			.c-btn__01{
				.c-ft--jp{
					font-family: $font-base;
				}
			}
		}
	}
}


