@use "vars" as *;
#page-contact{
	background: #F8F8F2;
}
.sec-contact-page{
	padding: clamp(20px,6.5vw,147px) 0 clamp(50px,6.5vw,147px);
}

.contact-note{
	text-align: center;
	line-height: 2.13333;
	margin-bottom: 110px;
	@include mq(){
		margin-bottom: 45px;
	}
	@include mq(sm){
		p{
			text-align: left;
		}
	}
	&__ttl{
		display: block;
		@include font-size(22);
		font-family: $font-jp;
		line-height: 1.4;
		font-weight: 500;
		margin-bottom: 72px;
		@include mq(){
			@include font-size(20);
			line-height: 1.6;
			margin-bottom: 40px;
		}
	}
	&__steps{
		@include flex(center,center,wrap);
		position: relative;
		margin-bottom: 58px;
		@include mq(){
			margin-bottom: 38px;
		}
		li{
			position: relative;
			padding: 61px 77px 0;
			color: $color-gray03;
			@include font-size(17);
			font-weight: 500;
			@include mq(){
				padding: 50px 30px 0;
				@include font-size(15);
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
			&:before{
				content: "";
				width: 54px;
				height: 54px;
				border-radius: 50%;
				border: 1px solid $color-gray03;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				background: white;
				z-index: 1;
				@include mq(){
					width: 40px;
					height: 40px;
				}
			}

			&:after{
				content: "";
				height: 1px;
				width: 100%;
				position: absolute;
				left: 50%;
				top: 27px;
				background: $color-gray03;
				@include mq(){
					top: 22px;
				}
			}
			span{
				position: absolute;
				top: 3px;
				left: 0;
				right: 0;
				font-family: $font-en;
				@include font-size(24);
				@include smooth-transition;
				z-index: 1;
				@include mq(){
					@include font-size(18);
				}
			}
			&.active{
				color: #373737;
				span{
					color: white;
				}
				&:before{
					background: #5B7C66;
					border: 1px solid #5B7C66;
				}
			}
		}
	}
}

.contact-form{
	@include font-size(16);
	color: #373737;
	max-width: 1100px;
	margin: 0 auto 90px;
	@include mq(){
		@include font-size(15);
		margin-bottom: 30px;
	}
	&__note{
		text-align: right;
		@include font-size(15);
		margin-bottom: 14px;
	}
	sub{
		color: #E55A6E;
	}
	.form-control{
		background: white;
		border-radius: 4px;
		border: none;

		@include font-size(16);
		margin-bottom: 12px;
		color: #373737;
		@include mq(md-min){
			height: 54px;
			padding: 5px 20px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		&::-webkit-input-placeholder {
			color: #C1C1C1;
		}
		&:-moz-placeholder {
			color: #C1C1C1;
		}
		&::-moz-placeholder {
			color: #C1C1C1;
		}
		&:-ms-input-placeholder {
			color: #C1C1C1;
		}
		&--fill{

		}
	}
	select:required:invalid {
		color: #C1C1C1;
	}
	select.form-control{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: #fff url(../img/common/arrow-dow.png) no-repeat 95%;
		background-size: 14px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		option {
			color: black;
		}
	}

	textarea.form-control{
		height: 315px;
		padding-top: 7px;
		@include mq(){
			height: 200px;
		}
	}

	&__item{
		margin-bottom: 40px;
		@include mq(){
			margin-bottom: 30px;
		}
		dt{
			margin-bottom: 15px;
			@include mq(){
				margin-bottom: 10px;
			}
		}
		dd{

		}
		&.h-adr{
			.wpcf7-form-control-wrap {
				display: block;
				margin-bottom: 25px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}


	&__tel{
		max-width: 290px;
		@include mq(sm){
			max-width: 100%;
		}
	}
	&__select{
		max-width: 290px;
		position: relative;
		@include mq(sm){
			max-width: 100%;
		}
	}
	&__column{
		@include flex(between,0,wrap);
		max-width: 976px;
		&-item{
			width: calc(50% - 10px);
			@include mq(){
				width: 100%;
				margin-bottom: 15px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	&__code{
		@include flex(start,0,wrap);
		margin-bottom: 23px;
		&-ttl{
			@include font-size(18);
			display: block;
			padding: 18px 0 0 0;
		}
		&-input{
			width: 150px;
			margin: 0 25px 0 15px;
			@include mq(xs){
				width: 115px;
				margin: 0 15px;
			}
		}
		&-btn{
			display: block;
			border: none;
			outline: none;
			color: white;
			@include smooth-transition;
			border-radius: 40px;
			background: $color-green;
			cursor: pointer;
			line-height: 1;
			margin: 15px 0 0 0;
			height: 28px;
			padding: 2px 21px 0;
			&:hover {
				@include hover {
					opacity: 0.7;
				}
			}
		}
	}
	&__check{
		@include flex(center,center,wrap);
		position: relative;
		padding: 90px 0 60px;
		@include mq(){
			padding: 30px 0;
		}
		&:after{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			@include h-align;
			width: 50000px;
			background: #F3F3EC;
			border-top: 1px solid #E1DCCD;
			border-bottom: 1px solid #E1DCCD;
		}
		&-input{
			position: relative;
			z-index: 2;
			input{
				position: absolute;
				top:  0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				opacity: 0;
				cursor: pointer;
				&[type="checkbox"]{
					& + span{
						&:after{
							background-color: transparent;
							top: 16px;
							left: 8px;
							width: 10px;
							height: 4px;
							border-style: solid;
							border-color: #fff;
							border-width: 0 0 3px 3px;
							-o-border-image: none;
							border-image: none;
							transform: rotate(-45deg) scale(0);
							@include mq(){
								top: 17px;
							}
						}
					}
					&:checked{
						& + span{
							color: #373737;
							&:after{
								content: '';
								transform: rotate(-45deg) scale(1);
								transition: transform 200ms ease-out;
							}
							&:before{
								animation: borderscale 200ms ease-in;
								background: #373737;
							}
						}
					}
				}
				&[type="radio"]{
					+ span{
						&:after,&:before{
							border-radius: 50%;
						}
					}
					&:checked{
						& + span{
							&:before{
								animation: borderscale 300ms ease-in;
							}
							&:after{
								transform: scale(1);
							}
						}
					}
				}
			}
			.wpcf7-list-item-label{
				display: block;
				position: relative;
				padding: 10px 0 10px 47px;
				vertical-align: middle;
				user-select: none;
				cursor: pointer;
				@include mq(){
					padding: 10px 0 10px 36px;
				}
				&:before{
					content: "";
					position: absolute;
					top: 7px;
					left: 0;
					width: 26px;
					height: 26px;
					background: #F9F9F9;
					border: 1px solid #C1C1C1;
					text-align: center;
					transition: all 0.3s ease;
					border-radius: 3px;
					@include mq(){
						width: 24px;
						height: 24px;
						top: 8px;
					}
				}
				&:after{
					content: "";
					background-color: #333;
					position: absolute;
					top: 16px;
					left: 6px;
					width: 14px;
					height: 14px;
					transform: scale(0);
					transform-origin: 50%;
					transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
				}
			}
		}
		.js-bar{
			padding: 0 7%;
			@include mq(){
				padding: 0 20px;
			}
		}
		.slimScrollDiv,.js-bar{
			height: 580px !important;
			@include mq(){
				height: 250px !important;
			}
		}
		.slimScrollBar{
			width: 10px !important;
			opacity: 1 !important;
			background: #e6e6e6 !important;
			right: 2.7% !important;
			display: block !important;
		}
		&-input{
			@include font-size(18);
			color: #C1C1C1;
			@include mq(){
				@include font-size(16);
			}
		}
	}
	&__priverty{
		position: relative;
		background: white;
		z-index: 1;
		@include fts(15,31);
		padding: 54px 0 0;
		margin-bottom: 47px;
		@include mq(){
			padding-top: 25px;
			@include font-size(14);
			line-height: 1.8;
			margin-bottom: 30px;
		}
		&-heading{
			display: block;
			text-align: center;
			line-height: 1.4;
			margin-bottom: 46px;
			@include mq(){
				margin-bottom: 30px;
			}
			span{
				display: block;
			}
			.c-ft--en{
				font-size: clamp(3rem,2.9vw,5rem);
				letter-spacing: 1px;
				margin-bottom: 8px;
				color: #E6E6E6;
			}
			.c-ft--base{
				font-family: $font-base;
				@include font-size(22);
				font-weight: 500;
				@include mq(){
					@include font-size(18);
				}
			}
		}
		&-ttl{
			display: block;
			@include font-size(20);
			font-weight: 500;
			letter-spacing: 1px;
			color: #5b7c66;
			border-bottom: 1px solid #5b7c66;
			padding: 0 0 7px;
			margin-bottom: 13px;
			@include mq(){
				@include font-size(16);
				padding: 0 0 5px;
				margin-bottom: 10px;
			}
		}
		p{
			margin-bottom: 31px;
			@include mq(){
				margin-bottom: 15px;
			}
		}
		ul{
			counter-reset: section;
			margin-bottom: 31px;
			li{
				position: relative;
				padding: 0 0 0 18px;
				margin-bottom: 10px;
				&:before{
					counter-increment: section;
					content: counters(section, ".") ".";
					position: absolute;
					top: 0;
					left: 0;
					font-weight: bold;
				}
				.contact-form__priverty-note{
					padding-left: 0;
				}
			}
			&.note-02{
				li{
					margin-bottom: 25px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
		.counter-first{
			li{
				margin-bottom: 0;
				&:before{
					font-weight: 400;
				}
			}
		}
		a{
			color: #373737;
		}
		&-note{
			padding: 0 0 0 18px;
			margin-bottom: 31px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&__sub{
		@include flex(center,center,wrap);
		padding: clamp(4.5rem, 4.9vw,8.3rem) 0 clamp(5rem, 8.3vw, 14rem);
		.c-btn__02{
			margin: 0 25px 15px;
		}
		&--thanks{
			padding-top: 0;
		}
	}
}

.mb0{
	margin-bottom: 0 !important;
}

.mw_wp_form_confirm{
	.contact-form{
		margin-bottom: 38px;
		&__item{
			border-bottom: 1px solid #E6E6E6;
			@include flex(0,center,wrap);
			margin-bottom: 0;
			padding: 36px 0;
			@include mq(){
				padding: 20px 0;
			}
			&:nth-child(2){
				padding-top: 0;
			}
			dt{
				width: 300px;
				margin-bottom: 0;
				@include mq(){
					width: 100%;
					margin-bottom: 10px;
				}
			}
			dd{
				width: calc(100% - 300px);
				@include mq(){
					width: 100%;
				}
			}

			.contact-form__code-btn{
				display: none;
			}
			.contact-form__code-ttl{
				padding-top: 0;
			}
		}
		&__check{
			display: none;
		}
	}
}

.wpcf7-not-valid-tip{
	@include font-size(14);
}



