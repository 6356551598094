@use "vars" as *;
.sec-company-page{
	padding: clamp(2rem,3.21vw,5.4rem) 0 clamp(5rem,9.464vw,15.9rem);
	.top-introduction{
		&__list{
			@include mq(md-min){
				gap: 44px 41px;
				margin-bottom: 120px;
				&-img{
					&:before{
						padding-bottom: 65.5%;
					}
				}
			}
			@include mq(){
				margin-bottom: 50px;
			}
		}
	}

}
.company-links{
	@include flex(between,0,wrap);
	&__item{
		width: calc(50% - 20px);
		border-bottom: 1px solid #E6E6E6;
		&:nth-child(1),&:nth-child(2){
			border-top: 1px solid #E6E6E6;
		}
		@include mq(sm){
			width: 100%;
			&:nth-child(2){
				border-top: none;
			}
		}
		.c-btn__01{
			position: relative;
			width: 100%;
			padding: 32px 0;
			@include mq(sm){
				padding: 15px 0;
			}
			.ico{
				position: absolute;
				@include v-align;
				right: 27px;
				@include mq(sm){
					right: 0;
				}
			}
			.c-ft--jp{
				@include font-size(20);
			}
		}
	}
}

#page-company-info{
	background: #f8f8f2;
}