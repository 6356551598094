@use "vars" as *;


.c-fc{ //font-color
	&--white{
		color: white !important;
	}
	&--black{
		color: black !important;
	}
}



.c-ft{//font-family
	&--en{
		font-family: $font-en;
	}
	&--jp{
		font-family: $font-jp;
	}
}



.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}



.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-al{
	&--c{
		text-align: center !important;
	}
	
	&--r{
		text-align: right !important
	}
	
	&--l{
		text-align: left !important;
	}
}



// Image position control
.c-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}










/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01 {
        @include fts(15,28);
        @include mq {
            @include font-size(12);
        }
	}
	&__02{
		@include fts(16, 28);
		@include mq(){
			@include font-size(12);
		}
	}
	&__03{
		@include fts(18, 36);
		@include mq{
			@include font-size(14);
		}
	}
}




//ttl
.c-ttl{
	&__01 {
        // @include fts(32,48);
		font-size: clamp(2rem, 2vw, 3.2rem);
		line-height: 1.5;
        font-family: $font-jp;
        @include mq {
            // @include fts(21,33);
			line-height: 1.57;
        }
        &--boderBottom {
            border-bottom: 1px solid $color-gray;
            padding-bottom: 12px;
        }
        &--sm {
            @include fts(21,46);
        }
		
		.c-ft--en {
			display: inline-block;
			color: #9b9b9b;
			font-weight: 400;
			@include font-size(18);
			letter-spacing: 0.04em;
			margin-left: 4px;
			@include mq {
				@include font-size(15);
			}
			@include mq(xs) {
				@include font-size(14);
			}
		}
		&--line{
			border-bottom: 1px solid #e1dccd;
			position: relative;
			font-weight: 500;
			padding-bottom: 22px;
			margin-bottom: 53px;
			
			@include mq{
			    padding-bottom: 10px;
    			margin-bottom: 25px;
			}
			@include p-after(15px, auto){
				border-bottom: 1px solid #5b7c66;
				left: 0;
				bottom: -1px;
			}
			
		}
		&--linetop {
			position: relative;
			border-top: 1px solid #e1dccd;
			font-weight: 500;
			padding-top: 25px;
			margin-bottom: 48px;
			@include mq{
				padding-top: 10px;
				margin-bottom: 25px;
			}
			&:after {
				content: "";
				position: absolute;
				width: 15px;
				height: 1px;
				background: #5b7c66;
				top: -1px;
				left: 0;
			}
		}
		&--date {
			position: relative;
			@include mq(md-min) {
				padding-left: 220px;
			}
			@include mq {
				padding-top: 40px;
			}
			.date {
				position: absolute;
				display: inline-block;
				border-radius: 3px;
				border: 1px solid #e1dccd;
				font-family: $font-base;
				@include font-size(17);
				letter-spacing: 0.02em;
				padding: 5px 20px;
				top: 10px;
				left: 0;
				@include mq {
					@include font-size(14);
					padding: 5px 10px 3px;
					top: 0;
				}
			}
		}
		&--md{
			font-size: clamp(2.0rem, 2vw, 3.0rem);
		}
	}
    &__02 {
        padding-left: 50px;
        position: relative;
        font-family: $font-jp;
        @include font-size(14);
        padding-left: 65px;
        letter-spacing: .04em;
        @include mq {
            padding-left: 30px;
        }
        &::before {
            position: absolute;
            content: '';
            height: 1px;
            width: 50px;
            left: 3px;        
            top: 10px;
            background: $color-base;
            @include mq {
                width: 20px;
            }
        }
        &--white {
            color: white;
            &::before {
                background: white;
            }
        }
    }
    &__03 {
        font-family: $font-en;
        @include font-size(62);
        line-height: 1.22;
        letter-spacing: .044em;
        @include mq(xl) {
            @include font-size(46);
        }
        @include mq {
            @include font-size(30);
            letter-spacing: 0;
        }
        @include mq(xs) {
            @include font-size(26);
        }
        small {
            display: block;
            @include fts(42,60);
            letter-spacing: .0325em;
            @include mq(xl) {
                @include font-size(31);
            }
            @include mq {
                @include font-size(20);
            }
            @include mq(xs) {
                @include font-size(18);
            }
        }
        .txt-md {
            @include font-size(52);
            @include mq(xl) {
                @include font-size(38);
            }
            @include mq {
                @include font-size(24);
            }
            @include mq(xs) {
                @include font-size(20);
            }
        }
    }
	&__04{
		font-size: clamp(2.4rem, 4vw, 3.4rem);
		font-weight: 500;
		line-height: 1.4;
		font-family: $font-jp;
		text-align: center;
		@include mq(xs){
			@include font-size(21);
		}
		.--sm{
			font-size: 70.6%;
			display: block;
			margin-bottom: 5px;
		}
	}
	&__05{
		line-height: 1.2;
		.c-ft--{
			&en{
				font-size: clamp(3.5rem, 4.6vw, 7.2rem);
				display: block;
				color: #5b7c66;
				opacity: .2;
			}
			&jp{
				font-size: clamp(1.6rem, 2vw, 1.8rem);
			}
		}
	}
	&__06{
		font-family: $font-jp;
		font-weight: 500;
		text-align: center;
	    margin-bottom: 44px;
		@include font-size(34);
		@include mq(xl){
			@include font-size(30);
		}
		@include mq(){
			@include font-size(24);
		}
		span{
			color: #e4e9e6;
			line-height: 1;
			display: block;
			font-family: $font-en;
			list-style: 1;
		    margin-bottom: -26px;
			letter-spacing: 2.5px;
			@include font-size(100);
			font-size: clamp(3rem, 6vw, 10rem);
			@include mq(xl){
				margin-bottom: -20px;
				// @include font-size(70);
			}
			@include mq(){
				margin-bottom: -10px;
			}
			@include mq(sm){
				margin-bottom: 0;
			}
		}
		small{
			margin-bottom: 5px;
			display: block;
			color: #373737;
			@include font-size(24);
			@include mq(xl){
				@include font-size(20);
			}
			@include mq(){
				margin-bottom: 0;
				@include font-size(18);
			}
		}
	}
	&__07{
		span{
			display: block;
		}
		.c-ft{
			&--en{
				color: #5b7c66;
				font-family: $font-en;
				margin-bottom: 23px;
				letter-spacing: 0.5px;
				@include font-size(16);
				@include mq(tb){
					margin-bottom: 15px;
				}
				@include mq(){
					letter-spacing: 0;
					margin-bottom: 6px;
					@include font-size(14);
				}
			}
			&--jp{
				color: #373737;
				font-weight: 500;
				line-height: 1.5217;
				font-family: $font-jp;
				@include font-size(46);
				font-size: clamp(2.4rem, 2.8vw, 4.6rem);
				@include mq(){
					line-height: 1.3;
				}
				span{
					font-size: clamp(2rem, 2vw, 3.2rem);
				}	
			}
		}
		&--border{
			border-bottom: 1px solid #e1dccd;
			position: relative;
			padding-bottom: 33px;
			margin-bottom: 34px;
			@include mq{
				padding-bottom: 10px;
				margin-bottom: 25px;
			}
			@include p-after(15px, auto){
				border-bottom: 1px solid #5b7c66;
				left: 0;
				bottom: -1px;
			}
		}
	}
}

//btn
.c-btn{
	&__01 {
        display: inline-flex;
        align-items: center;
        gap: 9px;
        @include font-size(15);
        @include mq {
            @include font-size(12);
        }
        //@include mq(xs) {
        //    @include font-size(10);
        //}
        .txt-lg {
            //@include fts(21,21);
            font-size: clamp(1.1rem, 1.75vw, 2.1rem);
            @include mq {
                @include font-size(15);
            }
            @include mq(xs) {
                @include font-size(11);
            }
        }
        .ico {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include flex(center, center);
            position: relative;
            @include mq {
                width: 25px;
                height: 25px;
                border: 1px solid white;
				min-width: 25px;
            }
            .material-symbols-outlined {
                @include font-size(14);
                @include mq {
                    @include font-size(11);
                }
            }
            &.gray {
                border-color: $color-gray02;
                .circle-svg {
                    .circle {
                        &-static {
                            stroke: $color-gray02;
                        }
                    }
                }
                .material-symbols-outlined {
                    color: $color-gray03;
                }
            }
            &.gray02 {
                border-color: $color-gray03;
                .circle-svg {
                    .circle {
                        &-static {
                            stroke: $color-gray03;
                        }
                    }
                }
                .material-symbols-outlined {
                    color: $color-gray03;
                }
            }
            .circle-svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                stroke-width: 1;
                transform: rotate(-90deg);
                @include mq {
                    display: none;
                }
                circle {
                    fill: transparent;
                    transform: translate(-2px,-2px);
                }
                .circle {
                    &-static {
                        stroke: white;
                        stroke-width: 1;
                    }
                    &-hover {
                        stroke: $color-green;
                        stroke-width: 0;
                        stroke-dasharray: 250;
                        stroke-dashoffset: 250;
                        animation-timing-function: cubic-bezier(0,0,0,.97);
                    }
                }
            }
        }
        &:hover {
            @include hover {
                color: $color-green;
                .ico {
                    border-color: $color-green;
                    .material-symbols-outlined {
                        animation: anim-arrow .4s forwards;
                    }
                    &.gray {
                        border-color: $color-green;
                        .material-symbols-outlined {
                            color: $color-green;
                        }
                    }
                    &.gray02 {
                        border-color: $color-green;
                        .material-symbols-outlined {
                            color: $color-green;
                        }
                    }
                    .circle-svg {
                        circle {
                            stroke-width: 1;
                            animation: anim-circle-m .5s forwards;
                        }
                    }
                }
            }
        }
		&--sm{
			.txt-lg{
				@include mq(md-min){
					font-size: clamp(1.1rem, 1.7vw, 1.8rem);
				}
			}
		}
		&--icoright {
			.ico {
				margin-bottom: 4px;
				z-index: 1;
				.material-symbols-outlined {
					color: #aaaaaa;
				}
				.circle-svg {
					opacity: 0;
				}
				&:after {
					content: "";
					position: absolute;
					width: calc(100% - 2px);
					height: calc(100% - 2px);
					border-radius: 50%;
					border: 1px solid #d6d6d6;
					top: 0;
					left: 0;
					z-index: -1;
				}
			}
			@media (any-hover: hover) {
				&:hover {
					.ico {
						.material-symbols-outlined {
							color: $color-green;
						}

						.circle-svg {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	&__02{
		background: #5B7C66;
		position: relative;
		border: 0;
		cursor: pointer;
		padding: 22px 10px;
		line-height: 1.5;
		font-size: clamp(1.5rem, 1.75vw, 2rem);
		@include flex(center, center);
		margin: 0 auto;
		max-width: 340px;
		width: 100%;
		border-radius: 8px;
		font-weight: 500;
		color: white;
		@include smooth-transition;
		@include mq{
			padding: 15px 10px;
			
		}
		&::after{
			content: "arrow_forward";
			font-family: $font-icon;
			font-size: 1.25em;
			position: absolute;
			top: 50%;
			right: 33px;
			transform: translateY(-50%);
		}
        &--noarrow{
            max-width: 360px;
            &:after{
                content: none;
            }
        }
		&--prev{
			background: #BCBCBC;
			&:after{
				left: 33px;
				right: auto;
				transform: translateY(-50%) rotate(180deg);
			}
		}
		&:hover {
			@include hover {
				opacity: 0.7;
			}
		}
		&.wpcf7-submit{
			background-image: url('../img/common/icon-arrow.png');
			background-repeat: no-repeat;
			background-position: center right 35px;
			background-size: 25px;
		}
		&.wpcf7-previous{
			background-image: url('../img/common/icon-arrow-prev.png');
			background-repeat: no-repeat;
			background-position: center left 35px;
			background-size: 25px;
		}
		&--down{
			@include font-size(16);
			max-width: 300px;
			font-weight: normal;
			padding: 1.2em 1em 1.2em 2.9em;
			letter-spacing: -0.25px;
			&::after{
				content: "vertical_align_bottom";
				font-family: $font-icon;
				font-size: 1.25em;
				position: absolute;
				top: 50%;
				right: auto;
				left: 30px;
				transform: translateY(-50%);
				line-height: 1;
				font-weight: bold;
			}
		}
	}
}

@keyframes anim-arrow {
    0% {
        opacity: 0;
        margin-left: -5px;
    }
    99.9%, to {
        opacity: 1;
        margin-left: 0;
    }
}
@keyframes anim-circle-m {
    from {
        stroke-dashoffset: 250;
    }
    to {
        stroke-dashoffset: 0;
    }
}


//fade up
.js-fadeUp {
	opacity: 0;
	transform: translate(0, 70px);
	&.is-active{
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}

.js-fadeBox {
    &-ct {
        opacity: 0;
    }
    &-img {

    }
}

