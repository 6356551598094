@use "vars" as *;

#business_development {
	overflow: hidden;
}

.sec-development {
	padding-top: clamp(5rem, 9vw, 13rem);

	.fund-slider {
		padding: 0;
	}
}

.development-heading {
	margin-bottom: clamp(5rem, 10vw, 15rem);
}

.development-business {
	padding: clamp(5rem, 9vw, 14rem) 0;
	@include mq() {
		padding-bottom: 0;
	}

	&__flex {
		@include flex(between, 0, wrap);
		max-width: 1060px;
		margin: 0 auto;
		position: relative;
		gap: 60px;

		@include mq() {
			gap: 80px 50px;

		}

		@media screen and (max-width: 767px) {
			gap: 220px 20px;
		}

		&-item {
			border-radius: 50%;
			width: 400px;
			height: 400px;
			@include flex(center, center, 0, 0, 1);
			padding: 0 77px;

			@include mq(tb) {
				width: 300px;
				height: 300px;
				padding: 0 30px;
			}

			@include mq() {
				padding: 0 40px;
				width: 250px;
				height: 250px;
			}

			@media screen and (max-width: 767px) {
				width: calc(50% - 10px);
				height: auto;
				border-radius: 10px;
				padding: 30px;

				height: auto;
				justify-content: unset;
				align-items: center;
			}

			@include mq(sm) {
				padding: 20px;
			}

			&:first-child {
				border: 1px solid #e6e6e6;
				text-align: center;
				z-index: 10;
				width: 460px;
				height: 460px;
				position: absolute;
				border-radius: 50%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				@include mq() {
					width: 320px;
					height: 320px;
					padding: 0 5px;
				}

				@media screen and (max-width: 767px) {
					width: 250px;
					height: 250px;
					justify-content: center;
					align-items: center;
				}

				@include mq(sm) {
					padding: 0 20px;
				}

			}

			&:nth-child(2) {
				background: rgba($color: #829561, $alpha: .15);

				.c-ttl__01 {
					.c-ft-- {
						&jp {
							font-weight: 500;
						}

						&en {
							color: #829561;
						}
					}
				}
			}

			&:nth-child(3) {
				background: rgba($color: #809b99, $alpha: .15);

				.c-ttl__01 {
					.c-ft-- {
						&jp {}

						&en {
							color: #809b99;
						}
					}
				}
			}

			&:nth-child(4) {
				background: rgba($color: #a59341, $alpha: .15);

				.c-ttl__01 {
					.c-ft-- {
						&jp {}

						&en {
							color: #a59341;
						}
					}
				}
			}

			&:nth-child(5) {
				background: rgba($color: #98a33f, $alpha: .15);

				.c-ttl__01 {
					.c-ft-- {
						&jp {}

						&en {
							color: #98a33f;
						}
					}
				}
			}

			.c-ttl__01 {
				text-align: center;

				@include mq {
					margin-bottom: 10px;
				}

				.c-ft-- {
					&jp {
						display: block;
						font-weight: 500;

					}

					&en {
						display: block;
						line-height: 1.2;
						margin: .2em 0 .8em;
						font-size: clamp(1.4rem, 2vw, 2rem);

						//@media screen and (min-width: 767px) {
						//	margin: 0 -20px;
						//}
					}
				}
			}

			.c-txt__01 {
				line-height: 1.6;
			}
		}

		&-icon {
			margin-bottom: 30px;

			@include mq() {
				img {
					width: 50px;
				}
			}
		}

		&-ttl {
			font-size: clamp(2.1rem, 3.5vw, 3.6rem);
			font-weight: 500;
			text-align: center;

			.c-ft-- {
				&jp {
					display: block;
					margin-bottom: 6px;
				}

				&en {
					font-size: clamp(1.8rem, 2vw, 2.4rem);
					opacity: .2;
					display: block;
				}
			}
		}

	}
}

.development-project {
	background: #f8f8f2;
	padding: clamp(5rem, 7.5vw, 12.1rem) 0 clamp(5rem, 9.6vw, 16rem);

	&__imgscroll {
		@include mq {
			overflow-x: auto;
			overflow-y: hidden;

			img {
				max-width: 1200px;

				@include mq {
					max-width: 650px;
				}
			}
		}
	}

	.block-heading {
		margin-bottom: clamp(3rem, 5.5vw, 9.2rem);

		@include mq(md-min) {

			&__ttl {
				width: 43%;
			}

			&__ct {
				width: 56.7%;
				padding-top: 11px;
			}
		}

	}
}

.development-solution {
	max-width: 1000px;
	margin: 0 auto 112px;
	position: relative;
	background: white;
	&__flex {
		white-space: nowrap;
		border-radius: 8px;
		padding: 37px 70px;
		padding: min(3.7em,3.74vw) 7%; 
		gap: 10px 20px;
		@include flex(between, center);
		
		//@include mq(lg) {
		//	padding: 10px 20px;
		//}
		@include mq{
			padding: 15px 20px;
			overflow-x: auto;
			overflow-y: hidden;
		}
	}

	@include mq {
		margin-bottom: 90px;
	}

	@include p-after(156px, 72px) {
		background: #dddddd;
		clip-path: polygon(23% 52%, 23% 0, 78% 0, 78% 52%, 100% 52%, 50% 100%, 0 52%);
		transform: translateX(-50%);
		top: calc(100% + 20px);
		left: 50%;

		@include mq {
			width: 100px;
			height: 50px;
		}
	}

	&__ttl {
		font-size: clamp(1.8rem, 2vw, 2.4rem);
		font-weight: 500;
		font-family: $font-jp;

		@include mq {
			text-align: center;
			width: 100%;

		}
	}

	.heading-common__link {
		margin-bottom: 0;
		font-family: $font-base;
		justify-content: flex-start;
		flex-wrap: wrap;
		&-item {
			@include mq {
			width: auto;
			}
		}
	}
}
