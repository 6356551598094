@use "vars" as *;

.sec-value {
	background: #f8f8f2;
	padding: clamp(50px,6vw,100px) 0 clamp(50px,12vw,201px);
}

.value-main {
	position: relative;
	margin-bottom: clamp(50px,12.3vw,206px);
	img {
		width: 100%;
	
	}
	&__ttl {
		text-align: center;
		position: absolute;
		width: 90%;
		max-width: clamp(270px, 56vw, 750px);
		color: white;
		font-family: $font-jp;
		font-weight: 500;
		font-size: clamp(2rem,3vw,4rem);
		line-height: 1.8;
		top: 49.9%;
		left: 50.3%;
		transform: translate(-50%, -50%);
		@include mq(xs) {
			@include font-size(18);
		}
	}
}

.value-wrap {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
	@include mq(sm-min) {
		padding-left: 600px;
		@media screen and (max-width: 1270px) {
			padding-left: 51%;
		}
	}

	&__ttl {
		font-family: $font-jp;
		font-weight: 500;
		font-size: clamp(1.8rem,2vw,2.8rem);
		line-height: 1.75;
		letter-spacing: -0.03em;
		margin-bottom: clamp(20px, 2vw, 33px);
		&-logo{
			display: block;
			@include mq(sm){
				text-align: center;
			}
		}
		img {
		
			max-width: 200px;
			margin-bottom: clamp(25px, 2.8vw, 47px);
			@include mq {
				max-width: 150px;
			}
		}
	}
	&__img {
		@include mq(sm-min) {
			position: absolute;
			width: 43.3%;
			top: 49%;
			left: 0;
			transform: translateY(-50%);
		}
		@include mq(sm) {
			width: 90%;
			margin: 0 auto 30px;
		}
	}
	p {
		&:not(:last-child) {
			margin-bottom: clamp(15px, 1.6vw, 26px);
		}
	}
}

.sec-business {
	padding: clamp(50px,6.6vw,110px) 0 clamp(50px,9.5vw,160px);
}

.business-ttl {
	text-align: center;
	font-family: $font-jp;
	font-weight: 500;
	font-size: clamp(2rem, 2.7vw, 3.6rem);
	letter-spacing: 0.03em;
	margin-bottom: clamp(30px, 4.4vw, 74px);
}

.business-list {
	&__item {
		&:not(:last-child) {
			margin-bottom: clamp(50px,6vw,100px);
		}
		@include mq(sm-min) {
			@include flex(between, center, wrap);
			flex-direction: row-reverse;
		}
	}
	&__ct {
		@include mq(sm-min) {
			width: 41.6%;
			@include mq {
				width: 56%;
			}
		}
		&-ttl {
			margin-bottom: clamp(15px, 1.6vw, 26px);
			.c-ft {
				&--en {
					display: block;
					color: $color-green;
					opacity: 0.2;
					font-size: clamp(2.5rem, 4.5vw, 5.2rem);
					letter-spacing: 0.03em;
					line-height: 1;
					margin-bottom: 13px;
					@include mq {
						margin-bottom: 5px;
					}
				}
				&--jp {
					display: block;
					font-weight: 500;
					font-size: clamp(1.8rem, 2vw, 2.6rem);
					letter-spacing: 0.02em;
				}
			}
		}
		&-tag {
			@include flex(0, 0, wrap);
			gap: 10px;
			font-weight: 500;
			@include font-size(14);
			line-height: 1;
			margin-bottom: clamp(20px,3.3vw,53px);
			@include mq(tb) {
				gap: 7px;
				@include font-size(12);
			}
			@include mq(xs) {
				@include font-size(10);
			}
			span {
				border-radius: 3px;
				border: 1px solid #dddddd;
				padding: 9px 14px 7px;
				letter-spacing: 0.06em;
				@include mq(tb) {
					padding: 6px 10px 5px;
				}
			}
		}
		&-btn {
			margin-top: clamp(10px,2.1vw,34px);
			text-align: right;
		}
	}
	&__img {
		position: relative;
		@include mq(sm-min) {
			width: 50%;
			@include mq {
				width: 41%;
			}
		}
		@include mq(sm) {
			margin-bottom: 15px;
		}
		img {
			border-radius: 8px;
		}
	}
}
