@use "vars" as *;
.sec-management {
	padding-top: clamp(5em, 8vw, 13.1em);
}
.management-infor {
	margin-bottom: clamp(3em, 4vw, 6.5em);
	&__block {
		@include flex(between, center);
		padding-bottom: clamp(3.5em, 4.5vw, 7.1em);
		border-bottom: 1px solid #e1dccd;
		margin-bottom: clamp(3em, 5vw, 8em);
		&:last-child {
			margin-bottom: 0;
		}
		@include mq {
			display: block;
		}
		&-l {
			width: 45%;
			@include mq {
				width: 100%;
				margin-bottom: 25px;
			}
		}
		&-r {
			width: calc(52% - 20px);
			@include mq {
				width: 100%;
			}
		}
	}

	&__ttl {
		// @include fts(32, 48);
		font-size: clamp(2.1rem, 2vw, 3.2rem);
		line-height: 1.5;
		font-weight: 500;
		padding: .2em 0 .41em .47em;
		border-left: 3px solid #5b7c66;
		margin-bottom: 1.32em;
		font-family: $font-jp;
		small {
			font-size: 68.75%;
			display: block;
		}
	}
}
.management-compare {
	margin-bottom: clamp(5em, 8.5vw, 14.2em);
	&__ttl {
		@include fts(24, 60);
		font-weight: 500;
		margin-bottom: 11px;
		font-family: $font-jp;
		@include mq(){
			line-height: 1.4;
			@include font-size(20);
			margin-bottom: 15px;
			&small{
				display: block;
				position: relative;
				@include font-size(16);
				line-height: 1.4;
				padding: 0 0 0 15px;
				margin-bottom: 10px;
				@include mq{
					font-family: $font-jp;
				}
				&:before{
					content: "";
					width: 11px;
					height: 11px;
					border-radius: 50%;
					background: #adbdb2;
					position: absolute;
					top: 5px;
					left: 0;
					@include mq{
						top: 7px;
					}
				}
			}
		}
	}
	&__tb {
		@include mq {
			position: relative;
			&w {
				overflow: auto;
				margin-left: 160px;
				/* width: 800px; */
				overflow-x: scroll;
				overflow-y: visible;
				padding: 0;
				&.js-scrollable{
					position: unset !important;
				}
				
			}
			.sticky-col {
					position: absolute;
					left: 0;
					top: auto;
					border-top-width: 1px;
			}

		}
		table {
			width: 100%;
			@include mq{
				width: 800px;
				overflow: auto;
			}
		}
		tr {
			// @include mq(){
			//     position: relative;
			//     padding-left: 160px;
			// }

			th,
			td {
				padding: 15px 15px 15px 30px;
				width: calc((100% - 22.833%) / 4);
				text-align: center;

				font-size: clamp(1.2rem, 1.2vw, 1.6rem);
				@include mq() {
					width: calc((100% - 160px) / 4);
				}
				&:first-child {
					width: 22.833%;
					font-family: $font-jp;
					@include mq {
						width: 160px;
						min-width: 160px;
						max-width: 160px;
						// left: 0px;
						// position: absolute;
						//     top: 0;
						//     left: 0;
							pointer-events: none;
					}
				}
				@include mq() {
					padding: 10px 10px 10px 15px;
				}
			}
			th {
				color: #fff;
				background: #5b7c66;
				text-align: center;
				padding: 13px 5px;
				font-family: $font-jp;
				&:first-child{
					@include mq{
						height: 100%;
					}
				}
			}
			td {
				background: #eae3df;
				&:first-child {
					background: #5b7c66;
					color: #fff;
					text-align: left;
					border: 1px solid #fff;
				}
				&.bg-lightorange {
					background: #efecdd;
				}
				&.bg-lightblue {
					background: #e8eded;
				}
			}
			&:nth-child(1){
				th{
					font-size: clamp(1.2rem, 1.2vw, 1.5rem);
					border: 1px solid #fff;
					font-weight: 400;
				}
			}
		}
		&--custom{
			table{
				width: 100% ;
				margin-bottom: 30px;
				&:last-child{
					margin-bottom: 0;
				}
				tr{
					th,td{
						width: 50% !important;
						padding: 10px 5px;
					}
					th{
						border: none !important;
						border-bottom: 1px solid white !important;
						text-align: left;
						padding-left: 15px;
					}
					td{

					}
				}
			}
		}
	}
}

.management-service {
	padding: clamp(5em, 7.5vw, 12.6em) 0 clamp(5em, 8.5vw, 14.6em);
	background: #f8f8f2;
	&__des {
		margin-bottom: 63px;
		margin-bottom: clamp(30px,5.25vw,63px);
	}
	&__box {
		background: #fff;
		padding: clamp(1.8em, 3vw, 3.6em) clamp(2em, 5.3vw, 6em)
			clamp(3em, 4.5vw, 5em);
		border-radius: 5px;
		&-ttl {
			@include fts(24, 36);
			font-weight: 500;
			margin-bottom: 1.5em;
			text-align: center;
			font-family: $font-jp;
			@include mq{
				@include font-size(20);
			}
		}
	}
	&__list {
		@include flex(0, 0, wrap);
		gap: 20px 20px;
		&-item {
			width: calc(25% - 15px);
			padding: 10.5px 10px;
			border-radius: 3px;
			background: #f8f8f2;
			text-align: center;
			@include mq(tb) {
				width: calc(33.33% - 13.3333px);
			}
			@include mq() {
				width: calc(50% - 10px);
				line-height: 1.4;
			}
		}
	}
}
.management-hotel {
	padding: clamp(5em, 8.5vw, 13em) 0 clamp(5em, 9.8vw, 16em);
	background: #fff;
	&__box {
		@include flex(between, 0);
		@include mq(sm) {
			display: block;
		}
		&-img {
			@include aspect-ratio(1120, 800);
			border-radius: 5px;
		}
		&-l {
			width: 52%;
			@include mq {
				width: 100%;
				margin-bottom: 35px;
			}
		}
		&-r {
			padding-top: 6px;
			width: calc(48% - 20px);
			@include mq {
				width: 100%;
			}
		}
	}
	&__list {
		li {
			display: flex;
			gap: 0 10px;
			margin-bottom: 11px;

			@include mq(md-min){
				@include font-size(16);
			}
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background: #5b7c66;
				display: inline-block;
				position: relative;
				top: 6px;
			}
		}
	}
}
