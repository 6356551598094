@use "vars" as *;
#page-sitemap, #page-solicitation{
	background: #F8F8F2;
}
.sec-sitemap{
	padding: clamp(2rem,6vw,4.9rem) 0 clamp(5rem,9.2vw,15.2rem);
	
}
.sitemap-list{
	@include flex(0, 0, wrap);
	font-family: $font-jp;
	gap: 10px 0;
	@include mq(sm){
		justify-content: space-between;
	}
	a{
		transition: all .3s ease;
		@media (any-hover: hover) {
			&:hover {
				opacity: .7;
			}
		}
	}
	&__first{
		width: 100%;
		margin-bottom: 47px;
		@include mq{
			margin-bottom: 30px;
		}
		a{
			font-weight: 500;
			@include font-size(24);
			@include mq{
				@include font-size(20);
			}
		}
	}
	&__parent{
		@include font-size(20);
		line-height: 1.5;
		width: 33.3%;
		
		@include mq{
			@include font-size(16);
		}
		@include mq(sm){
			width: calc(50% - 5px);
		}
		@include mq{
			@include font-size(14);
		}
		@include mq(sm-min){
			&:nth-child(3){
				width: 39%;
			}
			&:nth-child(4){
				width: 20%;
			}
		}
		
		&-item{
			&:not(:last-child){
			    margin-bottom: 16px;
				@include mq{
					margin-bottom: 20px;
				}
			}
		}
	}
	&__sub{
		@include font-size(17);
		padding: 20px 0 0 33px;
		@include mq{
			@include font-size(13);
			padding: 10px 0 0 15px;
		}
		@include mq(xs){
			padding: 10px 0 0 10px;
			@include font-size(12);
		}
		&-item{
			&:not(:last-child){
			    margin-bottom: 21px;
				@include mq{
					margin-bottom: 10px;
				}
			}
		
		}
	}
}
