@use "vars" as *;



.pc{
	@include mq(){
		display: none !important;
	}
	&-xl{
		@include mq(xl){
			display: none !important;
		}
	}
	&-tb{
		@include mq(tb){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-tb{
		@include mq(tb-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}



// Hover style
.u-fn-hover{
	display: inline-block;
	@include mq(md-min){
		transition: all .2s;
		&:hover{
			opacity: .7;
		}
	}
}

.u-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}

.mouse-icon {
	position: absolute;
	width: 100px;
	height: 100px;
	background-color: rgba($color-green, .9);
	background-image: url(../img/common/ico-dbl-arrow.png);
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 50%;
	pointer-events: none;
}

.js-fadeBox {
	> * {
		@include smooth-transition;
	}
}