@use "vars" as *;

.sec-fund{
	// padding: 129px 0 0;
	padding: clamp(5rem, 8vw, 12.9rem) 0 0;
	// @include mq(){
	// 	padding: 50px 0 0;
	// }
}

.fund-block{
	&__list{
		gap: 10px;
	    margin-bottom: 75px;
		@include flex(center,center,wrap);
		@include mq(){
			gap: 5px;
			margin-bottom: 35px;
		}
		&-item{
			display: inline-block;
			background: #5b7c66;
			border-radius: 4px;
			padding: 12px 26px;
			letter-spacing: 1px;
			color: #fff;
			font-weight: 500;
			font-family: $font-jp;
			@include font-size(16);
			@include mq(){
				padding: 8px 10px;
				letter-spacing: 0;
				@include font-size(15);
			}
		}
	}
	&__desc,.c-txt__01{
		margin-bottom: 90px;
		text-align: center;
		@include mq(){
			text-align: left;
			margin-bottom: 35px;
		}
	}
	&__img{
		max-width: 1158px;
		margin: 0 auto 100px;
		@include mq(){
			overflow-x: auto;
			margin-bottom: 50px;
			picture{
				@include mq(){
					width: 767px;
					display: block;
				}
			}
		}
		&--custom{
			max-width: 100%;
			width: auto;
			overflow: hidden;
			@include mq{
				margin-bottom: 0;
				margin-top: 3em;
			}
			picture{
				width: 100%;
			}
		}
		img{
			width: 100%;
		}
	}
}

.fund-box{
	padding: 50px 59px 62px;
    background: #f8f8f2;
    margin: 0 12px;
	@include mq(xl){
		padding: 50px 20px;
	}
	@include mq(){
		padding: 35px 20px;
		&--custom{
			margin: 0;
			padding: 0;
			background: none;
		}
	}
	.c-ttl__01{
		text-align: center;
		margin-bottom: 30px;
		@include mq(){
			margin-bottom: 25px;
		}
	}
	&__img{
		@include mq(){
			overflow-x: auto;
			picture{
				@include mq(){
					width: 767px;
					display: block;
				}
			}
		}
		img{
			width: 100%;
		}
	}
}

.fund-slider{
	padding: 139px 0 160px;
	@include mq(){
		padding: 60px 0;
	}
	&__item{
		padding: 0 10px;
		width: 440px;
		@include mq(xl){
			width: 360px;
		}
		@include mq(){
			padding: 0 5px;
			width: 240px;
		}
	}
	&__thumb{
		@include aspect-ratio(420px,320px);
		border-radius: 8px;
		overflow: hidden;
	}
}

.fund-hotel{
	padding: 109px 0 159px;
	background-color: rgb(248, 248, 242);
	@include mq(){
		padding: 50px 0;
	}
	.c-ttl__01{
		margin-bottom: 50px;
		@include mq(){
			margin-bottom: 25px;
		}
	}
	&__item{
		&:not(:last-child){
			margin-bottom: 87px;
			@include mq(){
				margin-bottom: 35px;
			}
		}
	}
	&__box{
		@include flex(0);
		background: #fff;
		@include mq(){
			flex-wrap: wrap;
		}
		&-ttl{
			width: 240px;
			line-height: 1.9;
			background: #5b7c66;
			padding: 20px 10px;
			text-align: center;
			@include flex(center,center);
			color: #fff;
			font-weight: 500;
			@include font-size(20);
			@include mq(xl){
				width: 160px;
				@include font-size(18);
			}
			@include mq(){
				width: 100%;
				line-height: 1.3;
				padding: 15px 10px;
				height: 70px;
			}
		}
		&-list{
			width: calc(100% - 240px);
			@include flex(0,0,wrap);
			gap: 20px 0;
			padding: 30px 19px;
			@include mq(lp){
				padding: 30px 10px;
			}
			@include mq(xl){
				width: calc(100% - 160px);
			}
			@include mq(){
				width: 100%;
				gap: 10px 0;
			}
			&-item{
				width: 25%;
				padding: 0 10px;
				@include mq(lp){
					padding: 0 5px;
				}
				@include mq(xl){
					width: 33.33%;
				}
				@include mq(sm){
					width: 50%;
					padding: 0 5px;
				}
				span{
					@include flex(center,center);
					background: #f8f8f2;
					padding: 14px 5px;
					border-radius: 5px;
					color: #373737;
					@include font-size(16);
					height: 100%;
					text-align: center;
					@include mq(lp){
						@include font-size(14);
					}
				}
			}
		}
	}
}
