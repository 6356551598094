@use "vars" as *;

.sec-news--ir{
	background: #fff;
	padding: clamp(5rem, 6.3vw, 11.1rem) 0 clamp(5rem, 9.5vw, 15.8rem);
	.c-ttl__01{
		@include mq(xl-min){
			padding-bottom: 30px;
			margin-bottom: 61px;
		}
	}
	.detail-content {
		margin: 0 auto;
		max-width: 715px;
	}
}
div.pdfemb-viewer{
	margin: 0 auto clamp(30px, 6vw, 50px)!important;
	border: 1px solid #ebebeb!important;
	background: #fff!important;
}

.common-wrap__ir{
	margin-bottom: 100px;
	@include mq(tb){
		margin-bottom: 50px;
	}
	&-item{
		border-bottom: 1px solid #e6e6e6;
		a{
			line-height: 1.733;
			padding: 34px 0 34.5px;
			@include flex(0,start);
			color: #373737;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				height: 1px;
				left: 0;
				bottom: -1px;
				background: #5b7c66;
				width: 0;
				transition: all .3s ease-in-out;
			}

			&:hover {
				@include hover {
					opacity: 1;
					&:after{
						width: 100%;
					}
				}
			}
			@include mq(tb){
				padding: 20px 0;
			}
			@include mq(){
				flex-wrap: wrap;
				padding: 15px 0;
			}
			time{
				padding: 0 20px;
				letter-spacing: 1px;
				width: 160px;
				display: block;
				font-family: $font-en;
				@include font-size(14);
				@include mq(){
					width: 100%;
					padding: 0;
					margin-bottom: 5px;
				}
			}
			p{
				padding-right: 20px;
				display: block;
				width: calc(100% - 160px);
				@include font-size(15);
				@include mq(){
					padding-right: 0;
					width: 100%;
					@include font-size(12);
				}
			}
		}
	}
}