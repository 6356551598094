@use "vars" as *;

.sec-news {
	padding: clamp(2rem,6.5vw,11rem) 0 clamp(5rem,9.5vw,15.8rem);
}

.news-scroll{
	@include mq(){
		margin-left: calc((100% - 100vw) / 2);
        margin-right: calc((100% - 100vw) / 2);
        padding: 0 6.66%;
        overflow-x: auto;
		margin-bottom: 30px;
		border-bottom: 1px solid #e6e6e6;
	}
}

.news-tab {
	@include flex(0, 0, wrap);
	gap: 10px 33px;
	border-bottom: 1px solid #e6e6e6;
	margin-bottom: 30px;
	@include mq {
		margin-bottom: 0;
		white-space: nowrap;
        width: max-content;
		gap: 24px;
		border-bottom: 0;
	}
	a {
		position: relative;
		display: inline-block;
		color: #9b9b9b;
		@include font-size(15);
		padding: 5px 7px 8px;
		@include mq {
			@include font-size(12);
		    padding: 9px 7px;
		}
		&:before {
			content: "";
			position: absolute;
			background: black;
			width: 0;
			height: 3px;
			bottom: -1px;
			left: 50%;
			transform: translateX(-50%);
			transition: 0.3s ease;
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 1;
				color: black;
				&:before {
					width: 100%;
				}
			}
		}
		&.active {
			color: black;
			&:before {
				width: 100%;
			}
		}
	}
}

.news-list {
	margin-bottom: clamp(30px,4.7vw,78px);
	&__item {
		border-bottom: 1px solid #e6e6e6;
		a {
			display: block;
			padding: 30px 20px;
			@include mq(sm-min) {
				@include flex(between, start, wrap);
			}
			@include mq(){
				@include flex();
				padding: 0;
				gap: 16px;
			}
		}
	}
	&__img {
		position: relative;
		@include mq(sm-min) {
			width: 27%;
		}
		@include mq(sm) {
			margin-bottom: 0;
			position: relative;
			width: 34.43%;
		}
		&:before {
			content: "";
			display: block;
			padding-bottom: calc(164 / 220 * 100%);
		}
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}
	&__ct {
		@include mq(sm){
			padding: 15px 0;
		}
		&-ttl {
			@include fts(17, 30);
			margin-bottom: 10px;
			@include mq(sm){
				line-height: 1.6;
				margin-bottom: 2px;
				@include font-size(12);
			}
		}
		&-info {
			@include flex(0, center, wrap);
			gap: 14px;
			@include font-size(14);
			@include mq(sm){
				gap: 5px 5px;
			}
			span {
				display: inline-block;
				border-radius: 3px;
				border: 1px solid #dddddd;
				font-weight: 500;
				padding: 6px 15px 4px;
				@include mq(sm){
					font-size: 1rem;
					line-height: 1.6;
					padding: 0 6px;
				}
			}
			time {
				font-family: $font-en;
				letter-spacing: 0.06em;
				@include mq(sm){
					font-size: 1rem;
				}
			}
		}
	}
	&--s2 {
		@include mq(sm-min) {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 20px;
			max-width: 840px;
			margin-top: -13px;
		}
		@include mq(){
			display: flex;
			flex-wrap: wrap;
			margin: 0 -7px;
		}
		.news-list {
			&__item {
				@include mq(sm-min) {
					margin-bottom: 0;
				}
				a {
					display: block;
				}
				@include mq(){
					width: 50%;
					padding: 0 7px;
				}
			}
			&__img {
				width: 100%;
				margin-bottom: 4px;
				&:before {
					padding-bottom: calc(266 / 370 * 100%);
				}
			}
			&__ct {
				width: 100%;
				&-info {
					gap: 9px;
					margin-top: -3px;
					@include mq(){
						gap: 5px;
						span{
							padding: .3em .5em .1em;
							@include font-size(12);
						}
					}
				}
				&-ttl{
					@include mq(){
						margin: 1em 0;
						font-size: 1.5rem;
						line-height: 1.5;
					}
				}
			}
		}
	}
}

.sec-newsdetail {
	padding: clamp(20px,4vw,59px) 0 clamp(50px,9.8vw,160px);
	.common-wrap__sidebar {
		@include mq(lg-min) {
			padding-top: 51px;
		}
	}
}

.detail-info {
	@include flex(0, center, wrap);
	gap: 14px;
	@include font-size(14);
	margin-bottom: 5px;
	@include mq(){
		gap: 6px;
	}
	span {
		display: inline-block;
		border-radius: 3px;
		border: 1px solid #dddddd;
		font-weight: 500;
		padding: .3em .5em .1em;
		@include font-size(13);
		@include mq(){
			@include font-size(12);
		}
	}
	time {
		font-family: $font-en;
		letter-spacing: 0.06em;
	}
	&--02{
		span {
			color: #fff;
			background: #5b7c67;
			border-color: #5b7c67;
			padding: .2em 15px;
			@include font-size(13);
			@include mq(){
				@include font-size(12);
			}
		}
	}
}

.detail-ttl {
	border-bottom: 1px solid #e6e6e6;
	font-family: $font-jp;
	font-size: clamp(1.8rem, 3.1vw, 2.6rem);
	line-height: 1.75;
	padding-bottom: 20px;
	margin-bottom: clamp(1.5em, 4vw, 3.05em);
	@include mq {
		padding-bottom: 1em;
		line-height: 1.5;
	}
}

.detail-img {
	max-width: 640px;
	margin: 0 auto clamp(20px,3.2vw,54px);
	img {
		width: 100%;
		border-radius: 8px;
	}
}

.detail-content {
	@include fts(15, 28);
	margin-bottom: clamp(30px,4.5vw,71px);
	@include mq {
		@include font-size(12);
	}

	p {
		&:not(:last-child) {
			margin-bottom: 44px;
			@include mq {
				margin-bottom: 20px;
			}
		}
	}

	h1 {
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		@include font-size(19);
		padding: 12px 8px 8px;
		margin-bottom: 28px;
	}

	h2 {
		border-bottom: 1px solid #5b7c66;
		@include font-size(19);
		padding: 0 0 6px 8px;
		margin-bottom: 29px;
	}

	h3 {
		border-left: 1px solid #5b7c66;
		@include font-size(19);
		line-height: 1.5;
		padding: 1px 0 0 13px;
		margin: 51px 0 22px;
	}

	h4 {
		position: relative;
		@include font-size(17);
		padding-left: 52px;
		margin-bottom: 13px;
		&:before {
			content: "";
			position: absolute;
			width: 40px;
			height: 1px;
			background: #e6e6e6;
			left: 0;
			top: 14px;
		}
	}

	h5 {
		position: relative;
		@include font-size(17);
		padding-left: 22px;
		margin-bottom: 13px;
		&:before {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			background: #5b7c66;
			border-radius: 50%;
			left: 0;
			top: 8px;
		}
	}

	h6 {
		@include font-size(17);
		margin-bottom: 14px;
	}

	ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;

		li {
			display: list-item;
			text-align: -webkit-match-parent;
			list-style: decimal;
		}
	}

	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0;
		margin-inline-end: 0;
		padding-inline-start: 40px;

		li {
			display: list-item;
			text-align: -webkit-match-parent;
			list-style: disc;
		}
	}

	del, ins {
		text-decoration: line-through;
	}

	#toc_container {
		width: 100%;
		background: #f3f3ec;
		border-radius: 8px;
		border: 1px solid #e6e6e6;
		padding: 19px;
		margin: 50px 0 clamp(30px, 3.7vw, 60px);
		.toc_title {
			text-align: center;
			border-bottom: 1px solid #e6e6e6;
			font-family: $font-en;
			@include font-size(24);
			padding-bottom: 4px;
			margin-bottom: 23px;
		}
		.toc_list {
			@include font-size(15);
			margin: 0;
			padding-left: 20px;
			@include mq {
				padding-left: 0;
				@include font-size(14);
			}
			ul {
				margin: 0;
				padding-left: 20px;
				@include mq(){
					padding-left: 10px;
				}
			}
			li {
				list-style: none;
				margin-bottom: 10px;
			}
			a {
				@include flex();
				margin-bottom: 7px;
			}
		}
		.toc_number {
			color: #5b7c66;
			font-family: $font-en;
			word-break: keep-all;
			margin-right: 8px;
		}
	}
}