@use "vars" as *;

.sec-philosophy {
	padding:clamp(5rem,8.6vw,14.2rem) 0 clamp(5rem,7vw,11.2rem);

}

.philosophy-heading {
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 93px;
	margin-bottom: 82px;
	@include mq{
		padding-bottom: 40px;
		margin-bottom: 40px;
	}
	&__ttl {
		margin-bottom: 68px;
		@include mq{
			margin-bottom: 30px;
		}
		
	}

	&__subttl {
		text-align: center;
		margin-bottom: 34px;
		font-weight: 500;
		@include mq{
			margin-bottom: 20px;
		}
		@include mq(sm){
			@include font-size(20);
		}
		@include mq(xs){
			@include font-size(17);
		}
	}

	.c-txt__03 {
		max-width: 800px;
		margin: 0 auto;
	}
}

.philosophy-vision {
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 89px;
	margin-bottom: 82px;
	@include mq{
		padding-bottom: 40px;
		margin-bottom: 40px;
	}
	&__ttl {
		margin-bottom: 69px;
		@include mq{
			margin-bottom: 30px;
		}
	}
}

.philosophy-list {
	@include fts(20, 36);
	max-width: 956px;
	margin: 0 auto;
	@include mq{
		@include font-size(15);
	}
	li {
		position: relative;
		padding-left: 28px;
		@include mq{
		    padding-left: 25px;
		}
		&:not(:last-child) {
			margin-bottom: 43px;
			@include mq{
				margin-bottom: 10px;
			}
		}

		@include p-before(16px, 16px) {
			background: currentColor;
			border-radius: 50%;
			left: 0;
			top: 9px;
			@include mq{
				top: 5px;
			}
		}
	}

	&--number {
		counter-reset: number;

		li {
			
			@include mq(md-min){
				padding-left: 35px;
			}
			&:not(:last-child) {
				
				@include mq(md-min){
					margin-bottom: 32px;
				}
			}

			&::before {
				counter-increment: number;
				content: "0" counter(number);
				background: transparent;
				top: 0;
				width: auto;
				height: auto;
				color: $color-green;
				font-family: $font-en;
				@include font-size(18);
				@include mq{
					@include font-size(16);
				}
			}
		}
	}
}

.philosophy-value {
	@include flex(between, 0, wrap);

	&__ttl {
		width: 290px;
		padding-left: 61px;
		@include mq{
			width: 100%;
			padding-left: 0;
			margin-bottom: 20px;
			text-align: center;
		}
	}

	.philosophy-list {
		width: calc(100% - 400px);
		margin: 11px 0 0;
		@include mq{
			width: 100%;
		}
	}
}

.key-indicatiors {
	background: #f8f8f2;
	padding: clamp(5rem, 8vw,12.6rem) 0 clamp(5rem, 11vw, 18.3rem);
	&__ttl {
		text-align: center;
		margin-bottom: 48px;
		@include mq{
			margin-bottom: 20px;
		}
		.c-ft-- {
			&en {
				display: block;
				color: $color-green;
				margin-top: 5px;
				margin-left: 0;
			}
		}
	}
	.c-txt__03{
		max-width: 800px;
		margin: 0 auto;
	}
}
