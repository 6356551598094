@use "vars" as *;

.sec-materality{
	&__item{
		// padding: 117px 0 120px;
		background: #f8f8f2;
		padding: clamp(5rem, 7vw, 11.7rem) 0 clamp(5rem, 7vw, 12rem);
		// @include mq(){
		// 	padding: 50px 0;
		// }
		&:nth-child(2n){
			background: #fff;
			@include mq(md-min){
				padding-bottom: 124px;
			}
		}
		&:nth-child(3){
			@include mq(md-min){
				padding-bottom: 142px;
			}
		}
	}
}

.materality-block{
	@include flex();
	margin-bottom: 50px;
	@include mq(){
		flex-wrap: wrap;
		margin-bottom: 35px;
	}
	.c-ttl__07{
		width: 50%;
		@include mq(){
			width: 100%;
		}
	}
	.c-txt__01{
		width: 50%;
		padding-top: 54px;
		letter-spacing: 0.1px;
		@include mq(){
			width: 100%;
			letter-spacing: 0;
			padding-top: 15px;
		}
	}
	.c-ttl__01{
		margin-bottom: 50px;
	}
	&--02{
		@include mq(md-min){
			border-bottom: 1px solid #e1dccd;
			position: relative;
			padding-bottom: 29px;
			margin-bottom: 59px;
			@include p-after(15px, auto){
				border-bottom: 1px solid #5b7c66;
				left: 0;
				bottom: -1px;
			}
		}
		.c-ttl__07{
			width: 36.75%;
			@include mq(){
				width: 100%;
				border-bottom: 1px solid #e1dccd;
				position: relative;
				padding-bottom: 33px;
				margin-bottom: 34px;
				@include mq{
					padding-bottom: 10px;
					margin-bottom: 25px;
				}
				@include p-after(15px, auto){
					border-bottom: 1px solid #5b7c66;
					left: 0;
					bottom: -1px;
				}
			}
		}
		.c-txt__01{
			width: 63.25%;
			padding-top: 48px;
			@include mq(){
				width: 100%;
				padding-top: 0;
			}
		}
	}
}

.materality-box{
	&__list{
		@include flex(between);
		@include mq(tb){
			flex-wrap: wrap;
			gap: 20px 0;
		}
		&-item{
			width: calc(25% - 30px);
			padding: 21px 19px 58px;
			border: 1px solid #e1dccd;
			border-radius: 8px;
			@include mq(lp){
				width: calc(25% - 10px);
			}
			@include mq(tb){
				width: calc(50% - 10px);
			}
			@include mq(){
				padding: 20px 12px min(35px,4.19vw);
				width: calc(50% - 5px);
			}
		}
		&-number{
			@include flex();
		    margin-bottom: 43px;
			@include mq(){
				margin-bottom: 2.49vw;
			}
			span{
				width: 55px;
				line-height: 1;
				display: block;
				font-family: $font-en;
				color: #5b7c66;
				position: relative;
				@include font-size(32);
				@include mq {
					width: 45px;
					//margin-bottom: 10px;
					@include font-size(24);
				}
				@include p-before(30px,1px){
					background: #5b7c66;
					bottom: -9px;
					left: 0;
					@include mq(){
						width: 20px;
						bottom: 0;
					}
				}
			}
			small{
				display: block;
				width: calc(100% - 55px);
				color: #5b7c66;
				font-family: $font-en;
				line-height: 1.4116;
				@include font-size(12);
				@include mq {
					width: calc(100% - 45px);
					line-height: 1.1;
				}
			}
		}
		&-ttl{
			text-align: center;
			color: #373737;
			line-height: 1.857;
			@include font-size(14);
			@include mq {
				line-height: 1.5;
			}
			@include mq(sm) {
				@include font-size(13);
				text-align: left;
			}
			span{
				display: block;
				font-weight: 500;
				font-family: $font-jp;
				@include font-size(28);
				margin-bottom: 18px;
				@include mq {
					margin-bottom: 10px;
					@include font-size(22);
				}
				@include mq(sm) {
					text-align: center;
				}
			}
		}
	}
}

.materality-img{
	@include mq(){
		overflow-x: auto;
		padding-bottom: 20px;
		picture{
			display: block;
			width: 834px;
		}
	}
	img{
		width: 100%;
	}
}

.materality-table{
	margin-top: 72px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #e1dccd;
	margin-bottom: -11px;
	@include mq(){
		margin-bottom: 0;
		margin-top: 35px;
		overflow-x: auto;
		padding-bottom: 1px;
	}
	table{
		width: calc(100% + 2px);
		margin: -1px;
		// border: 1px solid #e1dccd;
		@include mq(){
			width: 834px;
		}
		th{
			text-align: center;
			padding: 23px 5px;
			background: #5b7c66;
			border: 1px solid #e1dccd;
			color: #fff;
			font-weight: 500;
			font-family: $font-jp;
			@include font-size(18);
			@include mq(tb){
				@include font-size(16);
			}
			&:nth-child(1){
				width: 250px;
				@include mq(xl){
					width: 200px;
				}
			}
			&:nth-child(3){
				width: 200px;
				@include mq(xl){
					width: 150px;
				}
				@include mq(tb){
					width: 120px;
				}
			}
			&:nth-child(4){
				width: 330px;
				@include mq(xl){
					width: 200px;
				}
				@include mq(tb){
					width: 160px;
				}
			}
		}
		td{
			border: 1px solid #e1dccd;
			padding: 34px 21px 46px;
			background: #F3F3EC;
			@include mq(xl){
				padding: 22px 8px;
			}
			p{
				line-height: 1.857;
				text-align: center;
				color: #373737;
				@include font-size(14);
				@include mq(xl){
					@include font-size(13);
				}
				span{
					font-family: $font-jp;
					display: block;
					font-weight: 500;
					margin-bottom: 19px;
					@include font-size(28);
					@include mq(xl){
						@include font-size(24);
					}
				}
			}
			ul{
				padding-left: 5px;
				@include mq(xl){
					padding-left: 0;
				}
				li{
					color: #373737;
					padding-left: 5px;
					position: relative;
					line-height: 1.3;
					@include font-size(14);
					&:not(:last-child){
						margin-bottom: 10px;
					}
					@include p-before(3px,3px){
						background: #373737;
						top: 7px;
						left: 0;
						border-radius: 50%;
					}
				}
			}
		}
	}
	&__logo{
		@include flex(0,0,wrap);
		margin: 0 -8px;
		gap: 20px 0;
		@include mq(xl){
			gap: 10px 0;
		}
		@include mq(tb){
			margin: 0 -4px;
		}
		&-item{
			width: 25%;
			padding: 0 8px;
			@include mq(xl){
				width: 33.33%;
			}
			@include mq(tb){
				padding: 0 4px;
			}
		}
	}
}