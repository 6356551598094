@use "vars" as *;

.value-top {
	padding: clamp(5rem, 9vw, 11.6rem) 0 clamp(5rem, 9vw, 12rem);
	background: #f8f8f2;

	.materality-block {
		margin-bottom: 65px;
		@include mq{
			margin-bottom: 30px;
		}
		&__ct {
			width: 50%;
			padding-top: 50px;
			@include mq(){
				width: 100%;
				padding-top: 20px;
			}
			.c-ttl__01 {
				font-weight: 500;
				letter-spacing: -2px;
				margin-bottom: 35px;
				@include mq{
					margin-bottom: 15px;
				}
			}

			.c-txt__01 {
				width: 100%;
				padding-top: 0;
			}
		}
	}
}

.value-info {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 40px;
	@include mq(lg){
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 710px) {
		grid-template-columns: 1fr;
		gap: 30px;
	}
	@include mq(sm){
		gap: 15px;
	}
	&__item {
		background: #f3f3ec;
		border-radius: 8px;
		padding: 32px 40px;
		display: grid;
		grid-template-rows: subgrid;
		gap: 0;
		grid-row: 2 span;
		
		@include mq(lp){
			padding: 32px 15px;
		}
		@include mq(sm){
			padding: 15px 20px;
		}
	}

	&__ttl {
		@include flex(0, center);
		gap: 10px 20px;
		margin-bottom: 28px;
		@include mq {
			margin-bottom: 15px;
		}

		&-txt {
			color: $color-green;
			white-space: nowrap;
			text-align: center;
			>* {
				display: block;
			}

			.c-ft-- {
				&en {
					@include font-size(12);
					border-bottom: 1px solid currentColor;
					padding-bottom: 5px;
					margin-bottom: 7px;
				}

				&base {
					@include font-size(14);
				}
			}
		}

		&-sub {
			@include font-size(20);
			@include mq(xl){
				@include font-size(16);
			}
		}
	}
}

.value-box {
	padding: clamp(5rem, 8.5vw, 12rem) 0 clamp(5rem, 8.5vw, 11rem);

	&__flex {
		@include flex(between, 0, wrap);
		gap: 20px 0;
		&-left {
			width: 42%;
			margin-top: -26px;
			@media screen and (max-width: 720px) {
				width: 100%;
				margin: 0 0 20px;
			}
			@include mq(sm){
				margin: 0;
			}
		}

		&-right {
			width: 50%;
			@media screen and (max-width: 720px) {
				width: 100%;
			}
		}
		&-stick{
			@media screen and (min-width: 720px) {
				position: sticky;
				top: 80px;
			}
			
		}
		&-ttl {
			margin-bottom: 40px;
			@include mq{
				margin-bottom: 20px;
			}
			>* {
				display: block;
			}

			.c-ft-- {
				&en {
					color: $color-green;
					@include font-size(16);
					margin-left: 0;
					margin-bottom: 24px;
					@include mq{
						margin-bottom: 12px;
					}
				}

				&jp {
					line-height: 1.6;
					font-weight: 500;
					.--md {
						font-size: clamp(2.8rem, 2.8vw, 4.6rem);
					}
				}
			}
		}
	}

	&__list {
		&-item {
			&:not(:last-child){
			    margin-bottom: 112px;
				@include mq{
					margin-bottom: 50px;
				}
			}
		}

		&-img {
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			margin-bottom: 30px;
			@include mq{
				margin-bottom: 15px;
			}
			@include mq(sm){
				margin-bottom: 30px;
			}
			&::before {
				content: "";
				padding-top: calc((250 / 600) * 100%);
				display: block;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

		&-ttl {
			@include font-size(20);
			font-family: $font-en;
			color: $color-green;
			position: relative;
			padding-bottom: 1px;
			margin-bottom: 27px;

			@include mq {
				padding-bottom: 10px;
				margin-bottom: 15px;
				@include font-size(16);
			}

			@include p-before(15px, 1px) {
				background: #5b7c66;
				left: 0;
				bottom: 0;
			}

			@include p-after(calc(100% - 20px), 1px) {
				background: #e1dccd;
				right: 0;
				bottom: 0;
			}
		}
		&-subttl{
		    margin-bottom: 27px;
			font-weight: 500;
			@include mq {
				margin-bottom: 15px;
			}
		}
		&-brief{
			margin-bottom: 54px;
			@include mq{
				margin-bottom: 30px;
			}
			@include mq(sm){
				margin-bottom: 20px;
			}
		}
	}
	&__info{
		counter-reset: number;
		&-item{
			&:not(:last-child){
			    margin-bottom: 47px;
				@include mq{
					margin-bottom: 20px;
				}
			}
		
		}
		&-ttl{
			position: relative;
			padding-left: 64px;
			font-family:  $font-jp;
			font-size: clamp(1.8rem, 2vw, 2.6rem);
			font-weight: 500;
			margin-bottom: 13px;
			@include mq{
			    padding-left: 40px;
			}
			&::before{
				counter-increment: number;
				content: "0"counter(number);
				font-size: clamp(2.2rem, 2.5vw, 3.2rem);
				font-family: $font-en;
				color: $color-green;
				position: absolute;
				left: 0;
				top: 2px;
				line-height: 1.2;
			}
			@include p-after(44px, 1px){
				background: $color-green;
				left: 0;
				top: 1.7em;
				@include mq{
					width: 30px;
				}
			}
		}
	}
}
.value-project{
	padding: clamp(5rem, 8.5vw, 10.5rem) 0 clamp(5rem, 12vw, 17rem);
	background: #f8f8f2;
	&__ttl{
		@include mq(xl-min){
			@include font-size(38);
		}
		.c-ft--{
			&en{
				display: block;
				color: $color-green;
				@include font-size(16);
				margin-left: 0;
				margin-bottom: 24px;
				@include mq{
					margin-bottom: 10px;
					@include font-size(14);
				}
			}
		}
		@include mq(md-min){
			padding-bottom: 33px;
			margin-bottom: 69px;
		}
	}
	&__imgscroll{
		@include mq{
			overflow-x: auto;
			overflow-y: hidden;
			img{
				max-width: 840px;
			}
		}
	}
}
